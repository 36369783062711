const qidoOrange = '#fc4c02';
const qidoYellow = '#ffd102';
const qidoGreen = '#72b969';
const qidoGrey = '#3a3a3a';
const qidoBlue = '#4bd0eb';

const Tab = {r: 180, g: 180, b: 180};

const colorDifference = 50;
function tabColor(index) {
  const diff = colorDifference * index;
  return `rgb(${Tab.r - diff}, ${Tab.g - diff}, ${Tab.b - diff})`;
}

export default {
  activeTabColor: qidoGrey,
  armed: qidoGreen,
  ok: qidoGreen,
  paired: qidoGreen,
  warningYellow: qidoYellow,
  primaryButtonBg: qidoGreen,
  helpButtonBg: '#6d6d6d',
  darkBackground: '#fff',
  background: '#fff',
  firstTab: tabColor(0),
  secondTab: tabColor(1),
  thirdTab: tabColor(2),
  fourthTab: tabColor(3),
  activeIndicator: qidoBlue,
  pairingIndicator: qidoBlue,
  loading: qidoOrange,
  accent: qidoOrange,
  addButton: qidoOrange,
  item: qidoGreen,
  lock: qidoGreen,
  titleBarButton: qidoOrange,
  highlightColor: qidoOrange,
  recommendedButton: qidoOrange, // used for select button on recommended monitoring plan
  planButton: qidoGreen, // used for select button on monitoring plans
  splashBg: '#fff',
  splashFg: qidoOrange,

  // Login / signup screen
  loginBackButton: '#3a3a3a',
  loginText: '#3a3a3a',
  materialInputBottom: '#3a3a3a',
  materialInputText: '#000',
  materialInputPlaceholderText: '#777',

  // Navigation Drawers
  drawerBg: '#fff',
  drawerHeader: '#757575',
  drawerActiveTint: '#fff',
  drawerInactiveTint: qidoGrey,
  drawerActiveBg: 'rgba(0, 0, 0, 0.2)',
  drawerInactiveBg: 'transparent',
};
