import React, {useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';

import * as actions from '../actions/member';
import useActions from '../hooks/useActions';

import Overrides from '../constants/Overrides';

import FileOverride from '../components/FileOverride';

function onError(err) {
  alert(err.message || err);
}

function Login(props) {
  const history = useHistory();
  const {login} = useActions(actions);
  const onForgotPassword = useCallback(() => history.push('/forgot_password'), [history]);
  const onLogin = useCallback(
    ({email, password}, {setSubmitting}) => {
      login(email, password).catch(err => {
        onError(err);
        setSubmitting(false);
      });
    },
    [login],
  );
  const onSignup = useCallback(() => history.push('/signup'), [history]);
  const AuthHeader = useMemo(
    () => Overrides.get('components', 'AuthHeader'),
    [],
  );
  return useMemo(
    () => (
      <FileOverride namespace="routes" filename="Login">
        {Component => (
          <Component
            headerComponent={AuthHeader}
            onForgotPassword={onForgotPassword}
            onLogin={onLogin}
            onSignup={onSignup}
          />
        )}
      </FileOverride>
    ),
    [AuthHeader, onForgotPassword, onLogin, onSignup],
  );
}

export default Login;
