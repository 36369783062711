import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';

import * as actions from '../actions/member';

import useActions from '../hooks/useActions';
import useTitle from '../hooks/useTitle';

import Loading from '../components/Loading';

import Component from '../routes/AccountDetails';

const selector = ({member}) => ({member});

function onError(err) {
  alert(err.error || err.message || err);
}

function AccountDetails() {
  const history = useHistory();
  const {t} = useTranslation('AccountDetails');
  const {enqueueSnackbar} = useSnackbar();
  const {getMember, updateNotifications, updatePassword} = useActions(actions);
  const {member} = useSelector(selector);
  const [loaded, setLoaded] = useState(false);
  useTitle(t('AccountDetails:AccountDetails'));

  useEffect(() => {
    getMember()
      .then(() => setLoaded(true));
  }, [getMember, loaded]);

  const onEdit = useCallback(() => history.push('/profile/edit'), [history]);

  const onUpdatePassword = useCallback(
    (values, {setSubmitting}) => {
      if (!values.password) {
        setSubmitting(false);
        return onError(t('Please enter your password.'));
      }

      if (!values.new_password) {
        setSubmitting(false);
        return onError(t('Please enter your new password.'));
      }

      if (values.new_password !== values.new_password_confirmation) {
        setSubmitting(false);
        return onError(t("Password doesn't match confirmation."));
      }

      return updatePassword(values)
        .then(() => {
          setSubmitting(false);
          enqueueSnackbar(t('Your password has been updated.'));
        })
        .catch(err => {
          setSubmitting(false);
          onError(err);
        });
    },
    [enqueueSnackbar, t, updatePassword],
  );

  return useMemo(
    () => {
      if(!loaded)
        return (<Loading />);
      return (
        <Component
          member={member}
          onEdit={onEdit}
          onUpdateNotifications={updateNotifications}
          onUpdatePassword={onUpdatePassword} />
      );
    },
    [loaded, member, onEdit, onUpdatePassword, updateNotifications],
  );
}

export default AccountDetails;
