import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

import {Box, Paper, Tab, Tabs} from '@material-ui/core';

import {
  Activity as ActivityIcon,
  Integrations as DevicesIcon,
  Home as HomeIcon,
  Members as SettingsIcon,
} from '@scout-app/assets/icons';

import * as actions from '../actions/location';

import Activity from './Activity';
import Home from './Home';
import Devices from './Devices';
import Settings from './Settings';

import Loading from '../components/Loading';

import useActions from '../hooks/useActions';
import useLocation from '../hooks/useLocation';
import usePrevious from '../hooks/usePrevious';
import usePusher from '../hooks/usePusher';
import useStyleSheet from '../hooks/useStyleSheet';
import useTitle from '../hooks/useTitle';

const parseMixins = (mixin, attr, func) =>
  Object.keys(mixin).reduce((result, key) => {
    if(key === attr) {
      return {
        ...result,
        ...func(mixin[attr]),
      };
    }
    return {
      ...result,
      [key]: func(mixin[key][attr]),
    };
  }, {});

const styleSheet = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'center',
  },
  content: {
    overflow: 'scroll',
    width: '100%',
    ...parseMixins(theme.mixins.toolbar, 'minHeight', (minHeight) => ({ height: `calc(100vh - 48px - ${minHeight}px)` }))
  },
  tabbar: {
    width: '100%',
  },
});

const TABS = [
  {
    icon: HomeIcon,
    component: Home,
  },
  {
    icon: ActivityIcon,
    component: Activity,
  },
  {
    icon: DevicesIcon,
    component: Devices,
    iconProps: {
      style: {
        fontSize: 26,
      }
    }
  },
  {
    icon: SettingsIcon,
    component: Settings,
    iconProps: {
      style: {
        fontSize: 36,
      }
    }
  }
];

function TabPanel({children, index, value, ...rest}) {
  return (
    <div hidden={value !== index} {...rest}>
      {value === index && children}
    </div>
  );
}

export default function Location() {
  const {location_id} = useParams();
  const previous = usePrevious(location_id);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState(0);

  const {getLocation} = useActions(actions);
  const location = useLocation();
  const classes = useStyleSheet(styleSheet);
  const handleChange = useCallback((event, value) => setTab(value), []);

  const children = useMemo(() => TABS.map(({component: Component}, i) => (
    <TabPanel key={i} index={i} value={tab}>
      <Component />
    </TabPanel>
  )), [tab]);

  const tabs = useMemo(() => TABS.map(({icon: Icon, iconProps = {}}, i) => (
    <Tab key={i} icon={<Icon {...iconProps} />} />
  )), []);

  useEffect(() => {
    if(location_id !== previous) {
      setLoading(true);
      getLocation(location_id).then(() => setLoading(false));
    }
  }, [getLocation, location_id, previous]);

  usePusher(location.id);
  useTitle(location.name);

  return useMemo(() => {
    if(loading || (location_id !== previous)) {
      return (<Loading />);
    }
    return (
      <Box className={classes.container}>
        <Box className={classes.content}>
          {children}
        </Box>
        <Paper square className={classes.tabbar}>
          <Tabs
            value={tab}
            onChange={handleChange}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary">
            {tabs}
          </Tabs>
        </Paper>
      </Box>
    );
  }, [children, classes.container, classes.content, classes.tabbar, handleChange, loading, location_id, previous, tab, tabs]);
}
