import React, {useMemo} from 'react';

import ct from 'countries-and-timezones';

import {fieldToSelect} from 'formik-material-ui';

import {MenuItem, TextField} from '@material-ui/core';

import Overrides from '../../constants/Overrides';

function TimezonePicker({country, ...props}) {
  const inputProps = useMemo(() => fieldToSelect(props), [props]);
  const {timezones} = useMemo(() => ct.getCountry(country), [country]);
  const children = useMemo(() => timezones.map((value, i) => (
    <MenuItem key={i} value={value}>{value.replace(/_/g, ' ')}</MenuItem>
  )), [timezones]);
  return useMemo(() => (
    <TextField id={props.field.name} {...inputProps} select>
      {children}
    </TextField>
  ), [children, inputProps, props.field.name]);
}

TimezonePicker.getInitialValue = ({timezone} = {}, fallback) =>
  timezone ||
  fallback ||
  Overrides.get('vendor', 'default_timezone', 'America/Chicago');

export default TimezonePicker;
