import createTextComponent from './createTextComponent';

const H2Text = createTextComponent('H2Text', 'h4');

H2Text.defaultProps = {
  // marginBottom: Spacing.DEFAULT_SPACING,
  component: 'h2',
};

export default H2Text;
