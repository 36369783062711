import {useEffect} from 'react';

import useAppBar from './useAppBar';

function useRightButton(icon, onClick) {
  const {setRightButton} = useAppBar();
  useEffect(() => {
    setRightButton(icon && onClick ? {icon, onClick} : null);
    return () => {
      setRightButton(null);
    }
  }, [icon, onClick, setRightButton]);
}

export default useRightButton;
