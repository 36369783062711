import {
  CLEAR_OPTIMISTIC_UPDATE,
  OPTIMISTICLY_UPDATE_DEVICE,
} from '../actions/ActionTypes';

const initialState = {
  door_lock: {},
  switch: {},
  dimmer_switch: {},
  outlet: {},
  light_bulb: {},
};

export default (state = initialState, action) => {
  if (!(action.device_type in state)) {
    return state;
  }
  switch (action.type) {
    case OPTIMISTICLY_UPDATE_DEVICE:
      return {
        ...state,
        [action.device_type]: {
          ...state[action.device_type],
          [action.device_id]: action.device_data,
        },
      };
    case CLEAR_OPTIMISTIC_UPDATE:
      return {
        ...state,
        [action.device_type]: {
          ...state[action.device_type],
          [action.device_id]: null,
        },
      };
    default:
      return state;
  }
};
