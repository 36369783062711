import React, {useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {useSnackbar} from 'notistack';

import BackButton from '@material-ui/icons/ArrowBack';

import * as actions from '../actions/location';

import useActions from '../hooks/useActions';
import useLeftButton from '../hooks/useLeftButton';
import useLocation from '../hooks/useLocation';
import useTitle from '../hooks/useTitle';

import Component from '../routes/EditLocation';

const requiredFields = [
  'name',
  'structure_type',
  'addr1',
  'city',
  'state',
  'zipcode',
  'country',
  'timezone',
];

const selectFields = ['country', 'timezone'];

function getMissingI18nKey(key) {
  if (selectFields.includes(key)) {
    return 'MissingSelect';
  }
  return 'MissingInput';
}


function onError(err) {
  alert(err.error || err.message || err);
}

function EditLocation() {
  const history = useHistory();
  const {t} = useTranslation('EditLocation');
  const {enqueueSnackbar} = useSnackbar();
  const {deleteLocation, updateLocation} = useActions(actions);
  const location = useLocation();

  useTitle(t('EditLocation:EditLocation'));
  useLeftButton(BackButton, history.goBack);
  // TODO - add delete button if admin + no devices

  const onSubmit = useCallback(
    (data, {setSubmitting}) => {
      const {
        name,
        structure_type,
        addr1,
        addr2,
        city,
        state,
        zipcode,
        country,
        timezone,
      } = data;
      for (let i = 0; i < requiredFields.length; i++) {
        const key = requiredFields[i];
        if (!data[key]) {
          setSubmitting(false);
          return onError(
            t(getMissingI18nKey(key), {placeholder: t(`placeholders.${key}`)}),
          );
        }
      }
      return updateLocation(location.id, {
        address: {
          addr1,
          addr2,
          city,
          state,
          zipcode,
          country,
        },
        name,
        structure_type,
        timezone,
      })
        .then(() => {
          enqueueSnackbar(t('LocationUpdated'));
          history.goBack();
        })
        .catch(err => {
          setSubmitting(false);
          onError(err);
        });
    },
    [enqueueSnackbar, history, location.id, t, updateLocation],
  );

  return useMemo(() => <Component location={location} onSubmit={onSubmit} />, [
    location,
    onSubmit,
  ]);
}

export default EditLocation;
