import {useTranslation} from 'react-i18next';

export default function useDeviceState(type, {currentState}) {
  const {t} = useTranslation(type);
  if (Array.isArray(currentState)) {
    return currentState.map(state => t(state)).join(', ');
  }
  if (currentState) {
    return t(currentState);
  }
}
