import Overrides from '../constants/Overrides';
import createAppContainer from '../containers/App';
import createStore from './store';

const CONFIG_WHITELIST = [
  'colors',
  'components',
  'elements',
  'images',
  'integrations',
  'routes',
  'styles',
  'vectors',
  'vendor',
];

class SharedApp {
  constructor(config = {}) {
    this.config = config;
    this.navigators = {};
    this.applyOverrides();
    this.createStore();
  }

  applyOverrides() {
    CONFIG_WHITELIST.forEach(namespace => {
      const value = this.config[namespace];
      if (value) {
        Overrides.set(namespace, value);
      }
    });
  }

  createStore() {
    const {store, persistor} = createStore(this.config);
    this.store = store;
    this.persistor = persistor;
  }

  build() {
    return createAppContainer(this);
  }
}

export default SharedApp;
