import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Field, Form, Formik} from 'formik';
import {Container, FormControl} from '@material-ui/core';
import {TextField} from 'formik-material-ui';

import Overrides from '../constants/Overrides';

import {EmailInput} from '../components/form';
import Button from '../elements/Button';

import useStyleSheet from '../hooks/useStyleSheet';

import {validateEmail, validatePhone} from '../lib/validation';

const styleSheet = {
  container: {
    padding: 24,
  },
};

function EditProfile({member, onUpdate}) {
  const {t} = useTranslation('EditProfile');
  const classes = useStyleSheet(styleSheet);
  const {email = '', fname = '', lname = '', phone_number = ''} = member;
  const initialValues = useMemo(
    () => ({
      email,
      fname,
      lname,
      phone_number,
    }),
    [email, fname, lname, phone_number],
  );
  const validate = useCallback(
    values => {
      const defaultCountry = Overrides.get(
        'vendor',
        'default_country',
        'US',
      );
      const errors = {};

      if (!values.fname) {
        errors.fname = t('MissingInput', {
          placeholder: t('placeholders.fname'),
        });
      }

      if (!values.lname) {
        errors.lname = t('MissingInput', {
          placeholder: t('placeholders.lname'),
        });
      }

      if (!values.phone_number) {
        errors.phone_number = t('MissingInput', {
          placeholder: t('placeholders.phone'),
        });
      }
      else if (
        defaultCountry === 'US' &&
        !validatePhone(values.phone_number, defaultCountry)
      ) {
        errors.phone_number = t('InvalidPhone');
      }

      if(!values.email) {
        errors.email = t('MissingInput', {
          placeholder: t('placeholders.email'),
        });
      }
      else if (!validateEmail(values.email)) {
        errors.email = t('InvalidEmail');
      }

      if (Object.keys(errors).length) {
        return errors;
      }
    },
    [t],
  );
  return useMemo(
    () => (
      <Container maxWidth="md" className={classes.container}>
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={onUpdate}>
          {({ submitForm, isSubmitting }) => (
            <Form>
              <FormControl fullWidth>
                <Field
                  component={TextField}
                  type="text"
                  autoComplete="given-name"
                  name="fname"
                  placeholder={t('placeholders.fname')} />
                <br />
                <Field
                  component={TextField}
                  type="text"
                  autoComplete="family-name"
                  name="lname"
                  placeholder={t('placeholders.lname')} />
                <br />
                <Field
                  component={TextField}
                  type="tel"
                  autoComplete="tel"
                  name="phone_number"
                  placeholder={t('placeholders.phone')} />
                <br />
                <Field
                  component={EmailInput}
                  name="email" />
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  i18nKey={
                    isSubmitting ? 'Common:common.updating' : 'Common:common.update'
                  }
                  onClick={submitForm}
                  disabled={isSubmitting}>
                  {isSubmitting ? 'Updating...' : 'Update'}
                </Button>
              </FormControl>
            </Form>
          )}
        </Formik>
      </Container>
    ),
    [classes.container, initialValues, onUpdate, t, validate],
  );
}

export default EditProfile;
