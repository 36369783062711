import React, {useMemo} from 'react';

import {countries} from 'countries-list';

import {fieldToSelect} from 'formik-material-ui';

import {MenuItem, TextField} from '@material-ui/core';

import Overrides from '../../constants/Overrides';

const data = Object.keys(countries).reduce((result, value) => [
  ...result,
  {
    name: countries[value].name,
    value,
  }
], []).sort((c1, c2) => c1.name.localeCompare(c2.name))

function CountryPicker(props) {
  const inputProps = useMemo(() => fieldToSelect(props), [props]);
  const children = useMemo(() => data.map(({name, value}, i) => (
    <MenuItem key={i} value={value}>{name}</MenuItem>
  )), []);
  return useMemo(() => (
    <TextField id={props.field.name} {...inputProps} select>
      {children}
    </TextField>
  ), [children, inputProps, props.field.name]);
}

CountryPicker.getInitialValue = ({country} = {}, fallback) =>
  country ||
  fallback ||
  Overrides.get('vendor', 'default_country', 'US');

export default CountryPicker;
