import React, {useMemo} from 'react';

import clsx from 'clsx';

import {Hub as HubIcon} from '@scout-app/assets/icons';

import LongPressable from './LongPressable';

import Text from '../elements/Text';

import useDeviceState from '../hooks/useDeviceState';
import useModel from '../hooks/useModel';

import Model from '../models/Hub';

function Hub(props) {
  const {classes, onPress} = props;
  const hub = useModel(props.hub, Model);
  const currentState = useDeviceState('Hub', hub);
  return useMemo(() => {
    let color = null;
    if (hub.isOffline()) {
      color = 'red';
    }
    else if (hub.onBattery() || hub.onCellular()) {
     color = 'yellow';
    }
    return (
      <LongPressable className={clsx(classes.toggle, classes[color])} onClick={() => onPress(hub)}>
        <HubIcon className={clsx(classes.icon, classes[color])} />
        <div className={classes.state}>
          <Text className={clsx(classes.info, classes[color])}>{currentState}</Text>
        </div>
        <Text className={clsx(classes.name, classes[color])}>{hub.name}</Text>
      </LongPressable>
    );
  }, [classes, currentState, hub, onPress]);
}

export default Hub;
