import React, {useMemo} from 'react';

import clsx from 'clsx';

import LongPressable from './LongPressable';

function Integration({classes, index, integration, onPress}) {
  const Icon = integration.icon;
  return useMemo(() => {
    let color = null;
    return (
      <LongPressable
        className={clsx(classes.toggle, classes[color])}
        onClick={() => onPress(integration)}>
        <Icon className={clsx(classes.icon, classes[integration.key])} />
      </LongPressable>
    );
  }, [classes, integration, onPress]);
}

export default Integration;
