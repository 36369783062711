/* eslint global-require: 0 */
import {get as _get} from 'lodash';

import Overrides from './Overrides';

const DEVICES = {
  HUB: require('@scout-app/assets/img/devices/hub.png'),
  DOORBELL: require('@scout-app/assets/img/devices/doorbell.png'),
  DOOR_PANEL: require('@scout-app/assets/img/devices/door_panel.png'),
  ACCESS_SENSOR: require('@scout-app/assets/img/devices/access_sensor.png'),
  SERCOMM: {
    HUB: require('@scout-app/assets/img/devices/sercomm_hub.png'),
    ACCESS_SENSOR: require('@scout-app/assets/img/devices/sercomm_access_sensor.png'),
    MOTION_SENSOR: require('@scout-app/assets/img/devices/sercomm_motion_sensor.png'),
    SMOKE_ALARM: require('@scout-app/assets/img/devices/sercomm_smoke_alarm.png'),
    WATER_SENSOR: require('@scout-app/assets/img/devices/sercomm_water_sensor.png'),
  },
  MOTION_SENSOR: require('@scout-app/assets/img/devices/motion_sensor.png'),
  WATER_SENSOR: require('@scout-app/assets/img/devices/sercomm_water_sensor.png'),
  GLASS_BREAK: {
    GOCONTROL: require('@scout-app/assets/img/devices/gocontrol_glass_break.png'),
    JOHNSON_CONTROLS: require('@scout-app/assets/img/devices/glass_break.png'),
  },
  SMOKE_ALARM: require('@scout-app/assets/img/devices/smoke_alarm.png'),
  DOOR_LOCK: {
    YALE: require('@scout-app/assets/img/devices/yale_door_lock.png'),
    KWIKSET: require('@scout-app/assets/img/devices/kwikset_door_lock.png'),
  },
  NEST: {
    PROTECT: require('@scout-app/assets/img/devices/nest_protect.png'),
  },
  SWITCH: require('@scout-app/assets/img/devices/switch.png'),
  CAMERA: require('@scout-app/assets/img/devices/camera.png'),
  CAMERA_RECORDING: require('@scout-app/assets/img/devices/camera_recording.png'),
  KEYPAD: require('@scout-app/assets/img/devices/keypad.png'),
  SIREN: require('@scout-app/assets/img/devices/siren.png'),
  REMOTE_CONTROL: require('@scout-app/assets/img/devices/remote_control.png'),
  PANIC_BUTTON: require('@scout-app/assets/img/devices/panic_button.png'),
};

const HUB = {
  PULL_TABS: require('@scout-app/assets/img/installation/hub/pull_tab_icon.png'),
  SENSOR_SEQUENCE: require('@scout-app/assets/img/installation/hub/sensor_sequence.png'),
  POWER: require('@scout-app/assets/img/installation/hub/power.png'),
  CONNECT: require('@scout-app/assets/img/installation/hub/connect.png'),
  LIGHTS: require('@scout-app/assets/img/installation/hub/light.png'),
  ENTER_CODE: require('@scout-app/assets/img/installation/hub/enter_code.png'),
  PAIRING: {
    UNFILLED: require('@scout-app/assets/img/installation/hub/pairing_unfilled.png'),
    FILLED: require('@scout-app/assets/img/installation/hub/pairing_filled.png'),
  },
  LIGHT_KEY: require('@scout-app/assets/img/installation/light_key.png'),
  SERCOMM: {
    POWER: require('@scout-app/assets/img/installation/hub/sercomm_power.png'),
    CONNECT: require('@scout-app/assets/img/installation/hub/sercomm_connect.png'),
    LIGHTS: require('@scout-app/assets/img/installation/hub/sercomm_light.png'),
    ENTER_CODE: require('@scout-app/assets/img/installation/hub/sercomm_enter_code.png'),
    PAIRING: {
      UNFILLED: require('@scout-app/assets/img/installation/hub/sercomm_pairing_unfilled.png'),
      FILLED: require('@scout-app/assets/img/installation/hub/sercomm_pairing_filled.png'),
    },
  },
};

const DOOR_PANEL = {
  OPEN: require('@scout-app/assets/img/devices/door_panel_open.png'),
  CLOSE: require('@scout-app/assets/img/devices/door_panel_close.png'),
  DESCRIPTION: require('@scout-app/assets/img/installation/door_panel/description.png'),
  PAIRING: {
    FILLED: require('@scout-app/assets/img/installation/door_panel/pairing_filled.png'),
    UNFILLED: require('@scout-app/assets/img/installation/door_panel/pairing_unfilled.png'),
  },
  FOB_REGISTRATION: require('@scout-app/assets/img/installation/door_panel/fob_registration.png'),
  RFID_SWIPE: require('@scout-app/assets/img/devices/rfid_swipe.png'),
  LEVEL_PLACEMENT: require('@scout-app/assets/img/installation/door_panel/level_placement.png'),
  ADHESIVE: require('@scout-app/assets/img/installation/door_panel/adhesive.png'),
  LAYOUT_1: require('@scout-app/assets/img/installation/door_panel/layout_1.png'),
  LAYOUT_2: require('@scout-app/assets/img/installation/door_panel/layout_2.png'),
  ADHESIVE_SMALL: require('@scout-app/assets/img/installation/door_panel/adhesive_small.png'),
};

const ACCESS_SENSOR = {
  OPEN: require('@scout-app/assets/img/devices/access_sensor_open.png'),
  CLOSE: require('@scout-app/assets/img/devices/access_sensor_close.png'),
  DESCRIPTION: require('@scout-app/assets/img/installation/access_sensor/description.png'),
  PAIRING: {
    FILLED: require('@scout-app/assets/img/installation/access_sensor/pairing_filled.png'),
    UNFILLED: require('@scout-app/assets/img/installation/access_sensor/pairing_unfilled.png'),
  },
  PLACEMENT: require('@scout-app/assets/img/installation/access_sensor/placement.png'),
  ADHESIVE: require('@scout-app/assets/img/installation/access_sensor/adhesive.png'),
  ADHESIVE_SMALL: require('@scout-app/assets/img/installation/access_sensor/adhesive_small.png'),
  LAYOUT_1: require('@scout-app/assets/img/installation/access_sensor/layout_1.png'),
  LAYOUT_2: require('@scout-app/assets/img/installation/access_sensor/layout_2.png'),
  SERCOMM: {
    DESCRIPTION: require('@scout-app/assets/img/installation/access_sensor/sercomm_description.png'),
    PAIRING: {
      FILLED: require('@scout-app/assets/img/installation/access_sensor/sercomm_pairing_filled.png'),
      UNFILLED: require('@scout-app/assets/img/installation/access_sensor/sercomm_pairing_unfilled.png'),
    },
    ADHESIVE: require('@scout-app/assets/img/installation/access_sensor/sercomm_adhesive.png'),
    ADHESIVE_SMALL: require('@scout-app/assets/img/installation/access_sensor/sercomm_adhesive_small.png'),
  },
};

const MOTION_SENSOR = {
  MOTION_START: require('@scout-app/assets/img/devices/motion_sensor_motion_start.png'),
  MOTION_STOP: require('@scout-app/assets/img/devices/motion_sensor_motion_stop.png'),
  DESCRIPTION: require('@scout-app/assets/img/installation/motion_sensor/description.png'),
  PAIRING: {
    FILLED: require('@scout-app/assets/img/installation/motion_sensor/pairing_filled.png'),
    UNFILLED: require('@scout-app/assets/img/installation/motion_sensor/pairing_unfilled.png'),
  },
  PLACEMENT: require('@scout-app/assets/img/installation/motion_sensor/placement.png'),
  SERCOMM: {
    DESCRIPTION: require('@scout-app/assets/img/installation/motion_sensor/sercomm_description.png'),
    PAIRING: {
      FILLED: require('@scout-app/assets/img/installation/motion_sensor/sercomm_pairing_filled.png'),
      UNFILLED: require('@scout-app/assets/img/installation/motion_sensor/sercomm_pairing_unfilled.png'),
    },
    PLACEMENT: require('@scout-app/assets/img/installation/motion_sensor/sercomm_placement.png'),
  },
};

const SMOKE_ALARM = {
  TESTING: require('@scout-app/assets/img/devices/smoke_alarm_testing.png'),
  SMOKE: require('@scout-app/assets/img/devices/smoke_alarm_smoke.png'),
  CO: require('@scout-app/assets/img/devices/smoke_alarm_co.png'),
  OK: require('@scout-app/assets/img/devices/smoke_alarm_ok.png'),
  DESCRIPTION: require('@scout-app/assets/img/installation/smoke_alarm/description.png'),
  PAIRING: {
    FILLED: require('@scout-app/assets/img/installation/smoke_alarm/pairing_filled.png'),
    UNFILLED: require('@scout-app/assets/img/installation/smoke_alarm/pairing_unfilled.png'),
  },
  LAYOUT_1: require('@scout-app/assets/img/installation/smoke_alarm/layout_1.png'),
  LAYOUT_2: require('@scout-app/assets/img/installation/smoke_alarm/layout_2.png'),
  SERCOMM: {
    DESCRIPTION: require('@scout-app/assets/img/installation/smoke_alarm/sercomm_description.png'),
    PAIRING: {
      FILLED: require('@scout-app/assets/img/installation/smoke_alarm/sercomm_pairing_filled.png'),
      UNFILLED: require('@scout-app/assets/img/installation/smoke_alarm/sercomm_pairing_unfilled.png'),
    },
    BATTERIES: require('@scout-app/assets/img/installation/smoke_alarm/sercomm_batteries.png'),
    BRACKET: require('@scout-app/assets/img/installation/smoke_alarm/sercomm_mounting_bracket.png'),
    MOUNTING: require('@scout-app/assets/img/installation/smoke_alarm/sercomm_mounting.png'),
  },
};

const GLASS_BREAK = {
  TESTING: require('@scout-app/assets/img/devices/glass_break_testing.png'),
  OK: require('@scout-app/assets/img/devices/glass_break_ok.png'),
  SHATTER: require('@scout-app/assets/img/devices/glass_break_shatter.png'),
  GOCONTROL: {
    DESCRIPTION: require('@scout-app/assets/img/installation/glass_break/gocontrol_description.png'),
    PAIRING: {
      FILLED: require('@scout-app/assets/img/installation/glass_break/gocontrol_pairing_filled.png'),
      UNFILLED: require('@scout-app/assets/img/installation/glass_break/gocontrol_pairing_unfilled.png'),
    },
    PLACEMENT: require('@scout-app/assets/img/installation/glass_break/gocontrol_placement.png'),
    BATTERIES: require('@scout-app/assets/img/installation/glass_break/gocontrol_batteries.png'),
  },
  JOHNSON_CONTROLS: {
    DESCRIPTION: require('@scout-app/assets/img/installation/glass_break/johnson_controls_description.png'),
    PAIRING: {
      FILLED: require('@scout-app/assets/img/installation/glass_break/johnson_controls_pairing_filled.png'),
      UNFILLED: require('@scout-app/assets/img/installation/glass_break/johnson_controls_pairing_unfilled.png'),
    },
    BATTERIES_TAB: {
      FRONT: require('@scout-app/assets/img/installation/glass_break/johnson_controls_front_tab.png'),
      BACK: require('@scout-app/assets/img/installation/glass_break/johnson_controls_back_tab.png'),
    },
    BATTERIES: {
      REMOVE: require('@scout-app/assets/img/installation/glass_break/johnson_controls_battery_remove.png'),
      CLOSE: require('@scout-app/assets/img/installation/glass_break/johnson_controls_close_cover.png'),
    },
    PLACEMENT: require('@scout-app/assets/img/installation/glass_break/johnson_controls_mounting_bracket.png'),
    MOUNTING: require('@scout-app/assets/img/installation/glass_break/johnson_controls_adhesive_remove.png'),
  },
};

const CAMERA = {
  PAIRING: {
    FILLED: require('@scout-app/assets/img/installation/camera/pairing_filled.png'),
    UNFILLED: require('@scout-app/assets/img/installation/camera/pairing_unfilled.png'),
  },
  DESCRIPTION: require('@scout-app/assets/img/installation/camera/description.png'),
  POWER: require('@scout-app/assets/img/installation/camera/power.png'),
  CONNECT_WIFI: require('@scout-app/assets/img/installation/camera/connect_wifi.png'),
  CONNECTION: {
    FILLED: require('@scout-app/assets/img/installation/camera/connection_filled.png'),
    UNFILLED: require('@scout-app/assets/img/installation/camera/connection_unfilled.png'),
  },
  PLACEMENT: require('@scout-app/assets/img/installation/camera/placement.png'),
  RECORDING: require('@scout-app/assets/img/installation/camera/recording.png'),
};

const DOORBELL = {
  PAIRING: {
    FILLED: require('@scout-app/assets/img/installation/doorbell/pairing_filled.png'),
    UNFILLED: require('@scout-app/assets/img/installation/doorbell/pairing_unfilled.png'),
  },
  DESCRIPTION: require('@scout-app/assets/img/installation/doorbell/description.png'),
  POWER: require('@scout-app/assets/img/installation/doorbell/power_on.png'),
  USB: require('@scout-app/assets/img/installation/doorbell/usb.png'),
  CONNECT_WIFI: require('@scout-app/assets/img/installation/doorbell/connect_wifi.png'),
  CONNECTION: {
    FILLED: require('@scout-app/assets/img/installation/doorbell/connection_filled.png'),
    UNFILLED: require('@scout-app/assets/img/installation/doorbell/connection_unfilled.png'),
  },
  WARNING: require('@scout-app/assets/img/installation/doorbell/warning.png'),
  ELECTRICITY: {
    OFF: require('@scout-app/assets/img/installation/doorbell/electricity_off.png'),
    ON: require('@scout-app/assets/img/installation/doorbell/electricity_on.png'),
  },
  REMOVAL: require('@scout-app/assets/img/installation/doorbell/removal.png'),
  PLATE: {
    INSTALL: require('@scout-app/assets/img/installation/doorbell/plate_install.png'),
    CONNECT: require('@scout-app/assets/img/installation/doorbell/plate_connect.png'),
    ATTACH: require('@scout-app/assets/img/installation/doorbell/plate_attach.png'),
  },
  INSTALLED: require('@scout-app/assets/img/installation/doorbell/installed.png'),
  RECORDING: require('@scout-app/assets/img/installation/doorbell/recording.png'),
  POWER_KIT: {
    DESCRIPTION: require('@scout-app/assets/img/installation/doorbell/power_kit.png'),
    SCREWS: require('@scout-app/assets/img/installation/doorbell/power_kit_screws.png'),
    HELP_1: require('@scout-app/assets/img/installation/doorbell/power_kit_help_1.png'),
    HELP_2: require('@scout-app/assets/img/installation/doorbell/power_kit_help_2.png'),
    INTERIOR: require('@scout-app/assets/img/installation/doorbell/power_kit_interior.png'),
    EXTERIOR: require('@scout-app/assets/img/installation/doorbell/power_kit_exterior.png'),
  },
};

const SWITCH = {
  PAIRING: {
    FILLED: require('@scout-app/assets/img/installation/switch/pairing_filled.png'),
    UNFILLED: require('@scout-app/assets/img/installation/switch/pairing_unfilled.png'),
  },
  DESCRIPTION: require('@scout-app/assets/img/installation/switch/description.png'),
  LAYOUT: require('@scout-app/assets/img/installation/switch/profile_1.png'),
  PROFILE: require('@scout-app/assets/img/installation/switch/profile_2.png'),
};

const WATER_SENSOR = {
  DRY: require('@scout-app/assets/img/devices/water_sensor_dry.png'),
  WET: require('@scout-app/assets/img/devices/water_sensor_wet.png'),
  DESCRIPTION: require('@scout-app/assets/img/installation/water_sensor/description.png'),
  PAIRING: {
    FILLED: require('@scout-app/assets/img/installation/water_sensor/pairing_filled.png'),
    UNFILLED: require('@scout-app/assets/img/installation/water_sensor/pairing_unfilled.png'),
  },
  BATTERIES: require('@scout-app/assets/img/installation/water_sensor/batteries.png'),
  LAYOUT_1: require('@scout-app/assets/img/installation/water_sensor/layout_1.png'),
  LAYOUT_2: require('@scout-app/assets/img/installation/water_sensor/layout_2.png'),
};

const DOOR_LOCK = {
  KWIKSET: {
    DESCRIPTION: require('@scout-app/assets/img/installation/door_lock/kwikset_description.png'),
    PAIRING: {
      FILLED: require('@scout-app/assets/img/installation/door_lock/kwikset_pairing_filled.png'),
      UNFILLED: require('@scout-app/assets/img/installation/door_lock/kwikset_pairing_unfilled.png'),
    },
  },
  YALE: {
    DESCRIPTION: require('@scout-app/assets/img/installation/door_lock/yale_description.png'),
    PAIRING: {
      FILLED: require('@scout-app/assets/img/installation/door_lock/yale_pairing_filled.png'),
      UNFILLED: require('@scout-app/assets/img/installation/door_lock/yale_pairing_unfilled.png'),
    },
  },
};

const KEY_PAD = {
  DESCRIPTION: require('@scout-app/assets/img/installation/key_pad/description.png'),
  PAIRING: {
    FILLED: require('@scout-app/assets/img/installation/key_pad/pairing_filled.png'),
    UNFILLED: require('@scout-app/assets/img/installation/key_pad/pairing_unfilled.png'),
  },
  BRACKET: require('@scout-app/assets/img/installation/key_pad/bracket.png'),
  MOUNTING: require('@scout-app/assets/img/installation/key_pad/mounting.png'),
  TESTING: require('@scout-app/assets/img/installation/key_pad/testing.png'),
  RESET: require('@scout-app/assets/img/installation/key_pad/reset.png'),
};

const SIREN = {
  DESCRIPTION: require('@scout-app/assets/img/installation/siren/description.png'),
  PAIRING: {
    FILLED: require('@scout-app/assets/img/installation/siren/pairing_filled.png'),
    UNFILLED: require('@scout-app/assets/img/installation/siren/pairing_unfilled.png'),
  },
  POWER: require('@scout-app/assets/img/installation/siren/power.png'),
  PIN: require('@scout-app/assets/img/installation/siren/insert_pin.png'),
  TESTING: require('@scout-app/assets/img/devices/siren_speaker.png'),
};

const REMOTE_CONTROL = {
  DESCRIPTION: require('@scout-app/assets/img/installation/remote_control/description.png'),
  PAIRING: {
    FILLED: require('@scout-app/assets/img/installation/remote_control/pairing_filled.png'),
    UNFILLED: require('@scout-app/assets/img/installation/remote_control/pairing_unfilled.png'),
  },
  PULL: require('@scout-app/assets/img/installation/remote_control/pull_tab.png'),
  PIN: require('@scout-app/assets/img/installation/remote_control/insert_pin.png'),
  TESTING: require('@scout-app/assets/img/devices/remote_control_testing.png'),
  OK: require('@scout-app/assets/img/devices/remote_control_ok.png'),
};

const PANIC_BUTTON = {
  DESCRIPTION: require('@scout-app/assets/img/installation/panic_button/description.png'),
  PAIRING: {
    FILLED: require('@scout-app/assets/img/installation/panic_button/pairing_filled.png'),
    UNFILLED: require('@scout-app/assets/img/installation/panic_button/pairing_unfilled.png'),
  },
  PULL: require('@scout-app/assets/img/installation/panic_button/pull_tab.png'),
  PIN: require('@scout-app/assets/img/installation/panic_button/insert_pin.png'),
  TESTING: require('@scout-app/assets/img/devices/panic_button_testing.png'),
  OK: require('@scout-app/assets/img/devices/panic_button_ok.png'),
};

const BETA = {
  PAIRING: {
    FILLED: require('@scout-app/assets/img/installation/beta/pairing_filled.png'),
    UNFILLED: require('@scout-app/assets/img/installation/beta/pairing_unfilled.png'),
  },
};

const MODE = {
  DEVICE: {
    ACCESS_SENSOR: require('@scout-app/assets/img/modes/access.png'),
    MOTION_SENSOR: require('@scout-app/assets/img/modes/motion.png'),
    DOOR_PANEL: require('@scout-app/assets/img/modes/door_panel.png'),
    CAMERA: require('@scout-app/assets/img/modes/camera.png'),
    GLASS_BREAK: require('@scout-app/assets/img/modes/glass_break.png'),
    NEST_CAMERA: require('@scout-app/assets/img/devices/nest_camera.png'),
    NEST_PROTECT: require('@scout-app/assets/img/devices/nest_protect_white.png'),
  },
  ACTION: {
    EMAIL: require('@scout-app/assets/img/modes/email.png'),
    PHONE_NUMBERS: require('@scout-app/assets/img/modes/phone_call.png'),
    PUSH: require('@scout-app/assets/img/modes/push.png'),
    SMS_NUMBERS: require('@scout-app/assets/img/modes/sms.png'),
    COUNT_DOWN: require('@scout-app/assets/img/modes/countdown.png'),
    ENTRY_DELAY: require('@scout-app/assets/img/modes/delay.png'),
    LIGHTS: require('@scout-app/assets/img/modes/lights.png'),
    PROFESSIONAL_MONITORING: require('@scout-app/assets/img/modes/professional_monitoring.png'),
    SIREN: require('@scout-app/assets/img/modes/siren.png'),
    CAMERA_RECORD: require('@scout-app/assets/img/modes/camera.png'),
  },
};

const MONITORING_PLANS = {
  PROFESSIONAL_MONITORING: require('@scout-app/assets/img/monitoring_plans/professional_monitoring.png'),
  CELLULAR_BACKUP: require('@scout-app/assets/img/monitoring_plans/cellular_backup_4g.png'),
  INDOOR_CAMERA: require('@scout-app/assets/img/monitoring_plans/indoor_camera.png'),
};

const VENDOR = {
  LOGO: require('../img/logo.png'),
};

const namespaces = {
  DEVICES,
  HUB,
  DOOR_PANEL,
  ACCESS_SENSOR,
  MOTION_SENSOR,
  SMOKE_ALARM,
  GLASS_BREAK,
  CAMERA,
  DOORBELL,
  SWITCH,
  WATER_SENSOR,
  DOOR_LOCK,
  BETA,
  MODE,
  MONITORING_PLANS,
  KEY_PAD,
  VENDOR,
  SIREN,
  REMOTE_CONTROL,
  PANIC_BUTTON,
};

function get(namespace, key) {
  const values = namespaces[namespace] || {};
  const src = Overrides.get('images', `${namespace}.${key}`, _get(values, key));
  if(src && src.default) {
    return src.default;
  }
  return src;
}

function getForHubType(hubType, namespace, key) {
  const imageForHubType = get(
    namespace,
    `${(hubType || '').toUpperCase()}.${key}`,
  );
  if (imageForHubType) {
    return imageForHubType;
  }
  return get(namespace, key);
}

function defer(namespace, key) {
  return () => get(namespace, key);
}

export default {defer, get, getForHubType};
