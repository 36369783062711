/* eslint global-require: 0 */
import {get as _get} from 'lodash';

import * as Devices from '@scout-app/assets/web/devices';

import * as AccessSensor from '@scout-app/assets/web/installation/access_sensor';
import * as Beta from '@scout-app/assets/web/installation/beta';
import * as Camera from '@scout-app/assets/web/installation/camera';
import * as DoorLock from '@scout-app/assets/web/installation/door_lock';
import * as DoorPanel from '@scout-app/assets/web/installation/door_panel';
import * as Doorbell from '@scout-app/assets/web/installation/doorbell';
import * as GlassBreak from '@scout-app/assets/web/installation/glass_break';
import * as Hub from '@scout-app/assets/web/installation/hub';
import * as KeyPad from '@scout-app/assets/web/installation/key_pad';
import * as MotionSensor from '@scout-app/assets/web/installation/motion_sensor';
import * as PanicButton from '@scout-app/assets/web/installation/panic_button';
import * as RemoteControl from '@scout-app/assets/web/installation/remote_control';
import * as Siren from '@scout-app/assets/web/installation/siren';
import * as SmokeAlarm from '@scout-app/assets/web/installation/smoke_alarm';
import * as Switch from '@scout-app/assets/web/installation/switch';
import * as WaterSensor from '@scout-app/assets/web/installation/water_sensor';

import Overrides from './Overrides';

const HUB = {
  PULL_TABS: Hub.PullTabIcon,
  SENSOR_SEQUENCE: Hub.SensorSequence,
  POWER: Hub.Power,
  CONNECT: Hub.Connect,
  LIGHTS: Hub.Light,
  ENTER_CODE: Hub.EnterCode,
  PAIRING: {
    UNFILLED: Hub.PairingUnfilled,
    FILLED: Hub.PairingFilled,
  },
  SERCOMM: {
    POWER: Hub.SercommPower,
    CONNECT: Hub.SercommConnect,
    LIGHTS: Hub.SercommLight,
    ENTER_CODE: Hub.SercommEnterCode,
    PAIRING: {
      UNFILLED: Hub.SercommPairingUnfilled,
      FILLED: Hub.SercommPairingFilled,
    },
  },
};

const DOOR_PANEL = {
  OPEN: Devices.DoorPanelOpen,
  CLOSE: Devices.DoorPanelClose,
  DESCRIPTION: DoorPanel.Description,
  PAIRING: {
    FILLED: DoorPanel.PairingFilled,
    UNFILLED: DoorPanel.PairingUnfilled,
  },
  FOB_REGISTRATION: DoorPanel.FobRegistration,
  RFID_SWIPE: Devices.RfidSwipe,
  LEVEL_PLACEMENT: DoorPanel.LevelPlacement,
  ADHESIVE: DoorPanel.Adhesive,
  LAYOUT_1: DoorPanel.Layout1,
  LAYOUT_2: DoorPanel.Layout2,
  ADHESIVE_SMALL: DoorPanel.AdhesiveSmall,
};

const ACCESS_SENSOR = {
  OPEN: Devices.AccessSensorOpen,
  CLOSE: Devices.AccessSensorClose,
  DESCRIPTION: AccessSensor.Description,
  PAIRING: {
    FILLED: AccessSensor.PairingFilled,
    UNFILLED: AccessSensor.PairingUnfilled,
  },
  PLACEMENT: AccessSensor.Placement,
  ADHESIVE: AccessSensor.Adhesive,
  ADHESIVE_SMALL: AccessSensor.AdhesiveSmall,
  LAYOUT_1: AccessSensor.Layout1,
  LAYOUT_2: AccessSensor.Layout2,
  SERCOMM: {
    DESCRIPTION: AccessSensor.SercommDescription,
    PAIRING: {
      FILLED: AccessSensor.SercommPairingFilled,
      UNFILLED: AccessSensor.SercommPairingUnfilled,
    },
    ADHESIVE: AccessSensor.SercommAdhesive,
    ADHESIVE_SMALL: AccessSensor.SercommAdhesiveSmall,
  },
};

const MOTION_SENSOR = {
  MOTION_START: Devices.MotionSensorMotionStart,
  MOTION_STOP: Devices.MotionSensorMotionStop,
  DESCRIPTION: MotionSensor.Description,
  PAIRING: {
    FILLED: MotionSensor.PairingFilled,
    UNFILLED: MotionSensor.PairingUnfilled,
  },
  PLACEMENT: MotionSensor.Placement,
  SERCOMM: {
    DESCRIPTION: MotionSensor.SercommDescription,
    PAIRING: {
      FILLED: MotionSensor.SercommPairingFilled,
      UNFILLED: MotionSensor.SercommPairingUnfilled,
    },
    PLACEMENT: MotionSensor.SercommPlacement,
  },
};

const SMOKE_ALARM = {
  TESTING: Devices.SmokeAlarmTesting,
  OK: Devices.SmokeAlarmOk,
  DESCRIPTION: SmokeAlarm.Description,
  PAIRING: {
    FILLED: SmokeAlarm.PairingFilled,
    UNFILLED: SmokeAlarm.PairingUnfilled,
  },
  LAYOUT_1: SmokeAlarm.Layout1,
  LAYOUT_2: SmokeAlarm.Layout2,
  SERCOMM: {
    DESCRIPTION: SmokeAlarm.SercommDescription,
    PAIRING: {
      FILLED: SmokeAlarm.SercommPairingFilled,
      UNFILLED: SmokeAlarm.SercommPairingUnfilled,
    },
    BATTERIES: SmokeAlarm.SercommBatteries,
    BRACKET: SmokeAlarm.SercommMountingBracket,
    MOUNTING: SmokeAlarm.SercommMounting,
  },
};

const GLASS_BREAK = {
  TESTING: Devices.GlassBreakTesting,
  OK: Devices.GlassBreakOk,
  GOCONTROL: {
    DESCRIPTION: GlassBreak.GocontrolDescription,
    PAIRING: {
      FILLED: GlassBreak.GocontrolPairingFilled,
      UNFILLED: GlassBreak.GocontrolPairingUnfilled,
    },
    PLACEMENT: GlassBreak.GocontrolPlacement,
    BATTERIES: GlassBreak.GocontrolBatteries,
  },
  JOHNSON_CONTROLS: {
    DESCRIPTION: GlassBreak.JohnsonControlsDescription,
    PAIRING: {
      FILLED: GlassBreak.JohnsonControlsPairingFilled,
      UNFILLED: GlassBreak.JohnsonControlsPairingUnfilled,
    },
    BATTERIES_TAB: {
      FRONT: GlassBreak.JohnsonControlsFrontTab,
      BACK: GlassBreak.JohnsonControlsBackTab,
    },
    BATTERIES: {
      REMOVE: GlassBreak.JohnsonControlsBatteryRemove,
      CLOSE: GlassBreak.JohnsonControlsCloseCover,
    },
    PLACEMENT: GlassBreak.JohnsonControlsMountingBracket,
    MOUNTING: GlassBreak.JohnsonControlsAdhesiveRemove,
  },
};

const CAMERA = {
  PAIRING: {
    FILLED: Camera.PairingFilled,
    UNFILLED: Camera.PairingUnfilled,
  },
  DESCRIPTION: Camera.Description,
  POWER: Camera.Power,
  CONNECT_WIFI: Camera.ConnectWifi,
  CONNECTION: {
    FILLED: Camera.ConnectionFilled,
    UNFILLED: Camera.ConnectionUnfilled,
  },
  PLACEMENT: Camera.Placement,
  RECORDING: Camera.Recording,
};

const DOORBELL = {
  PAIRING: {
    FILLED: Doorbell.PairingFilled,
    UNFILLED: Doorbell.PairingUnfilled,
  },
  DESCRIPTION: Doorbell.Description,
  POWER: Doorbell.PowerOn,
  USB: Doorbell.Usb,
  CONNECT_WIFI: Doorbell.ConnectWifi,
  CONNECTION: {
    FILLED: Doorbell.ConnectionFilled,
    UNFILLED: Doorbell.ConnectionUnfilled,
  },
  WARNING: Doorbell.Warning,
  ELECTRICITY: {
    OFF: Doorbell.ElectricityOff,
    ON: Doorbell.ElectricityOn,
  },
  REMOVAL: Doorbell.Removal,
  PLATE: {
    INSTALL: Doorbell.PlateInstall,
    CONNECT: Doorbell.PlateConnect,
    ATTACH: Doorbell.PlateAttach,
  },
  INSTALLED: Doorbell.Installed,
  RECORDING: Doorbell.Recording,
  POWER_KIT: {
    DESCRIPTION: Doorbell.PowerKit,
    SCREWS: Doorbell.PowerKitScrews,
    HELP_1: Doorbell.PowerKitHelp1,
    HELP_2: Doorbell.PowerKitHelp2,
    INTERIOR: Doorbell.PowerKitInterior,
    EXTERIOR: Doorbell.PowerKitExterior,
  },
};

const SWITCH = {
  PAIRING: {
    FILLED: Switch.PairingFilled,
    UNFILLED: Switch.PairingUnfilled,
  },
  DESCRIPTION: Switch.Description,
  LAYOUT: Switch.Profile1,
  PROFILE: Switch.Profile2,
};

const WATER_SENSOR = {
  DRY: Devices.WaterSensorDry,
  WET: Devices.WaterSensorWet,
  DESCRIPTION: WaterSensor.Description,
  PAIRING: {
    FILLED: WaterSensor.PairingFilled,
    UNFILLED: WaterSensor.PairingUnfilled,
  },
  BATTERIES: WaterSensor.Batteries,
  LAYOUT_1: WaterSensor.Layout1,
  LAYOUT_2: WaterSensor.Layout2,
};

const DOOR_LOCK = {
  KWIKSET: {
    DESCRIPTION: DoorLock.KwiksetDescription,
    PAIRING: {
      FILLED: DoorLock.KwiksetPairingFilled,
      UNFILLED: DoorLock.KwiksetPairingUnfilled,
    },
  },
  YALE: {
    DESCRIPTION: DoorLock.YaleDescription,
    PAIRING: {
      FILLED: DoorLock.YalePairingFilled,
      UNFILLED: DoorLock.YalePairingUnfilled,
    },
  },
};

const KEY_PAD = {
  DESCRIPTION: KeyPad.Description,
  PAIRING: {
    FILLED: KeyPad.PairingFilled,
    UNFILLED: KeyPad.PairingUnfilled,
  },
  BRACKET: KeyPad.Bracket,
  MOUNTING: KeyPad.Mounting,
  TESTING: KeyPad.Testing,
  RESET: KeyPad.Reset,
};

const SIREN = {
  DESCRIPTION: Siren.Description,
  PAIRING: {
    FILLED: Siren.PairingFilled,
    UNFILLED: Siren.PairingUnfilled,
  },
  POWER: Siren.Power,
  PIN: Siren.InsertPin,
  // TESTING: Siren.speaker,
};

const REMOTE_CONTROL = {
  DESCRIPTION: RemoteControl.Description,
  PAIRING: {
    FILLED: RemoteControl.PairingFilled,
    UNFILLED: RemoteControl.PairingUnfilled,
  },
  PULL: RemoteControl.PullTab,
  PIN: RemoteControl.InsertPin,
  TESTING: Devices.RemoteControlTesting,
  OK: Devices.RemoteControlOk,
};

const PANIC_BUTTON = {
  DESCRIPTION: PanicButton.Description,
  PAIRING: {
    FILLED: PanicButton.PairingFilled,
    UNFILLED: PanicButton.PairingUnfilled,
  },
  PULL: PanicButton.PullTab,
  PIN: PanicButton.InsertPin,
  TESTING: Devices.PanicButtonTesting,
  OK: Devices.PanicButtonOk,
};

const BETA = {
  PAIRING: {
    FILLED: Beta.PairingFilled,
    UNFILLED: Beta.PairingUnfilled,
  },
};

const namespaces = {
  HUB,
  DOOR_PANEL,
  ACCESS_SENSOR,
  MOTION_SENSOR,
  SMOKE_ALARM,
  GLASS_BREAK,
  CAMERA,
  DOORBELL,
  SWITCH,
  WATER_SENSOR,
  DOOR_LOCK,
  BETA,
  KEY_PAD,
  SIREN,
  REMOTE_CONTROL,
  PANIC_BUTTON,
};

function get(namespace, key) {
  const values = namespaces[namespace] || {};
  const src = Overrides.get('vectors', `${namespace}.${key}`, _get(values, key));
  if(src && src.default) {
    return src.default;
  }
  return src;
}

function getForHubType(hubType, namespace, key) {
  const imageForHubType = get(
    namespace,
    `${(hubType || '').toUpperCase()}.${key}`,
  );
  if (imageForHubType) {
    return imageForHubType;
  }
  return get(namespace, key);
}

function defer(namespace, key) {
  return () => get(namespace, key);
}

export default {defer, get, getForHubType};
