import * as React from 'react';
import MuiSlider from '@material-ui/core/Slider';

const fieldToSlider = ({
  field,
  form: { isSubmitting },
  disabled = false,
  ...props
}) => ({
  disabled: isSubmitting || disabled,
  ...props,
  ...field,
  name: field.name,
  value: field.value,
});

const Slider = (props) => (
  <MuiSlider
    {...fieldToSlider(props)}
    onChange={(e, value) =>
      props.field.onChange({
        ...e,
        target: {
          ...e.target,
          name: props.field.name,
          value
        },
      })
    } />
);

Slider.displayName = 'FormikMaterialUISlider';

export default Slider;
