import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Field, Form, Formik} from 'formik';
import {Container, FormControl} from '@material-ui/core';
import {ToggleButton} from '@material-ui/lab';
import {TextField} from 'formik-material-ui';
import {ToggleButtonGroup} from 'formik-material-ui-lab';

import {
  Button,
  HeadlineText,
  HelperText,
  ParagraphText,
} from '../elements';
import {EmailInput} from '../components/form';

import useConfig from '../hooks/useConfig';
import useStyleSheet from '../hooks/useStyleSheet';

const styleSheet = (theme) => ({
  container: {
    padding: 24,
  },
  toggle: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
  },
})

function AddMember({
  admin = false,
  email,
  member_id,
  name,
  searched,
  onSubmit,
}) {
  const {t} = useTranslation('AddMember');
  const classes = useStyleSheet(styleSheet);
  const {vendorName} = useConfig();
  const initialValues = useMemo(() => ({admin, email, name}), [
    admin,
    email,
    name,
  ]);
  return useMemo(() => (
    <Container maxWidth="md" className={classes.container}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}>
        {({ submitForm, isSubmitting }) => (
          <Form>
            <FormControl fullWidth>
              <Field
                component={EmailInput}
                disabled={searched}
                name="email"
                placeholder={t('email_placeholder')} />
              <br />
              {!searched && (
                <HelperText i18nKey="AddMember:member_invitation">
                  The new member will receive an invitation from {{vendorName}} with a link to activate their account.
                </HelperText>
              )}
              {searched && !member_id && (
                <>
                  <Field
                    component={TextField}
                    type="text"
                    autoComplete="name"
                    name="name"
                    placeholder={t('name_placeholder')} />
                  <br />
                </>
              )}
              {searched && (
                <>
                  <div className={classes.toggle}>
                    <Field
                      component={ToggleButtonGroup}
                      exclusive
                      name="admin"
                      type="checkbox">
                      <ToggleButton value={false}>
                        {t('Member')}
                      </ToggleButton>
                      <ToggleButton value={true}>
                        {t('Admin')}
                      </ToggleButton>
                    </Field>
                  </div>
                  <HeadlineText i18nKey="AddMember:admin_permissions">
                    Admin Permissions
                  </HeadlineText>
                  <ParagraphText i18nKey="AddMember:member_admin">
                    Admins on an account have unrestricted control over system
                    functionality.
                  </ParagraphText>
                  <HeadlineText i18nKey="AddMember:member_permissions">
                    Member Permissions
                  </HeadlineText>
                  <ParagraphText i18nKey="AddMember:member_explanation">
                    Members are able to arm, disarm, see the current active mode, view
                    the activity feed, view devices, device states, view other
                    members, status of key fobs and utilize the {{vendorName}} IFTTT
                    integration. Members are unable to add users, change permissions
                    of other users, edit modes, edit device settings, add new modes,
                    view or edit integration settings.
                  </ParagraphText>
                </>
              )}
              <br />
              <Button
                variant="contained"
                color="primary"
                i18nKey={isSubmitting ? 'Common:common.saving' : 'Common:common.save'}
                onClick={submitForm}
                disabled={isSubmitting}>
                {isSubmitting ? 'saving...' : 'save'}
              </Button>
            </FormControl>
          </Form>
        )}
      </Formik>
    </Container>
  ), [classes.container, classes.toggle, initialValues, member_id, onSubmit, searched, t, vendorName]);
}

export default AddMember;
