import Overrides from './Overrides';

const Tab = {r: 227, g: 227, b: 227};
const colorDifference = 19;
function tabColor(index) {
  const diff = colorDifference * index;
  return `rgb(${Tab.r - diff}, ${Tab.g - diff}, ${Tab.b - diff})`;
}

const GRAYS = {
  darker: '#323232',
  dark: '#a8a8a7',
  medium: '#bdbcbc',
  light: '#d0d0d0',
  lighter: '#ededed',
};

const GRAYS_WITH_OPACITY = {
  darker: 'rgba(20, 20, 20, 0.6)',
};

const primaryText = '#9d9c9c';
const secondaryText = GRAYS.dark;
const invert = '#fff';
const alarmed = '#ea504a';
const armed = '#89bad5';
const disarmed = '#f2f2f2';
const lightBg = GRAYS.lighter;
const accent = armed;
const defaultColors = {
  alarmed,
  armed,
  disarmed,
  primaryText,
  secondaryText,
  accent, // color used for accent icons, default activity feed borders, add buttons
  darkBgText: GRAYS.light,
  invertText: invert,
  primaryButtonBg: accent,
  secondaryButtonBg: secondaryText,
  helpButtonBg: alarmed,
  emergencyRedText: '#8e332d',
  warningYellow: '#fde014',
  warningYellowText: '#988912',
  warningText: '#97890f',
  ok: '#6cbf6c',
  okGreenText: '#386f1f',
  paired: '#437444',
  navigation: primaryText,
  background: invert,
  darkBackground: GRAYS.darker,
  inputBackground: lightBg,
  placeholderText: secondaryText,
  darkerOpacityText: GRAYS_WITH_OPACITY.darker,
  activeIndicator: accent, // used for slideshow and carousel indicators
  inactiveIndicator: lightBg,
  pairingIndicator: accent,
  borderColor: secondaryText,
  activeTabColor: accent,
  cameraFooter: 'rgba(0, 0, 0, 0.5)',
  firstTab: tabColor(0),
  secondTab: tabColor(1),
  thirdTab: tabColor(2),
  fourthTab: tabColor(3),
  loading: accent,
  titleBarButton: accent, // used for delete button
  highlightColor: accent, // used for recommended monitoring plan
  recommendedButton: '#53707f', // used for select button on recommended monitoring plan
  planButton: '#6b6b6b', // used for select button on monitoring plans
  addButton: accent,
  item: accent,
  lock: accent,
  h1: primaryText,
  splashBg: '#242424',
  splashFg: '#fff',

  // Login / signup screen
  loginBackButton: '#eee',
  loginText: '#fff',
  materialInputBottom: '#ccc',
  materialInputText: '#fff',
  materialInputPlaceholderText: '#eee',

  // Navigation Drawers
  drawerBg: GRAYS.darker,
  drawerHeader: disarmed,
  drawerActiveTint: disarmed,
  drawerInactiveTint: disarmed,
  drawerActiveBg: 'rgba(0, 0, 0, 0.4)',
  drawerInactiveBg: 'transparent',
};

function get(name) {
  const override = Overrides.get('colors', name);

  return override || defaultColors[name];
}

export default {get};
