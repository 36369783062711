import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import useModel from './useModel';

import UserCode from '../models/UserCode';

const getSelector = params => ({location, user_code}) => {
  const {user_code_id} = params || {};
  return { user_code: location.user_codes.find(({id}) => id === user_code_id) || {} };
};

function useUserCode() {
  const params = useParams();
  const selector = useMemo(() => getSelector(params), [params]);
  const {user_code} = useSelector(selector);
  return useModel(user_code, UserCode);
}

export default useUserCode;
