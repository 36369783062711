import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import md5 from 'md5';

import {Avatar, Container} from '@material-ui/core';
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab';

import {
  HeadlineText,
  ParagraphText,
} from '../elements';

import useConfig from '../hooks/useConfig';
import useStyleSheet from '../hooks/useStyleSheet';

const styleSheet = (theme) => ({
  container: {
    padding: 24,
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  avatar: {
    height: theme.spacing(10),
    width: theme.spacing(10),
  },
})

function Member({enabled, member, onToggle}) {
  const {t} = useTranslation('Member');
  const classes = useStyleSheet(styleSheet);
  const {vendorName} = useConfig();
  return useMemo(() => (
    <Container maxWidth="md" className={classes.container}>
      <div className={classes.centered}>
        <Avatar
          alt={member.name()}
          className={classes.avatar}
          src={`https://secure.gravatar.com/avatar/${md5(
            member.email,
          )}?s=120&d=mm`} />
      </div>
      <div className={classes.centered}>
        <ToggleButtonGroup
          value={member.state === 'admin'}
          exclusive
          onChange={onToggle}>
          <ToggleButton value={false} disabled={!enabled}>
            {t('Member')}
          </ToggleButton>
          <ToggleButton value={true} disabled={!enabled}>
            {t('Admin')}
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <HeadlineText i18nKey="Member:admin_permissions">
        Admin Permissions
      </HeadlineText>
      <ParagraphText i18nKey="Member:member_admin">
        Admins on an account have unrestricted control over system
        functionality.
      </ParagraphText>
      <HeadlineText i18nKey="Member:member_permissions">
        Member Permissions
      </HeadlineText>
      <ParagraphText i18nKey="Member:member_explanation">
        Members are able to arm, disarm, see the current active mode, view
        the activity feed, view devices, device states, view other
        members, status of key fobs and utilize the {{vendorName}} IFTTT
        integration. Members are unable to add users, change permissions
        of other users, edit modes, edit device settings, add new modes,
        view or edit integration settings.
      </ParagraphText>
    </Container>
  ), [classes.avatar, classes.centered, classes.container, enabled, member, onToggle, t, vendorName]);
}

export default Member;
