import React, {useCallback, useMemo} from 'react';

import {Button} from '../../elements';

function EnvironmentalSettings({onRoute}) {
  const onPress = useCallback(() => onRoute('AlarmContacts'), [onRoute]);

  return useMemo(
    () => (
      <Button
        i18nKey="Device:device.emergency_contacts"
        onPress={onPress}>
        manage emergency contacts
      </Button>
    ),
    [onPress],
  );
}

EnvironmentalSettings.interactive = false;

EnvironmentalSettings.fieldName = 'env_field';

EnvironmentalSettings.getFieldProps = ({onRoute}) => ({onRoute});

export default EnvironmentalSettings;
