import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Field, Form, Formik} from 'formik';
import {Container, FormControl, MenuItem} from '@material-ui/core';
import {Select, TextField} from 'formik-material-ui';

import {Rfid as RfidIcon} from '@scout-app/assets/icons';

import PairingIndicator from '../components/PairingIndicator';

import {Scheduler} from '../components/form';
import Vector from '../components/pairing/Vector';

import Colors from '../constants/Colors';
import Vectors from '../constants/Vectors';

import {
  Button,
  HeadlineText,
  ParagraphText,
  Text,
} from '../elements';

import useStyleSheet from '../hooks/useStyleSheet';

const styleSheet = (theme) => ({
  container: {
    padding: 24,
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  label: {
    flexGrow: 1,
  },
  icon: {
    fontSize: 48,
    color: Colors.get('item'),
  }
})

function AddRfid({
  found,
  initial_mode_id,
  is_setup,
  modes,
  onFinish,
  onSubmit,
  token,
}) {
  const {t} = useTranslation('AddRfid');
  const classes = useStyleSheet(styleSheet);
  const initialValues = useMemo(
    () => ({
      name: null,
      mode_id: initial_mode_id,
      scheduler: {
        active: true,
        schedule: undefined,
      },
    }),
    [initial_mode_id],
  );
  const _onSubmit = useCallback(
    (formData, formik) => {
      const {
        name,
        mode_id,
        scheduler: {active, schedule},
      } = formData;
      onSubmit({
        name,
        mode_id,
        active,
        schedule,
      }, formik);
    },
    [onSubmit],
  );

  return useMemo(() => (
    <Container maxWidth="md" className={classes.container}>
      <Formik
        initialValues={initialValues}
        onSubmit={_onSubmit}>
        {({ submitForm, isSubmitting, values }) => (
          <Form>
            <FormControl fullWidth>
              {token && (
                <>
                  <div className={classes.centered}>
                    <RfidIcon className={classes.icon} />
                  </div>
                  <HeadlineText i18nKey="AddRfid:add_rfid.new_fob_detected">
                    New key fob detected!
                  </HeadlineText>
                  <Field
                    component={TextField}
                    type="text"
                    name="name"
                    placeholder={t('add_rfid.fob_name')} />
                  <br />
                  {modes.length > 0 && (
                    <>
                      <HeadlineText i18nKey="AddRfid:add_rfid.mode_to_arm">
                        This fob will arm the following mode:
                      </HeadlineText>
                      <Field
                        component={Select}
                        name="mode_id">
                        {modes.map((mode, i) => (
                          <MenuItem key={i} value={mode.id}>{mode.name}</MenuItem>
                        ))}
                      </Field>
                      <br />
                      <ParagraphText i18nKey="AddRfid:add_rfid.mode_node">
                        Note: active fobs can disarm any mode, but will only arm the mode chosen above.
                      </ParagraphText>
                    </>
                  )}
                  <HeadlineText i18nKey="AddRfid:add_rfid.permissions">
                    Permissions of this fob:
                  </HeadlineText>
                  <ParagraphText i18nKey="AddRfid:add_rfid.permissions_description">
                    With the selector below, you can choose if and when this fob has permission to arm or disarm the system.
                  </ParagraphText>
                  <Field
                    component={Scheduler}
                    name="scheduler" />
                </>
              )}
              {!token && (
                <>
                  <Vector source={Vectors.get('DOOR_PANEL', 'FOB_REGISTRATION')} />
                  <HeadlineText i18nKey="AddRfid:add_rfid.swipe_a_fob">
                    Swipe a Key Fob or Sticker
                    {'\n'}
                    across a door panel.
                  </HeadlineText>
                  <Text i18nKey="AddRfid:add_rfid.sticker_placement">
                    If you are pairing an RFID sticker, place it on a secret item in
                    your home, in the event you lose your keys.
                  </Text>
                  {!found && (
                    <PairingIndicator i18nKey="AddRfid:add_rfid.listening">
                      listening...
                    </PairingIndicator>
                  )}
                </>
              )}
              {found && (
                <div className={classes.centered}>
                  <Text i18nKey="AddRfid:add_rfid.fob_detected">
                    Key fob detected: {{found}}
                  </Text>
                </div>
              )}
              {!token && is_setup && (
                <Button
                  variant="contained"
                  color="primary"
                  i18nKey="AddRfid:add_rfid.done"
                  onClick={onFinish}>
                  I am done adding key fobs
                </Button>
              )}
              {token && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  i18nKey={isSubmitting ? 'Common:common.saving' : 'Common:common.save'}
                  onClick={submitForm}>
                  {isSubmitting ? 'saving...' : 'save'}
                </Button>
              )}
            </FormControl>
          </Form>
        )}
      </Formik>
    </Container>
  ), [_onSubmit, classes.centered, classes.container, classes.icon, found, initialValues, is_setup, modes, onFinish, t, token]);
}

export default AddRfid;
