import React, {useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useConfirm} from 'material-ui-confirm';

import BackButton from '@material-ui/icons/ArrowBack';
import {Delete as DeleteButton} from '@scout-app/assets/icons';

import * as actions from '../actions/location';

import useActions from '../hooks/useActions';
import useLeftButton from '../hooks/useLeftButton';
import useLocation from '../hooks/useLocation';
import useRequireAdmin from '../hooks/useRequireAdmin';
import useRfid from '../hooks/useRfid';
import useRightButton from '../hooks/useRightButton';
import useSetState from '../hooks/useSetState';
import useTitle from '../hooks/useTitle';

import Component from '../routes/Rfid';

const onError = err => alert(err.error || err.message || err);

function Rfid() {
  const history = useHistory();
  const {t} = useTranslation('Rfids');
  const {removeRfid, updateRfid} = useActions(actions);
  const location = useLocation();
  const rfid = useRfid();
  const confirm = useConfirm();
  const [state, setState] = useSetState();
  const {modes} = location;
  const onDelete = useCallback(() => {
    confirm({
      title: t('RemoveKeyFob'),
      description: t('ConfirmRemoveKeyFob', {
        rfid_name: rfid.name,
        location_name: location.name,
      }),
    }).then(confirmed => {
      history.goBack();
      removeRfid(rfid.id).catch(onError);
    })
    .catch(() => {});
  }, [confirm, history, location.name, removeRfid, rfid.id, rfid.name, t]);


  const onBack = useCallback(() => {
    const {name, mode_id, active, schedule} = state;
    const data = {};
    if (name && name !== rfid.name) {
      data.name = name;
    }
    if ('active' in state && active !== rfid.active) {
      data.active = active;
    }
    if ('schedule' in state && schedule !== rfid.schedule) {
      data.schedule = schedule;
    }
    if (mode_id && mode_id !== rfid.mode_id) {
      data.mode_id = mode_id;
    }

    history.goBack();
    if (Object.keys(data).length) {
      return updateRfid(rfid.id, data)
        .catch(onError);
    }
  }, [history, rfid.active, rfid.id, rfid.mode_id, rfid.name, rfid.schedule, state, updateRfid]);

  useTitle(rfid?.name);
  useLeftButton(BackButton, onBack);
  useRightButton(DeleteButton, location.member_state === 'admin' ? onDelete : null);
  useRequireAdmin();

  return useMemo(
    () => (
      <Component
        rfid={rfid}
        modes={modes}
        onSubmit={setState}
      />
    ),
    [modes, rfid, setState],
  );
}

export default Rfid;
