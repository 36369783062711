import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import useModel from './useModel';

import Location from '../models/Location';

const getSelector = params => ({location, member}) => {
  const {location_id} = params || {};
  if(location_id) {
    if(location.id === location_id) {
      return { location };
    }
    return { location: member.locations.find(({id}) => id === location_id) || {} };
  };
  return { location };
};

function useLocation() {
  const params = useParams();
  const selector = useMemo(() => getSelector(params), [params]);
  const {location} = useSelector(selector);
  return useModel(location, Location);
}

export default useLocation;
