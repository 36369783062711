import React, {useCallback, useMemo} from 'react';
import {Slider} from '@material-ui/core';

import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab';
import {KeyboardTimePicker} from '@material-ui/pickers';

import {useTranslation} from 'react-i18next';
import {format, parse, setDay} from 'date-fns'; // TODO - change to moment and deprecate date-fns

import useStyleSheet from '../../hooks/useStyleSheet';

const fieldToScheduler = ({
  field,
  form: { isSubmitting },
  disabled = false,
  ...props
}) => ({
  disabled: isSubmitting || disabled,
  ...props,
  ...field,
  name: field.name,
  value: field.value,
});

const date = new Date();

const weekdays = [
  format(setDay(date, 0), 'EEEEE'),
  format(setDay(date, 1), 'EEEEE'),
  format(setDay(date, 2), 'EEEEE'),
  format(setDay(date, 3), 'EEEEE'),
  format(setDay(date, 4), 'EEEEE'),
  format(setDay(date, 5), 'EEEEE'),
  format(setDay(date, 6), 'EEEEE'),
];

const styleSheet = (theme) => ({
  slider: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  days: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  day: {
    flex: 1,
  },
  timePicker: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  }
});

const inputProps = {
  step: 900,
};

const Scheduler = (props) => {
  const {t} = useTranslation('Scheduler');
  const classes = useStyleSheet(styleSheet);
  const marks = useMemo(() => [
    {
      value: 0,
      label: t('scheduler.disabled')
    },
    {
      value: 1,
      label: t('scheduler.scheduled')
    },
    {
      value: 2,
      label: t('scheduler.twentyfourseven')
    },
  ], [t]);
  const fieldProps = useMemo(() => fieldToScheduler(props), [props]);
  const {disabled, value} = fieldProps;
  const {
    days = [],
    start_time = '9:00',
    end_time = '17:00',
  } = value.schedule || {};
  // const initialState = useMemo(() => ({
  //   active: value.active || false,
  //   schedule: value.schedule || null,
  // }), []);
  // const [state, setState] = useSetState(initialState);
  const sliderValue = value.active ? (value.schedule ? 1 : 2) : 0;
  const onSliderChange = useCallback((e, nextValue) => {
    props.field.onChange({
      ...e,
      target: {
        ...e.target,
        name: props.field.name,
        value: {
          active: nextValue !== 0,
          schedule: nextValue === 1 ? {
            days: [1, 2, 3, 4, 5],
            start_time: '9:00',
            end_time: '17:00',
          } : null,
        },
      },
    });
  }, [props.field]);
  const onDaysChange = useCallback((e, nextValue) => {
    props.field.onChange({
      ...e,
      target: {
        ...e.target,
        name: props.field.name,
        value: {
          active: true,
          schedule: {
            days: nextValue,
            start_time,
            end_time,
          },
        },
      },
    });
  }, [end_time, props.field, start_time]);
  const startTime = useMemo(() => parse(start_time, 'H:mm', new Date()), [start_time]);
  const onStartTimeChange = useCallback((e, nextValue) => {
    props.field.onChange({
      ...e,
      target: {
        ...e.target,
        name: props.field.name,
        value: {
          active: true,
          schedule: {
            days,
            start_time: format(parse(nextValue, 'hh:mm a', new Date()), 'H:mm'),
            end_time,
          },
        },
      },
    });
  }, [days, end_time, props.field]);
  const endTime = useMemo(() => parse(end_time, 'H:mm', new Date()), [end_time]);
  const onEndTimeChange = useCallback((e, nextValue) => {
    props.field.onChange({
      ...e,
      target: {
        ...e.target,
        name: props.field.name,
        value: {
          active: true,
          schedule: {
            days,
            start_time,
            end_time: format(parse(nextValue, 'hh:mm a', new Date()), 'H:mm'),
          },
        },
      },
    });
  }, [days, props.field, start_time]);
  return (
    <>
      <div className={classes.slider}>
        <Slider
          {...fieldProps}
          value={sliderValue}
          disabled={disabled}
          marks={marks}
          max={2}
          step={null}
          onChange={onSliderChange} />
      </div>
      {sliderValue === 1 && (
        <>
          <div className={classes.centered}>
            <ToggleButtonGroup
              className={classes.days}
              size="medium"
              value={value.schedule.days}
              onChange={onDaysChange}>
              {weekdays.map((day, value) =>
                <ToggleButton
                  className={classes.day}
                  key={value}
                  value={value}>
                  {day}
                </ToggleButton>
              )}
            </ToggleButtonGroup>
          </div>
          <div className={classes.centered}>
            <KeyboardTimePicker
              className={classes.timePicker}
              margin="normal"
              label={t('scheduler.from')}
              value={startTime}
              onChange={onStartTimeChange}
              inputProps={inputProps} />
            <KeyboardTimePicker
              className={classes.timePicker}
              margin="normal"
              label={t('scheduler.to')}
              value={endTime}
              onChange={onEndTimeChange}
              inputProps={inputProps} />
          </div>
        </>
      )}
    </>
  );
};

Scheduler.displayName = 'FormikMaterialUIScheduler';

export default Scheduler;
