import React, {useMemo} from 'react';
import moment from 'moment';
import get from 'lodash.get';

import clsx from 'clsx';

import Colors from '../constants/Colors';
import Text from '../elements/Text';

import useConfig from '../hooks/useConfig';
import useStyleSheet from '../hooks/useStyleSheet';

const TRIAL_PERIOD = 3 * 24 * 60 * 60 * 1000;

// TODO - read colors from config
const styleSheet = (theme) => ({
  text: {
    textAlign: 'center',
    fontSize: 14,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  emergency: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    backgroundColor: Colors.get('alarmed'),
    zIndex: 1,
  },
  emergencyText: {
    color: '#fff',
  },
  button: {
    backgroundColor: '#fff',
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    borderRadius: 2,
  },
  buttonText: {
    color: Colors.get('secondaryText'),
    fontSize: 12,
    fontWeight: '400',
  },
  warning: {
    backgroundColor: Colors.get('warningYellow'),
    zIndex: 1,
  },
  warningText: {
    color: Colors.get('warningText'),
  },
});

const isDeviceOffline = device => {
  if (!device) {
    return false;
  }
  return get(device, 'reported.timedout', false);
};

const isMonitoringContactInfoRequired = location => {
  if (!location || location.plan !== 'pro') {
    return false;
  }
  if (!get(location, 'pro_monitoring.account_number', false)) {
    return true;
  }
  return false;
};

const isAgreementRequired = location => {
  return false;
  if (!location || location.plan !== 'pro') {
    return false;
  }

  const hasAgreementRequest = get(
    location,
    'pro_monitoring.agreement_request',
    false,
  );
  const signedAgreement = get(
    location,
    'pro_monitoring.agreement_signed',
    false,
  );

  if (hasAgreementRequest && !signedAgreement) {
    return true;
  }
  return false;
};

const isInTestMode = location => {
  return false;
  if (!location || location.plan !== 'pro') {
    return false;
  }
  const agreementRequired = isAgreementRequired(location);
  const trailPeriod = moment(location.created)
    .isAfter(new Date(Date.now() - TRIAL_PERIOD));
  if (agreementRequired || trailPeriod) {
    return true;
  }
  return false;
};

const isHubOffline = location => {
  if (!location) {
    return false;
  }
  if (get(location, 'hub.reported.status') === 'offline') {
    return true;
  }
  return false;
};

const isHubOnCellular = location => {
  if (!location || isHubOffline(location)) {
    return false;
  }
  return get(location, 'hub.reported.cellular.active', false);
};

const isHubOnBackupBattery = location => {
  if (!location || isHubOffline(location)) {
    return false;
  }
  return get(location, 'hub.reported.battery.active', false);
};

const isHubSirenOn = location => {
  if (!location) {
    return false;
  }
  const isLocationAlarmed = get(location, 'state.alarmed', false);
  const isEnvironmentalAlarmed = get(
    location,
    'environmental.alarmed.length',
    false,
  );
  const siren = get(location, 'hub.reported.siren', false);
  if (siren && !isEnvironmentalAlarmed && !isLocationAlarmed) {
    return true;
  }
  return false;
};

function Warning({device, location, onMonitoring, onSiren}) {
  const {vendorName} = useConfig();
  const classes = useStyleSheet(styleSheet);
  const locationName = location?.name;
  return useMemo(() => {
    // TODO - move calculations outside of render memo
    if (isHubSirenOn(location)) {
      return (
        <div className={classes.emergency}>
          <Text i18nKey="Warning:warning.siren_on" className={classes.emergencyText}>
            Siren is on
          </Text>
          <div onClick={onSiren} className={classes.button}>
            <Text
              i18nKey="Warning:warning.turn_off"
              className={classes.buttonText}>
              Turn off
            </Text>
          </div>
        </div>
      );
    }
    return (
      <div className={classes.warning}>
        {isInTestMode(location) && (
          <Text
            i18nKey="Warning:warning.test_mode"
            className={clsx(classes.text, classes.warningText)}>
            {{locationName}} is currently in test mode
            {'\n'}
            Police will not be dispatched
          </Text>
        )}
        {isMonitoringContactInfoRequired(location) && (
          <Text
            i18nKey="Warning:warning.contact_info"
            className={clsx(classes.text, classes.warningText)}
            onClick={onMonitoring}>
            Enter your monitoring contact information &raquo;
          </Text>
        )}
        {isAgreementRequired(location) && (
          <Text
            i18nKey="Warning:warning.sign_contact_agreement"
            className={clsx(classes.text, classes.warningText)}>
            Remember to sign the {{vendorName}} Monitoring Agreement
          </Text>
        )}
        {isHubOffline(location) && (
          <Text
            i18nKey="Warning:warning.offline"
            className={clsx(classes.text, classes.warningText)}>
            Your hub at {{locationName}} is offline
          </Text>
        )}
        {isHubOnCellular(location) && (
          <Text
            i18nKey="Warning:warning.cellular"
            className={clsx(classes.text, classes.warningText)}>
            Your hub at {{locationName}} is on cellular
          </Text>
        )}
        {isHubOnBackupBattery(location) && (
          <Text
            i18nKey="Warning:warning.backup"
            className={clsx(classes.text, classes.warningText)}>
            Your hub at {{locationName}} is on backup battery
          </Text>
        )}
        {isDeviceOffline(device) && (
          <Text
            i18nKey="Warning:warning.device_offline"
            className={clsx(classes.text, classes.warningText)}>
            This device is offline
          </Text>
        )}
      </div>
    );
  }, [
    device,
    location,
    locationName,
    onMonitoring,
    onSiren,
    classes.button,
    classes.buttonText,
    classes.emergency,
    classes.emergencyText,
    classes.text,
    classes.warning,
    classes.warningText,
    vendorName,
  ]);
}

export default Warning;
