import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import {Locked as UserCodeIcon} from '@scout-app/assets/icons';

import Colors from '../constants/Colors';
import useModel from '../hooks/useModel';
import useStyleSheet from '../hooks/useStyleSheet';

import Model from '../models/UserCode';

const styleSheet = () => ({
  icon: {
    fontSize: 32,
    color: Colors.get('item'),
  }
});

function UserCode(props) {
  const {admin = true, onPress} = props;
  const {t} = useTranslation('UserCodes');
  const user_code = useModel(props.user_code, Model);
  const classes = useStyleSheet(styleSheet);
  return useMemo(
    () => (
      <ListItem onClick={() => onPress(user_code)}>
        <ListItemIcon>
          <UserCodeIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary={user_code.name} secondary={t(user_code.state)} />
        {admin && (
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
        )}
      </ListItem>
    ),
    [admin, classes.icon, onPress, user_code, t],
  );
}

export default UserCode;
