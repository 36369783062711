import qs from 'qs';
import retryableFetch from './retryable-fetch';

import {HOST_NAME} from '../app.json';

const DEBUG = true;

export const request = (
  method,
  auth = true,
  getState,
  path,
  data,
  retryOptions = {},
) => {
  var host;
  var Authorization;
  if (typeof getState === 'function') {
    const {member} = getState();
    host = HOST_NAME;
    Authorization = member.token;
  }
  if (!host) {
    if (DEBUG) {
      console.log('[api]', method, path, data || '');
    }
    return Promise.reject('invalid host');
  }
  if (auth && !Authorization) {
    if (DEBUG) {
      console.log('[api]', method, `${host}${path}`, data || '');
    }
    return Promise.reject('invalid or expired session');
  }
  const options = {
    ...retryOptions,
    headers: {
      Authorization,
      'Content-Type': 'application/json',
    },
    method,
  };
  let uri = `${host}${path}`;
  if (data) {
    if (['get', 'delete'].indexOf(method) > -1) {
      Object.keys(data).forEach(key => {
        if (Array.isArray(data[key])) {
          data[key] = data[key].join();
        }
      });
      uri += `?${qs.stringify(data)}`;
    } else {
      options.body = JSON.stringify(data);
    }
  }
  return retryableFetch(uri, options)
    .then(res => res.text())
    .then(text => {
      try {
        return JSON.parse(text);
      } catch (e) {
        // ignored
      }
      throw text;
    })
    .then(json => {
      if (json && json.error) {
        throw json;
      }
      if (DEBUG) {
        console.log('[api]', method, uri, data || '', json);
      }
      return json;
    })
    .catch(err => {
      if (DEBUG) {
        console.log('[api]', method, uri, data || '');
      }
      throw err.error || err.message || err;
    });
};

export const get = (...args) => request('get', true, ...args);
export const post = (...args) => request('post', true, ...args);
export const put = (...args) => request('put', true, ...args);
export const del = (...args) => request('delete', true, ...args);

export default {
  request,
  get,
  post,
  put,
  del,
};
