import React from 'react';

import clsx from 'clsx';

import moment from '../lib/moment';

import {
  AccessSensor,
  Alarmed,
  AmazonEcho,
  Armed,
  Battery,
  Camera,
  Cellular,
  CorrectPasscode,
  Disarmed,
  Dismissed,
  DispatchedPolice,
  DoorLock,
  DoorPanel,
  Emergency,
  GlassBreak1 as GlassBreak,
  Hub,
  IncorrectPasscode,
  IndoorCamera,
  CategoryKeyPad as Keypad,
  Light,
  LocationCreate,
  MotionSensor,
  NoResponse,
  NoSignal,
  Ok,
  Paired,
  PanicButton,
  Power,
  RemoteControl,
  RequestedAssistance,
  Signal,
  Siren,
  SmokeCo,
  Switch,
  Warning,
  WaterSensor,
} from '@scout-app/assets/icons';

import {
  Error as ErrorIcon,
  ErrorOutline,
} from '@material-ui/icons';

import Colors from '../constants/Colors';
import useStyleSheet from '../hooks/useStyleSheet';

const DATE_FORMAT = 'LL';
const ISO_FORMAT = "YYYY-MM-DD'T'HH:mm:ssZ";
const TIME_FORMAT = 'LTS';

export const DEFAULT_SIZE = 32;

const ENVIRONMENTAL_EVENTS = [
  'dismissed',
  'paired',
  'unpaired',
  'smoke_emergency',
  'smoke_warning',
  'smoke_ok',
  'co_emergency',
  'co_warning',
  'co_ok',
  'malfunction_emergency',
  'malfunction_ok',
  'jammed',
  'wet',
  'dry',
];

const connectedOrDisconnected = ({event}) =>
  ['connected', 'disconnected'].includes(event);

const isEnvironmental = ({event}) => ENVIRONMENTAL_EVENTS.includes(event);

const isIntegration = ({effected, event}) =>
  effected?.type === 'integration' && event !== 'dismissed';

const notExpired = ({url_expiration}) =>
  !url_expiration || moment(url_expiration, ISO_FORMAT).isAfter(moment());

const isDismissed = ({event}) => event === 'dismissed';

const pairedOrUnpaired = ({event}) => ['paired', 'unpaired'].includes(event);

const sectionTitle = ({timestamp}) =>
  moment(timestamp, ISO_FORMAT).format(DATE_FORMAT);

const buildSection = (title, {length: key}) => ({data: [], key, title});

const sectionData = (item, {length: section}) => ({
  section,
  ...item,
});

function connectsTo(i1, i2) {
  return canConnect(i1) && canConnect(i2);
}

function canConnect(item = {}) {
  if (!item.timestamp) {
    return false;
  }

  const deviceCanConnect =
    isDevice(item) &&
    !(isEnvironmental(item) || isDismissed(item) || isIntegration(item));

  const cameraCanConnect =
    isCamera(item) && !pairedOrUnpaired(item);

  return (
    deviceCanConnect ||
    cameraCanConnect ||
    item.type === 'slideshow' ||
    isArm(item) ||
    isDisarm(item)
  );
}

function isCamera(item = {}) {
  return item.type === 'camera';
}

function isDevice(item = {}) {
  return item.type === 'device';
}

function isLocation(item = {}) {
  return item.type === 'location';
}

function isMode(item = {}) {
  return item.type === 'mode';
}

function isAlarm(item = {}) {
  return item.event === 'alarmed';
}

function isDismiss(item = {}) {
  return item.event === 'dismissed';
}

function isArm(item = {}) {
  return isMode(item) && item.event === 'armed';
}

function isDisarm(item = {}) {
  return isMode(item) && item.event === 'disarmed';
}

function hasPreview({meta = {}} = {}) {
  return notExpired(meta) && !!meta.image_url;
}

function isEvent(item, ...events) {
  const {event} = item || {};
  return events.indexOf(event) > -1;
}

function hasPaywall(item) {
  const {meta} = item || {};
  const {restricted} = meta || {};
  return !!restricted;
}

export function hasMedia(item) {
  return (
    item && (hasPreview(item) || hasPaywall(item))
  );
}

function getPreview({meta = {}} = {}) {
  return meta.image_url;
}

function noEvent(item) {
  return !item.event;
}

function notSlideshow(item) {
  return item.type && item.type !== 'slideshow';
}

function isTopConnected(item, siblings, index) {
  const prev = siblings[index - 1];
  let previous = prev;
  if (
    prev &&
    noEvent(prev) &&
    notSlideshow(prev) &&
    index > 2
  ) {
    previous = siblings[index - 2];
  }
  return (
    connectsTo(item, previous) &&
    !isDisarm(item) &&
    !isArm(previous)
  );
}

function isBottomConnected(item, siblings, index) {
  const {length} = siblings;
  const nextItem = siblings[index + 1];
  let next = nextItem;
  if (
    nextItem &&
    noEvent(next) &&
    notSlideshow(nextItem) &&
    index < length - 2
  ) {
    next = siblings[index + 2];
  }
  return (
    connectsTo(item, next) &&
    !isArm(item) &&
    !isDisarm(next)
  );
}

/*
 * Activity is grouped into sections by date
 * and sorted in reverse chronological order (newest to oldest).
 * Within a day activity is grouped according to the following rules:
 * 1. connect armed -> disarmed
 * 2. connect active alarm -> dismissed
 * 3. Within a 10-minute window, group matching event pairs for a device,
 *    e.g., open/close on a door sensor
 * 4. Group adjacent camera motion events into a carousel
 *    (reuse home screen video slider)
 */
export function group(unsorted = [], sections = [], options = {}) {
  if (!unsorted.length) {
    return sections;
  }

  const activities = unsorted.sort((a, b) =>
    b.timestamp.localeCompare(a.timestamp),
  );

  const {groupSlides} = options;

  let slides = [];
  let i = 0;
  let activity;
  let section = sections.pop();
  let title;

  if (section) {
    const {data = []} = section;
    const lastItem = data.pop();
    if (lastItem) {
      if (lastItem.type === 'slideshow') {
        slides = lastItem.items;
      } else {
        section.data.push(lastItem);
      }
    }
  }

  const pushActivity = item => {
    section.data.push(sectionData(item, sections));
  };

  const pushSlides = () => {
    if (!slides.length) {
      return;
    }

    section.data.push(
      sectionData(
        {
          id: slides[0].id,
          timestamp: slides[0].timestamp,
          type: 'slideshow',
          items: slides,
        },
        sections,
      ),
    );

    slides = [];
  };

  const collectSlides = (item, index) => {
    const nextIndex = index + 1;
    const next = activities[nextIndex];

    item.paywall = hasPaywall(item);
    slides.push(item);

    if (
      groupSlides &&
      hasMedia(next) &&
      item.item_id === next.item_id &&
      sectionTitle(item) === sectionTitle(next)
    ) {
      return collectSlides(next, nextIndex);
    }

    pushSlides();
    return nextIndex;
  };

  while (i < activities.length) {
    activity = activities[i];
    title = sectionTitle(activity);
    section = section || buildSection(title, sections);

    if (title !== section.title) {
      /*
       * it's a new day
       * add current section to sections list
       * start a new section
       */
      pushSlides();
      sections.push(section);
      section = buildSection(title, sections);
    }

    if (hasMedia(activity)) {
      i = collectSlides(activity, i);
    } else {
      pushSlides();
      activity.paywall = hasPaywall(activity);
      pushActivity(activity);
      i += 1;
    }
  }

  if (section) {
    // there is only one section
    pushSlides();
    sections.push(section);
  }

  return sections;
}


const emergency = {
  row: 'alarmed',
  text: 'emergencyRedText',
};

const warning = {
  row: 'warningYellow',
  text: 'warningText',
};

const ok = {
  row: 'ok',
  text: 'okGreenText',
};

const STYLES = {
  event: {
    smoke_emergency: emergency,
    smoke_warning: warning,
    smoke_ok: ok,
    co_emergency: emergency,
    co_warning: warning,
    co_ok: ok,
    malfunction_emergency: warning,
    malfunction_ok: ok,
    jammed: warning,
    wet: emergency,
    dry: ok,
  },
  device: {},
  mode: {
    alarmed: emergency,
  },
  location: {
    alarmed: emergency,
  },
};

const styleSheet = (theme, size) => ({
  icon: {
    fontSize: 26 * (size / DEFAULT_SIZE),
  },
  circle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: size,
    height: size,
    overflow: 'hidden',
    borderRadius: size / 2,
    borderWidth: 2,
    borderStyle: 'solid',
  },
  defaultIcon: {
    color: Colors.get('item'),
  },
  defaultCircle: {
    borderColor: Colors.get('item'),
  },
  emergencyIcon: {
    color: Colors.get('emergencyRedText'),
  },
  emergencyCircle: {
    borderColor: Colors.get('emergencyRedText'),
  },
  warningIcon: {
    color: Colors.get('warningYellowText'),
  },
  warningCircle: {
    borderColor: Colors.get('warningYellowText'),
  },
  okIcon: {
    color: Colors.get('okGreenText'),
  },
  okCircle: {
    borderColor: Colors.get('okGreenText'),
  },
  alarmedIcon: {
    color: Colors.get('alarmed'),
  },
  alarmedCircle: {
    borderColor: Colors.get('alarmed'),
  },
  pairedIcon: {
    color: Colors.get('paired'),
  },
  pairedCircle: {
    borderColor: Colors.get('paired'),
  },
  panicIcon: {
    color: Colors.get('alarmed'),
  },
  panicCircle: {
    borderColor: Colors.get('alarmed'),
  },
  xsmallIcon: {
    fontSize: 14 * (size / DEFAULT_SIZE),
  },
  smallIcon: {
    fontSize: 18 * (size / DEFAULT_SIZE),
  },
  largeIcon: {
    fontSize: size,
  },
  customIcon: {
    fontSize: 30 * (size / DEFAULT_SIZE),
  }
});

function EmptyIcon({size = DEFAULT_SIZE}) {
  const classes = useStyleSheet(styleSheet, size);
  return (<div className={clsx(classes.circle, classes.defaultCircle)} />);
}

const Icons = {
  // mode icons
  alarmed: {
    circle: true,
    className: 'emergency',
    icon: Alarmed,
  },
  armed: {
    circle: true,
    className: 'default',
    icon: Armed,
  },
  disarmed: {
    circle: true,
    className: 'default',
    icon: Disarmed,
  },
  dismissed: {
    circle: true,
    className: 'alarmed',
    icon: Dismissed,
  },
  panic: {
    className: 'panic',
    icon: ErrorIcon,
    iconSize: 'large',
  },
  panic_dismiss: {
    className: 'panic',
    icon: ErrorOutline,
    iconSize: 'large',
  },
  // camera icons
  camera: {
    circle: true,
    className: 'default',
    icon: IndoorCamera,
    iconSize: 'small',
  },
  dbc831: {
    circle: true,
    className: 'default',
    icon: Camera,
    iconSize: 'small',
  },
  dbc831v2: {
    circle: true,
    className: 'default',
    icon: Camera,
    iconSize: 'small',
  },
  // device icons
  access_sensor: {
    circle: true,
    className: 'default',
    icon: AccessSensor,
    iconSize: 'small',
  },
  motion_sensor: {
    circle: true,
    className: 'default',
    icon: MotionSensor,
    iconSize: 'small',
  },
  door_panel: {
    circle: true,
    className: 'default',
    icon: DoorPanel,
    iconSize: 'small',
  },
  hub: {
    circle: true,
    className: 'default',
    icon: Hub,
    iconSize: 'small',
  },
  light_bulb: {
    circle: true,
    className: 'default',
    icon: Light,
    iconSize: 'small',
  },
  switch: {
    circle: true,
    className: 'default',
    icon: Switch,
    iconSize: 'small',
  },
  dimmer_switch: {
    circle: true,
    className: 'default',
    icon: Switch,
    iconSize: 'small',
  },
  door_lock: {
    circle: true,
    className: 'default',
    icon: DoorLock,
    iconSize: 'small',
  },
  glass_break: {
    circle: true,
    className: 'default',
    icon: GlassBreak,
    iconSize: 'small',
  },
  key_pad: {
    circle: true,
    className: 'default',
    icon: Keypad,
    iconSize: 'small',
  },
  panic_button: {
    circle: true,
    className: 'default',
    icon: PanicButton,
    iconSize: 'small',
  },
  remote_control: {
    circle: true,
    className: 'default',
    icon: RemoteControl,
    iconSize: 'small',
  },
  siren: {
    circle: true,
    className: 'default',
    icon: Siren,
    iconSize: 'xsmall',
  },
  smoke_alarm: {
    circle: true,
    className: 'default',
    icon: SmokeCo,
    iconSize: 'small',
  },
  water_sensor: {
    circle: true,
    className: 'default',
    icon: WaterSensor,
    iconSize: 'small',
  },
  // location & environmental events
  cellular: {
    circle: true,
    className: 'default',
    icon: Cellular,
  },
  connected:{
    circle: true,
    className: 'default',
    icon: Signal,
  },
  disconnected: {
    circle: true,
    className: 'default',
    icon: NoSignal,
  },
  dry: {
    className: 'ok',
    icon: WaterSensor,
    iconSize: 'small',
  },
  wet: {
    className: 'emergency',
    icon: WaterSensor,
    iconSize: 'small',
  },
  malfunction_ok: {
    className: 'ok',
    icon: SmokeCo,
    iconSize: 'large',
  },
  malfunction_emergency: {
    className: 'warning',
    icon: SmokeCo,
    iconSize: 'large',
  },
  jammed: {
    className: 'warning',
    icon: DoorLock,
    iconSize: 'small',
  },
  co_ok: {
    className: 'ok',
    icon: SmokeCo,
    iconSize: 'large',
  },
  co_warning: {
    className: 'warning',
    icon: SmokeCo,
    iconSize: 'large',
  },
  co_emergency: {
    className: 'emergency',
    icon: SmokeCo,
    iconSize: 'large',
  },
  smoke_ok: {
    className: 'ok',
    icon: SmokeCo,
    iconSize: 'large',
  },
  smoke_warning: {
    className: 'warning',
    icon: SmokeCo,
    iconSize: 'large',
  },
  smoke_emergency: {
    className: 'emergency',
    icon: SmokeCo,
    iconSize: 'large',
  },
  nest_co_ok: {
    className: 'ok',
    icon: Ok,
    iconSize: 'large',
  },
  nest_co_warning: {
    className: 'warning',
    icon: Warning,
    iconSize: 'large',
  },
  nest_co_emergency: {
    className: 'emergency',
    icon: Emergency,
    iconSize: 'large',
  },
  nest_smoke_ok: {
    className: 'ok',
    icon: Ok,
    iconSize: 'large',
  },
  nest_smoke_warning: {
    className: 'warning',
    icon: Warning,
    iconSize: 'large',
  },
  nest_smoke_emergency: {
    className: 'emergency',
    icon: Emergency,
    iconSize: 'large',
  },
  paired: {
    className: 'paired',
    icon: Paired,
    iconSize: 'custom',
  },
  unpaired: {
    className: 'alarmed',
    icon: Paired,
    iconSize: 'custom',
  },
  battery_backup_on: {
    className: 'armeed',
    icon: Battery,
    iconSize: 'custom',
  },
  battery_backup_off: {
    className: 'paired',
    icon: Power,
    iconSize: 'custom',
  },
  location_create: {
    className: 'paired',
    icon: LocationCreate,
    iconSize: 'custom',
  },
  correct_passcode: {
    className: 'paired',
    icon: CorrectPasscode,
    iconSize: 'custom',
  },
  incorrect_passcode: {
    className: 'alarmed',
    icon: IncorrectPasscode,
    iconSize: 'custom',
  },
  no_response: {
    className: 'alarmed',
    icon: NoResponse,
    iconSize: 'custom',
  },
  requested_assistance: {
    className: 'alarmed',
    icon: RequestedAssistance,
    iconSize: 'custom',
  },
  dispatched_police: {
    className: 'armed',
    icon: DispatchedPolice,
    iconSize: 'custom',
  },
  amazon_co_ok: {
    className: 'ok',
    icon: AmazonEcho,
    iconSize: 'large',
  },
  amazon_co_warning: {
    className: 'warning',
    icon: AmazonEcho,
    iconSize: 'large',
  },
  amazon_co_emergency: {
    className: 'emergency',
    icon: AmazonEcho,
    iconSize: 'large',
  },
  amazon_smoke_ok: {
    className: 'ok',
    icon: AmazonEcho,
    iconSize: 'large',
  },
  amazon_smoke_warning: {
    className: 'warning',
    icon: AmazonEcho,
    iconSize: 'large',
  },
  amazon_smoke_emergency: {
    className: 'emergency',
    icon: AmazonEcho,
    iconSize: 'large',
  },
  amazon_shatter: {
    className: 'default',
    icon: AmazonEcho,
    iconSize: 'large',
  },
  amazon_motion: {
    className: 'default',
    icon: AmazonEcho,
    iconSize: 'large',
  },
};

function ActivityIcon({icon: Icon, size}) {
  const classes = useStyleSheet(styleSheet, size);
  if(!Icon)
    return <EmptyIcon size={size} />
  const iconClasses = clsx(classes.icon, classes[Icon.className + 'Icon'], Icon.iconSize && classes[`${Icon.iconSize}Icon`]);
  if(Icon.circle)
    return (
      <div className={clsx(classes.circle, classes[Icon.className + 'Circle'])}>
        <Icon.icon className={iconClasses} />
      </div>
    );
  return <Icon.icon className={iconClasses} />;
}

function CameraIcon({item, size = DEFAULT_SIZE}) {
  let icon = null;
  if (pairedOrUnpaired(item) || connectedOrDisconnected(item)) {
    icon = Icons[item?.event];
  }
  else {
    icon = Icons[item?.effected?.model] || Icons[item?.effected?.type] || Icons[item?.type];
  }
  return <ActivityIcon icon={icon} size={size} />
}

function DeviceIcon({item, size = DEFAULT_SIZE}) {
  let icon = null;
  if (isIntegration(item)) {
    icon = Icons[`${item?.affector?.id}_${item?.event}`];
  }
  else if (isEnvironmental(item)) {
    if (pairedOrUnpaired(item) || isDismissed(item)) {
      icon = Icons[item?.event];
    }
    else if (item?.effected?.type === 'nest_protect') {
      icon = Icons[`nest_${item.event}`];
    }
    else {
      icon = Icons[item?.event];
    }
  }
  else {
    icon = Icons[item?.effected?.type];
  }
  return <ActivityIcon icon={icon} size={size} />
}

function LocationIcon({item, size = DEFAULT_SIZE}) {
  const icon = Icons[item?.event] || Icons.location_create;
  return <ActivityIcon icon={icon} size={size} />
}

function ModeIcon({item, size = DEFAULT_SIZE}) {
  const icon = Icons[item?.event];
  return <ActivityIcon icon={icon} size={size} />
}

const IconForType = {
  camera: CameraIcon,
  device: DeviceIcon,
  location: LocationIcon,
  mode: ModeIcon,
};

export function getIcon(item) {
  const Icon = IconForType[item?.type] || EmptyIcon;
  return (
    <Icon item={item} />
  );
}

export function getStyle(item = {}, darkMode = false) {
  let style;
  if (isDevice(item)) {
    if (isEnvironmental(item) || isIntegration(item)) {
      style = STYLES.event[item?.event];
    }
    else {
      style = STYLES.device[item?.effected?.type];
    }
  }
  if (isMode(item)) {
    style = STYLES.mode[item?.event];
  }
  if (isLocation(item)) {
    style = STYLES.location[item?.event];
  }
  style = style || {row: null, text: 'primary'};
  if (darkMode) {
    return {
      ...style,
      text: 'dark',
    };
  }
  return style;
}

export default {
  getIcon,
  getStyle,
  hasPreview,
  isBottomConnected,
  isTopConnected,
}
