import Overrides from '../constants/Overrides';

import * as Components from '.';
import * as Routes from '../routes';

const namespaceMapping = {
  components: Components,
  routes: Routes,
};

function FileOverride({children, filename, namespace}) {
  const override = Overrides.get(namespace, filename);

  if (override) {
    return children(override);
  }

  const resolved = namespaceMapping[namespace][filename];

  if (resolved) {
    return children(resolved);
  }

  return null;
}

export default FileOverride;
