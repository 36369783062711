import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {TextField} from 'formik-material-ui';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export default function PasswordInput({autoComplete='current-password', placeholder, ...rest}) {
  const {t} = useTranslation('Common');
  const [visible, setVisible] = useState(false);
  const onClick = useCallback(() => setVisible(!visible), [visible]);
  const defaultPlaceholder = useMemo(() => t('common.password'), [t]);
  return (
    <TextField
      autoComplete={autoComplete}
      placeholder={placeholder || defaultPlaceholder}
      type={visible ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={onClick}
              edge="end">
              {visible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )
      }}
      required
      {...rest} />
  );
}
