export const BEGIN_LOGOUT = 'auth/BEGIN_LOGOUT';
export const LOGIN = 'auth/LOGIN';
export const LOGOUT = 'auth/LOGOUT';
export const RECEIVE_TOKEN = 'auth/TOKEN';
export const SET_TOKEN = 'auth/SET_TOKEN';

export const BEGIN_SIGNUP = 'member/BEGIN_SIGNUP';
export const RECEIVE_DEVICE_TOKEN = 'member/DEVICE_TOKEN';
export const RECEIVE_LOCATIONS = 'member/LOCATIONS';
export const RECEIVE_NOTIFICATIONS = 'member/NOTIFICATIONS';
export const RECEIVE_PROFILE = 'member/PROFILE';
export const RECEIVE_SYSTEM = 'member/SYSTEM';
export const SIGNUP_FAILURE = 'member/SIGNUP_FAILURE';

export const RECEIVE_ALARM = 'location/ALARM';
export const RECEIVE_LOCATION = 'location/LOCATION';
export const RECEIVE_HUB = 'location/HUB';
export const RECEIVE_LOCATION_STATE = 'location/STATE';
export const RECEIVE_MODES = 'location/MODES';
export const RECEIVE_ACTIVITY = 'location/ACTIVITY';
export const RECEIVE_DEVICES = 'location/DEVICES';
export const RECEIVE_MEMBERS = 'location/MEMBERS';
export const RECEIVE_RFIDS = 'location/RFIDS';
export const RECEIVE_USER_CODES = 'location/USER_CODES';
export const RECEIVE_CAMERAS = 'location/CAMERAS';
export const RECEIVE_MONITORING = 'location/MONITORING';
export const RECEIVE_INTEGRATION = 'location/INTEGRATION';
export const RECEIVE_ENVIRONMENTAL = 'location/ENVIRONMENTAL';
export const RECEIVE_DEVICE_GROUPS = 'location/DEVICE_GROUPS';
export const RECEIVE_VISIBILITY = 'location/VISIBILITY';
export const RECEIVE_ALARM_CONTACTS = 'location/ALARM_CONTACTS';
export const RECEIVE_SUBSCRIPTION = 'location/SUBSCRIPTION';
export const REMOVE_LOCATION = 'location/REMOVE';
export const RESET_LOCATION = 'location/RESET';

export const CHANNEL_SUBSCRIBED = 'pusher/CHANNEL_SUBSCRIBED';
export const CHANNEL_UNSUBSCRIBED = 'pusher/CHANNEL_UNSUBSCRIBED';
export const LISTENER_ADDED = 'pusher/LISTENER_ADDED';
export const LISTENER_REMOVED = 'pusher/LISTENER_REMOVED';
export const PUSHER_STATUS = 'pusher/STATUS';

export const REMOVE_CAMERA = 'camera/REMOVE';
export const UPDATE_CAMERA = 'camera/UPDATE';

export const RECEIVE_NETWORK = 'camera/NETWORK';

export const REMOVE_INTEGRATION = 'integration/REMOVE';
export const UPDATE_INTEGRATION = 'integration/UPDATE';

export const REMOVE_MODE = 'mode/REMOVE';
export const UPDATE_MODE = 'mode/UPDATE';
export const REMOVE_MODE_ACTION = 'mode/REMOVE_ACTION';

export const REMOVE_RFID = 'rfid/REMOVE';
export const UPDATE_RFID = 'rfid/UPDATE';

export const REMOVE_USER_CODE = 'user_code/REMOVE';
export const UPDATE_USER_CODE = 'user_code/UPDATE';

export const REMOVE_DEVICE = 'device/REMOVE';
export const UPDATE_DEVICE = 'device/UPDATE';
export const OPTIMISTICLY_UPDATE_DEVICE = 'OPTIMISTICLY_UPDATE_DEVICE';
export const CLEAR_OPTIMISTIC_UPDATE = 'CLEAR_OPTIMISTIC_UPDATE';

export const REMOVE_DEVICE_GROUP = 'device_group/REMOVE';
export const UPDATE_DEVICE_GROUP = 'device_group/UPDATE';

export const UPDATE_ALARM_CONTACTS = 'alarm_contacts/UPDATE';

export const START_INSTALL = 'install/START';
export const FINISH_INSTALL = 'install/FINISH';

export const BACK_BUTTON = 'hardware/BACK_BUTTON';

export const NAVIGATION_APPSTATE_CHANGE = 'NAVIGATION_APPSTATE_CHANGE';

export const RECEIVE_DEALER = 'RECEIVE_DEALER';

export const SET_ADDED_SENSORS = 'SET_ADDED_SENSORS';
export const RESET_ADDED_SENSORS = 'RESET_ADDED_SENSORS';
