import React, {useMemo} from 'react';

import clsx from 'clsx';

import {BatteryLow} from '@scout-app/assets/icons';

import Text from '../elements/Text';

import LongPressable from './LongPressable';

import DeviceTypes from '../constants/DeviceTypes';

import useDeviceState from '../hooks/useDeviceState';
import useModel from '../hooks/useModel';

import Model from '../models/Device';

function Device(props) {
  const {classes, filtering, onLongPress, onPress, selected} = props;
  const device = useModel(props.device, Model);
  const currentState = useDeviceState('Device', device);
  const deviceType = DeviceTypes[device.type] || DeviceTypes.unknown;
  const Icon = useMemo(() => deviceType.icon(device), [device, deviceType]);
  const color = useMemo(() => {
    if (
      device.isOffline() ||
      (device.hasLowBattery() && device.isMotionSensor())
    ) {
      return 'red';
    }
    else if (device.hasLowBattery() && !device.isMotionSensor()) {
      return 'yellow';
    }
  }, [device]);
  return useMemo(() => {
    const {temperature} = device;
    return (
      <LongPressable
        className={clsx(classes.toggle, classes[color], {
          [classes.filtering]: filtering && !selected,
        })}
        onClick={() => onPress(device)}
        onLongPress={() => onLongPress(device)}>
        <Icon className={clsx(classes.icon, classes[color])} />
        {typeof temperature !== 'undefined' && (
          <div className={classes.temperature}>
            <Text className={clsx(classes.info, classes[color])} i18nKey="Device:temperature">{{temperature}}</Text>
          </div>
        )}
        <div className={classes.state}>
          {currentState && (
            <Text className={clsx(classes.info, classes[color])}>{currentState}</Text>
          )}
          {device.hasLowBattery() && (
            <BatteryLow className={clsx(classes.lowBattery, classes[color])} />
          )}
        </div>
        <Text className={clsx(classes.name, classes[color])}>{device.name}</Text>
      </LongPressable>
    );
  }, [classes, color, currentState, device, filtering, onLongPress, onPress, selected]);
}

export default Device;
