import React, {useMemo} from 'react';
import Spinner from 'react-spinkit';

import clsx from 'clsx';

import Colors from '../constants/Colors';
import Images from '../constants/Images';
import Overrides from '../constants/Overrides';

import useConfig from '../hooks/useConfig';
import useStyleSheet from '../hooks/useStyleSheet';

const styleSheet = () => ({
  container: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: Colors.get('background'),
  },
  logo: {
    width: '60%',
    maxWidth: 320,
    marginBottom: '1em',
  },
  splash: {
    backgroundColor: Colors.get('splashBg'),
  }
});

export default function Loading({splash}) {
  const {getColor, vendorName} = useConfig();
  const classes = useStyleSheet(styleSheet);
  const header = useMemo(() => {
    const LoadingHeader = Overrides.get('components', 'LoadingHeader');
    if (splash) {
      return LoadingHeader ? (
        <LoadingHeader />
      ) : (
        <img
          alt={vendorName}
          className={classes.logo}
          src={Images.get('VENDOR', 'LOGO')} />
      );
    }
    return null;
  }, [classes.logo, splash, vendorName]);
  return (
    <div className={clsx(classes.container, {
      [classes.splash]: splash,
    })}>
      {header}
      <Spinner
        name="line-scale"
        color={splash ? getColor('splashFg') : getColor('loading')} />
    </div>
  );
}
