import React from 'react';
import Colors from '../../constants/Colors';

import Svg from '../Svg';

const HEIGHT = 185;

export default function PairingVector({
  height = HEIGHT,
  width,
  ...rest
}) {
  return (
    <Svg
      height={height}
      width={width ? width : '100%'}
      fill={Colors.get('pairingIndicator')}
      {...rest}
    />
  );
}
