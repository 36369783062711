import React, {useMemo} from 'react';


import Mode from './Mode';
import AddButton from '../AddButton';

function Modes({
  admin,
  modes,
  paywall = false,
  onPress,
  onLongPress,
  onAddMode,
}) {
  const children = useMemo(
    () =>
      (modes || []).map((mode, i) => (
        <Mode
          key={i}
          mode={mode}
          paywall={paywall}
          onPress={onPress}
          onLongPress={onLongPress}
        />
      )),
    [modes, onLongPress, onPress, paywall],
  );
  return useMemo(
    () => (
      <div>
        {children}
        {admin && (
          <AddButton i18nKey="Mode:add_mode" onPress={onAddMode} marginTop="1em">
            add mode
          </AddButton>
        )}
      </div>
    ),
    [admin, children, onAddMode],
  );
}

export default Modes;
