import {
  Doorbell,
  IndoorCamera,
  LogitechCircle,
  NestIndoor,
} from '@scout-app/assets/icons';

import Images from './Images';

// TODO - export function to allow for overrides
export default {
  camera: {
    name: 'Camera',
    icon: IndoorCamera,
    image: Images.defer('DEVICES', 'CAMERA'),
  },
  doorbell: {
    name: 'Doorbell',
    icon: Doorbell,
    iconSize: 54,
    image: Images.defer('DEVICES', 'DOORBELL'),
    imageStyle: {
      marginTop: -20,
      marginBottom: -20,
    },
  },
  logitech: {
    name: 'Logitech Circle',
    icon: LogitechCircle,
    iconSize: 72,
    iconStyle: {},
  },
  nest: {
    name: 'Nest Cam',
    icon: NestIndoor,
    iconSize: 64,
    iconStyle: {},
  },
};
