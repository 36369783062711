import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import sharedReducers from '../reducers';
import sharedMiddleware from '../middleware';

const sharedBlacklist = [
  'optimistic',
  'pusher',
];

function combineAndPersistReducers(reducers, customReducers, blacklist) {
  const allReducers = {...reducers, ...customReducers};
  const reducersWithPersistence = Object.keys(allReducers).reduce(
    (combined, key) => {
      const currentReducer = allReducers[key];
      if (blacklist.indexOf(key) < 0) {
        const {migrate, version} = currentReducer;
        combined[key] = persistReducer(
          {
            key,
            migrate,
            storage,
            timeout: null,
            version,
          },
          currentReducer,
        );
      } else {
        combined[key] = currentReducer;
      }
      return combined;
    },
    {},
  );

  return combineReducers(reducersWithPersistence);
}

export default function create({
  blacklist = [],
  middleware = [],
  reducers = {},
} = {}) {
  const combinedBlacklist = sharedBlacklist.concat(blacklist);
  const combinedMiddleware = sharedMiddleware.concat(middleware);
  const combinedReducers = combineAndPersistReducers(
    sharedReducers,
    reducers,
    combinedBlacklist,
  );

  const store = createStore(
    combinedReducers,
    compose(applyMiddleware(...combinedMiddleware)),
  );
  let persistor = persistStore(store);

  store.updateReducers = updatedReducers => {
    const replacementReducer = combineAndPersistReducers(
      combinedReducers,
      updatedReducers,
      combinedBlacklist,
    );
    store.replaceReducer(replacementReducer);
    persistor = persistStore(store);
  };

  return {store, persistor};
}
