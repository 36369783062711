import React from 'react';

import {TextField} from 'formik-material-ui';

import Overrides from '../../constants/Overrides';

function StatePicker({country, ...props}) {
  return (
    <TextField type="text" {...props} />
  );
}

StatePicker.getInitialValue = ({state} = {}, fallback) =>
  state ||
  fallback ||
  Overrides.get('vendor', 'default_state', 'IL');

export default StatePicker;
