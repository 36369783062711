import {
  RECEIVE_ALARM,
  RECEIVE_LOCATION,
  RECEIVE_HUB,
  RECEIVE_LOCATION_STATE,
  RECEIVE_MODES,
  RECEIVE_ACTIVITY,
  RECEIVE_DEVICES,
  RECEIVE_MEMBERS,
  RECEIVE_RFIDS,
  RECEIVE_USER_CODES,
  RECEIVE_CAMERAS,
  RECEIVE_MONITORING,
  RECEIVE_INTEGRATION,
  RECEIVE_VISIBILITY,
  RECEIVE_ALARM_CONTACTS,
  REMOVE_LOCATION,
  RESET_LOCATION,
  START_INSTALL,
  FINISH_INSTALL,
  RECEIVE_SUBSCRIPTION,
  LOGOUT,
  SET_ADDED_SENSORS,
  RESET_ADDED_SENSORS,
} from '../actions/ActionTypes';

const initialState = {
  modes: [],
  activity: [],
  devices: [],
  members: [],
  rfids: [],
  user_codes: [],
  cameras: [],
  subscription: null,
  camera_subscription: null,
  state: {},
  environmental: {},
  alarm_contacts: {},
  last_alarm: null,
  setup: false,
  added_sensors: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_LOCATION: {
      const locationChanged = Boolean(
        state.id && action.location.id && state.id !== action.location.id,
      );
      const locationState = action.location.state || {};
      const setup = locationChanged
        ? locationState.ready === false
        : state.setup;
      const added_sensors = locationChanged
        ? initialState.added_sensors
        : state.added_sensors;

      return {
        ...state,
        ...action.location,
        alarm_contacts: {
          ...(action.location.alarm_contacts || {}),
        },
        connected_accounts: {
          ...(action.location.connected_accounts || {}),
        },
        pro_monitoring: {
          ...(action.location.pro_monitoring || {}),
        },
        state: {
          ...locationState,
        },
        setup,
        added_sensors,
      };
    }
    case RECEIVE_LOCATION_STATE:
      return {
        ...state,
        state: {
          ...(action.state || {}),
        },
      };
    case RECEIVE_HUB: {
      const oldHub = state.hub || {};
      const hub = action.hub
        ? {
            ...oldHub,
            ...action.hub,
            reported: {
              ...(oldHub.reported || {}),
              ...(action.hub.reported || {}),
            },
          }
        : action.hub;
      return {
        ...state,
        hub,
        hub_id: (hub || {}).id,
      };
    }
    case RECEIVE_MODES: {
      const modes = action.modes || [];
      const mode_ids = [];
      const location_state = {
        armed: null,
        arming: null,
        alarmed: null,
      };
      modes.forEach(mode => {
        mode_ids.push(mode.id);
        if (mode.state !== 'disarmed') {
          location_state[mode.state] = mode.id;
        }
      });
      return {
        ...state,
        modes,
        mode_ids: (action.modes || []).map(({id}) => id),
        state: {
          ...(state.state || {}),
          ...location_state,
        },
      };
    }
    case RECEIVE_ACTIVITY:
      return {
        ...state,
        activity: action.activity,
      };
    case RECEIVE_DEVICES:
      return {
        ...state,
        devices: action.devices,
        device_ids: (action.devices || []).map(({id}) => id),
      };
    case RECEIVE_MEMBERS:
      return {
        ...state,
        members: action.members,
        member_ids: (action.members || []).map(({id}) => id),
      };
    case RECEIVE_RFIDS:
      return {
        ...state,
        rfids: action.rfids,
        rfid_ids: (action.rfids || []).map(({id}) => id),
      };
    case RECEIVE_USER_CODES:
      return {
        ...state,
        user_codes: action.user_codes,
        user_code_ids: (action.user_codes || []).map(({id}) => id),
      };
    case RECEIVE_CAMERAS:
      return {
        ...state,
        cameras: action.cameras,
        camera_ids: (action.cameras || []).map(({id}) => id),
      };
    case RECEIVE_MONITORING:
      return {
        ...state,
        pro_monitoring: action.pro_monitoring,
      };
    case RECEIVE_INTEGRATION:
      return {
        ...state,
        connected_accounts: {
          ...state.connected_accounts,
          [action.name]: action.integration,
        },
      };
    case RECEIVE_VISIBILITY:
      return {
        ...state,
        visibility: {
          ...state.visibility,
          [action.group]: action.hidden,
        },
      };
    case RECEIVE_ALARM_CONTACTS:
      return {
        ...state,
        alarm_contacts: action.alarm_contacts,
      };
    case START_INSTALL:
      return {
        ...state,
        setup: true,
      };
    case FINISH_INSTALL:
      return {
        ...state,
        setup: false,
      };
    case SET_ADDED_SENSORS:
      return {
        ...state,
        added_sensors: {
          ...state.added_sensors,
          ...action.sensors,
        },
      };
    case RESET_ADDED_SENSORS:
      return {
        ...state,
        added_sensors: initialState.added_sensors,
      };
    case RECEIVE_SUBSCRIPTION: {
      const {subscription, plan_code} = action;
      const {cloud_video_recording = []} = subscription;
      let {plan} = subscription;
      if (subscription && plan_code) {
        plan = /always-on-p/.test(plan_code) ? 'pro' : 'self';
      }
      return {
        ...state,
        cloud_video_recording,
        plan,
      };
    }
    case RECEIVE_ALARM:
      return {
        ...state,
        last_alarm: action.timestamp,
      };
    case RESET_LOCATION:
    case REMOVE_LOCATION:
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
