import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {Container} from '@material-ui/core';

import * as actions from '../actions/member';

import Loading from '../components/Loading';
import Component from '../components/NotificationSettings';

import useActions from '../hooks/useActions';
import useStyleSheet from '../hooks/useStyleSheet';
import useTitle from '../hooks/useTitle';

const selector = ({member: {notifications}}) => ({notifications});

const styleSheet = {
  container: {
    padding: 24,
  },
};

export default function NotificationSettings() {
  const {t} = useTranslation('NotificationSettings');
  const classes = useStyleSheet(styleSheet);
  const {getNotifications, updateNotifications} = useActions(actions);
  const [loaded, setLoaded] = useState(false);

  const {notifications} = useSelector(selector);

  useEffect(() => {
    getNotifications()
      .then(() => setLoaded(true));
  }, [getNotifications]);

  useTitle(t('NotificationSettings:NotificationSettings'));

  const children = useMemo(() => {
    if(!loaded)
      return (<Loading />);
    return (
      <Component
        notifications={notifications}
        onSubmit={updateNotifications} />
    );
  }, [loaded, notifications, updateNotifications]);

  return (
    <Container maxWidth="md" className={classes.container}>
      {children}
    </Container>
  );
}
