import {
  CLEAR_OPTIMISTIC_UPDATE,
  OPTIMISTICLY_UPDATE_DEVICE,
} from './ActionTypes';

export const clearOptimisticlyUpdate = (device_id, device_type) => dispatch =>
  dispatch({
    type: CLEAR_OPTIMISTIC_UPDATE,
    device_id,
    device_type,
  });

export const optimisticlyUpdateDevice = (
  device_id,
  device_type,
  data,
) => dispatch =>
  dispatch({
    type: OPTIMISTICLY_UPDATE_DEVICE,
    device_id,
    device_type,
    device_data: data,
  });
