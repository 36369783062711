import React, {forwardRef, useCallback, useImperativeHandle, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Form, Formik} from 'formik';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';

const initialValues = {};

function DialogForm({children, label, title}, ref) {
  const {t} = useTranslation('Common');
  const [open, setOpen] = useState(false);
  const promise = useRef(null);
  const onClose = useCallback(() => {
    setOpen(false);
    promise.current({});
  }, []);
  const onSubmit = useCallback((values, {setSubmitting}) => {
    setOpen(false);
    setSubmitting(false);
    promise.current(values);
  }, []);
  const prompt = useCallback(() => {
    return new Promise(resolve => {
      promise.current = resolve;
      setOpen(true);
    });
  }, []);
  useImperativeHandle(
    ref,
    () => ({
      prompt,
    }),
    [prompt],
  );
  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {({ submitForm }) => (
        <Dialog open={open} onClose={onClose}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{label}</DialogContentText>
            <Form>{children}</Form>
          </DialogContent>
          <DialogActions>
            <Button onClick={submitForm} color="primary">
              {t('common.confirm')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}

export default forwardRef(DialogForm);
