import {useEffect, useMemo, useRef} from 'react';

import * as actions from '../actions/pusher';

import useActions from './useActions';

export default function usePusher(location_id, trigger, callback) {
  const {subscribe, unsubscribe} = useActions(actions);
  const done = useRef(undefined);
  const promise = useMemo(
    () => new Promise(resolve => (done.current = resolve)),
    [],
  );
  useEffect(() => {
    if (location_id) {
      subscribe(location_id, trigger, callback).then(done.current);
    }
    return () => {
      if (location_id) {
        unsubscribe(location_id, trigger, callback);
      }
    };
  }, [callback, location_id, subscribe, trigger, unsubscribe]);
  return promise;
}
