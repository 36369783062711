import moment from './moment';

import SubscriptionPlans from '../constants/SubscriptionPlans';

const DISPLAY_FORMAT = 'MMMM Do YYYY';
const PARSE_FORMAT = "YYYY-MM-DD'T'HH:mm:ss Z";

export function getBasePlan(plan_code) {
  return (plan_code || '').replace(/-\d?y?$/, '');
}

export function getCVR(subscription) {
  return subscription.cloud_video_recording || [];
}

export function getFriendlyName(name) {
  return (name || '').replace(' (yearly)', '');
}

export function isAnnualPlan(plan_code) {
  return /-\d?y$/.test(plan_code || '');
}

export function getPlanCode(plan) {
  return isAnnualPlan(plan) ? plan : null;
}

export function isCVRPlan(plan_code) {
  return /^cvr/.test(plan_code || '');
}

export function isProPlan(plan_code) {
  return /^always-on-p/.test(plan_code || '');
}

// TODO - deprecate
export function isRecommendedPlan(plan_code) {
  return /-p$|p-\d?y$/.test(plan_code);
}

export function formatBillingDate(date) {
  return moment(date, PARSE_FORMAT).format(DISPLAY_FORMAT);
}

// TODO - deprecate
export function parseBillingInfo(billingInfo = {}) {
  return {
    card: billingInfo?.card,
    lastFour: billingInfo?.last_four,
    name: billingInfo?.name,
  };
}

// TODO - deprecate
export function cvrPlanAmount(plan_code, plans) {
  const cvrPlan = isCVRPlan(plan_code)
    ? plan_code
    : isAnnualPlan(plan_code)
    ? 'cvr-y'
    : 'cvr';
  const plan = plans[cvrPlan];
  return plan ? plan.amount : 0;
}

// TODO - deprecate
export function planAmount(plan, cloudVideoRecording = [], plans = []) {
  let billedCameras = cloudVideoRecording.length;
  if (!billedCameras) {
    return plan.amount;
  }

  if (isProPlan(plan.code)) {
    billedCameras--;
  }

  let amount = isCVRPlan(plan.code) ? 0 : plan.amount;
  amount += billedCameras * cvrPlanAmount(plan.code, plans);

  return amount;
}

// TODO - deprecate
export function planName(t, plan, cloud_video_recording = []) {
  if (!cloud_video_recording.length || isCVRPlan(plan.code)) {
    return plan.name;
  }
  return t('MonitoringPlans:with_cvr', {planName: plan.name});
}

export function isPaywall(location) {
  return (
    location.subscription_required &&
    location.plan === 'free' &&
    location.hub_id &&
    Date.now() - new Date(location.created) > 43200000
  );
}

export function hasPlanChanged(
  {cloud_video_recording, plan_code},
  subscription,
) {
  const planChanged = subscription && subscription.plan !== plan_code;
  const camerasChanged =
    subscription &&
    subscription.cloud_video_recording.sort().join() !==
      cloud_video_recording.sort().join();
  return planChanged || camerasChanged;
}

export function getPendingSubscriptionState(plan_code) {
  const plan = getBasePlan(plan_code);
  return {plan, plan_code};
}

export function getSubscriptionState(subscription) {
  const current_plan = subscription && subscription.plan;
  const plan = getBasePlan(current_plan);
  const plan_code = getPlanCode(current_plan);
  const cloud_video_recording = getCVR(subscription);

  return {plan, plan_code, cloud_video_recording};
}

// TODO - deprecate
export function getPlanInfo({
  plans,
  plan_code,
  plan,
  cloud_video_recording,
  free_camera_subscriptions = 0,
}) {
  const selected = plans[plan_code || plan];
  const basePlan = getBasePlan(plan_code || plan);
  const isAnnual = isAnnualPlan(selected && selected.code);
  const isPro = isProPlan(selected && selected.code);
  const isCVR = isCVRPlan(selected && selected.code);
  const selectedAmount = (selected && selected.amount) || 0;
  const selectedInterval = (selected && selected.interval) || 'mo'; // TODO - deprecate
  const cameraPlan = (isAnnual ? plans['cvr-y'] : plans.cvr) || {};
  const pricePerCamera = cameraPlan.amount || 0;
  const enabledCameras = cloud_video_recording.length;
  const freeCameras = enabledCameras && isPro ? free_camera_subscriptions : 0;
  const camerasAmount = pricePerCamera * (enabledCameras - freeCameras);
  const totalAmount = selectedAmount + camerasAmount;

  return {
    basePlan,
    camerasAmount,
    enabledCameras,
    isAnnual,
    isCVR,
    isPro,
    selectedAmount,
    selectedInterval,
    totalAmount,
  };
}

export function reducePlans(monitoring_plans, flags = {}) {
  return monitoring_plans.reduce(
    (result, {code, ...rest}) => ({
      ...result,
      [code]: {
        ...rest,
        code,
        ...(SubscriptionPlans(getBasePlan(code), flags) || {}),
      },
    }),
    {},
  );
}
