import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import md5 from 'md5';

import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import useModel from '../hooks/useModel';

import Model from '../models/Member';

function Member(props) {
  const {admin = true, onPress} = props;
  const {t} = useTranslation('Members');
  const member = useModel(props.member, Model);
  return useMemo(
    () => (
      <ListItem onClick={() => onPress(member)}>
        <ListItemAvatar>
          <Avatar alt={member.name()} src={`https://secure.gravatar.com/avatar/${md5(
            member.email,
          )}?s=64&d=mm`} />
        </ListItemAvatar>
        <ListItemText primary={member.name()} secondary={t(member.currentState)} />
        {admin && (
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
        )}
      </ListItem>
    ),
    [admin, member, onPress, t],
  );
}

export default Member;
