import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import {Rfid as RfidIcon} from '@scout-app/assets/icons';

import Colors from '../constants/Colors';
import useModel from '../hooks/useModel';
import useStyleSheet from '../hooks/useStyleSheet';

import Model from '../models/Rfid';

const styleSheet = () => ({
  icon: {
    fontSize: 36,
    color: Colors.get('item'),
  }
});

function Rfid(props) {
  const {admin = true, onPress} = props;
  const {t} = useTranslation('Rfids');
  const rfid = useModel(props.rfid, Model);
  const classes = useStyleSheet(styleSheet);
  return useMemo(
    () => (
      <ListItem onClick={() => onPress(rfid)}>
        <ListItemIcon>
          <RfidIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary={rfid.name} secondary={t(rfid.state)} />
        {admin && (
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
        )}
      </ListItem>
    ),
    [admin, classes.icon, onPress, rfid, t],
  );
}

export default Rfid;
