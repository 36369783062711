import {
  RECEIVE_TOKEN,
  RECEIVE_PROFILE,
  RECEIVE_LOCATION,
  RECEIVE_LOCATIONS,
  RECEIVE_NOTIFICATIONS,
  RECEIVE_DEVICE_TOKEN,
  REMOVE_LOCATION,
  LOGOUT,
} from '../actions/ActionTypes';

const initialState = {
  loggedIn: false,
  token: null,
  locations: [],
  device_token: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_TOKEN:
      return {
        ...state,
        ...action.member,
        loggedIn: true,
        token: action.token,
      };
    case RECEIVE_PROFILE:
      return {
        ...state,
        ...action.member,
      };
    case RECEIVE_LOCATION: {
      const location_ids = state.locations.map(({id}) => id);
      const index = location_ids.indexOf(action.location.id);
      if (index > -1) {
        return {
          ...state,
          locations: [
            ...state.locations.slice(0, index),
            {
              ...state.locations[index],
              name: action.location.name,
            },
            ...state.locations.slice(index + 1),
          ],
        };
      }
      return state;
    }
    case RECEIVE_LOCATIONS:
      return {
        ...state,
        locations: action.locations,
      };
    case RECEIVE_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.notifications,
      };
    case RECEIVE_DEVICE_TOKEN:
      return {
        ...state,
        device_token: action.device_token,
        device_registered: action.device_registered,
      };
    case REMOVE_LOCATION:
      return {
        ...state,
        locations: state.locations.filter(
          location => location.id !== action.location_id,
        ),
      };
    case LOGOUT:
      return {
        ...initialState,
        device_token: state.device_token,
      };
    default:
      return state;
  }
};
