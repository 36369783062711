import React, {useMemo} from 'react';

import AddButton from './AddButton';
import DeviceGroup from './DeviceGroup';

import useStyleSheet from '../hooks/useStyleSheet';

const defaultVisibility = {all: true};

const styleSheet = (theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});

function Devices({
  location,
  onAddDevice,
  onCamera,
  onDevice,
  onEdit,
  onHub,
  onIntegration,
  onLongPressCamera,
  onLongPressDevice,
  setGroupVisibility,
}) {
  const classes = useStyleSheet(styleSheet);
  const visibility = location.visibility || defaultVisibility;
  const sharedProps = useMemo(
    () => ({
      location,
      onCamera,
      onDevice,
      onHub,
      onIntegration,
      onLongPressCamera,
      onLongPressDevice,
    }),
    [
      location,
      onCamera,
      onDevice,
      onHub,
      onIntegration,
      onLongPressCamera,
      onLongPressDevice,
    ],
  );
  const admin = false; // location.member_state === 'admin';
  return useMemo(
    () => (
      <div className={classes.container}>
        {admin && (
          <AddButton
            i18nKey="Devices:add_device"
            onPress={onAddDevice}>
            add device
          </AddButton>
        )}
        {location.hasAnyDevice(true) && (
          <>
            <DeviceGroup
              hidden={visibility.all !== true}
              onEdit={group => onEdit(-1, group)}
              onVisibilityChange={hidden => setGroupVisibility('all', hidden)}
              {...sharedProps}
            />
            {(location.device_groups || []).map((device_group, i) => (
              <DeviceGroup
                key={i}
                group={device_group}
                hidden={visibility[i.toString()] !== true}
                onEdit={group => onEdit(i, group)}
                onVisibilityChange={setGroupVisibility}
                {...sharedProps}
              />
            ))}
          </>
        )}
      </div>
    ),
    [admin, classes.container, location, onAddDevice, onEdit, setGroupVisibility, sharedProps, visibility],
  );
}

export default Devices;
