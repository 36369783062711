import React, {useCallback, useMemo, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {useSnackbar} from 'notistack';
import {Field} from 'formik';

import BackButton from '@material-ui/icons/ArrowBack';

import * as actions from '../actions/member';

import {DialogForm, PasswordInput} from '../components/form';

import useActions from '../hooks/useActions';
import useLeftButton from '../hooks/useLeftButton';
import useMember from '../hooks/useMember';
import useTitle from '../hooks/useTitle';

import Component from '../routes/EditProfile';

function EditProfile({prompt}) {
  const history = useHistory();
  const {t} = useTranslation('EditProfile');
  const {enqueueSnackbar} = useSnackbar();
  const {updateMember} = useActions(actions);
  const member = useMember();
  const dialog = useRef(null);

  useTitle(t('EditProfile:EditProfile'));
  useLeftButton(BackButton, history.goBack);

  const onUpdate = useCallback(
    ({email, fname, lname, phone_number}, {setSubmitting}) => {
      dialog.current.prompt()
        .then(({password}) => {
          if (password) {
            return updateMember({
              fname,
              lname,
              phone_number,
              email,
              password
            })
              .then(() => {
                enqueueSnackbar(t('ProfileUpdated'));
                history.replace('/profile');
              });
          }
        })
        .catch(err => {
          setSubmitting(false);
          alert(t(err.error || err.message || err));
        })
        .then(() => setSubmitting(false));
    },
    [enqueueSnackbar, history, t, updateMember],
  );

  return useMemo(() => {
    return (
      <>
        <Component
          member={member}
          onUpdate={onUpdate} />
        <DialogForm
          ref={dialog}
          title={t('UpdateProfile')}
          label={t('EnterPassword')}>
          <Field
            component={PasswordInput}
            margin="dense"
            name="password"
            fullWidth />
        </DialogForm>
      </>
    );
  }, [member, onUpdate, t]);
}

export default EditProfile;
