import React, {useMemo} from 'react';

import {Field, Form, Formik} from 'formik';
import {Switch} from 'formik-material-ui';

import {Container} from '@material-ui/core';

import {HeaderText, HeadlineText} from '../../elements';

const noop = () => {};

function Switching(props) {
  const {device, onChange, paywall = false, value} = props;

  const fields = useMemo(
    () => [
      {
        name: 'text',
        component: (
          <HeaderText i18nKey="Device:device.optional_settings">
            Optional Device Settings:
          </HeaderText>
        ),
        interactive: false,
      },
      {
        // handle state changes without form
        name: 'state',
        component: inputProps => (
          <Switch
            {...inputProps}
            disabled={paywall || device.isOffline()}
            onSwitch={on => onChange({state: on ? 'on' : 'off'})}>
            <HeadlineText i18nKey="Device:device.power">
              Power
            </HeadlineText>
          </Switch>
        ),
      },
    ],
    [device, onChange, paywall],
  );

  const initialValues = useMemo(
    () => ({
      state: value.state,
    }),
    [value.state],
  );

  return useMemo(
    () => (
      <Form
        fields={fields}
        initialValues={initialValues}
        onSubmit={noop}
        renderSubmit={noop}
        unsetSubmittingTimeout={0}
      />
    ),
    [fields, initialValues],
  );
}

Switching.fieldName = 'switch_field';

Switching.getFieldProps = ({device, onChange, onChangeValue, styles}) => ({
  device,
  onChange,
  onChangeValue,
  styles,
});

Switching.populateInitialValues = device => ({
  state: device.isOn(),
});

export default Switching;
