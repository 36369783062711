/* global fetch */
import qs from 'qs';

import {RECURLY_PUBLIC_KEY} from '../app.json';

const version = '4.8.5';

export const getToken = billing_info => (dispatch, getState) => {
  const data = {
    key: RECURLY_PUBLIC_KEY,
    version,
    ...billing_info,
  };
  return fetch(
    `https://api.recurly.com/js/v1/token?${qs.stringify(data, {
      encodeValuesOnly: true,
    })}`,
    {method: 'post'},
  )
    .then(res => res.text())
    .then(text => {
      try {
        return JSON.parse(text);
      } catch (e) {
        // ignored
      }
      throw text;
    })
    .then(json => {
      if (json.id) {
        return json.id;
      }
      throw json;
    })
    .catch(err => {
      if (err.error) {
        throw err.error.message;
      }
      throw err.message || err;
    });
};

export default {
  getToken,
};
