import React, {useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import human from 'humanparser';

import * as actions from '../actions/member';
import Overrides from '../constants/Overrides';
import FileOverride from '../components/FileOverride';
import useActions from '../hooks/useActions';

const stripPhone = (phone = '') => phone.replace(/\D/g, '');

function onError(err) {
  alert(err.message || err);
}

function Signup(props) {
  const history = useHistory();
  const {t} = useTranslation('Signup');
  const {signup} = useActions(actions);
  const SignupHeader = useMemo(
    () => Overrides.get('components', 'SignupHeader'),
    [],
  );

  const onSubmit = useCallback(
    ({name, email, phone_number, password, password_confirmation}, {setSubmitting}) => {
      if (!name) {
        setSubmitting(false);
        return onError(t('Please enter your name.'));
      }

      if (!email) {
        setSubmitting(false);
        return onError(t('Please enter your email.'));
      }

      if (!phone_number) {
        setSubmitting(false);
        return onError(t('Please enter your phone number.'));
      }

      if (!password) {
        setSubmitting(false);
        return onError(t('Please enter a password.'));
      }

      if (password !== password_confirmation) {
        setSubmitting(false);
        return onError(t("Password doesn't match confirmation."));
      }

      const { firstName: fname, lastName: lname } = human.parseName(name);

      signup({
        email,
        fname,
        lname,
        password,
        password_confirmation,
        phone_number: stripPhone(phone_number),
      })
      .catch(err => {
        setSubmitting(false);
        onError(t(err.error || err.message || err));
      });
    },
    [signup, t],
  );

  return useMemo(
    () => (
      <FileOverride namespace="routes" filename="Signup">
        {Component => (
          <Component
            headerComponent={SignupHeader}
            onBack={() => history.goBack()}
            onSubmit={onSubmit}
          />
        )}
      </FileOverride>
    ),
    [SignupHeader, history, onSubmit],
  );
}

export default Signup;
