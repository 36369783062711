import BaseModel from './BaseModel';

function isSercomm({type}) {
  return /^sercomm/i.test(type || '');
}

function isType(hub, ...types) {
  return types.indexOf(hub.type) > -1;
}

function isZigbee(hub) {
  const reported = hub.reported || {};
  return !!reported.zigbee;
}

function isZwave(hub) {
  const reported = hub.reported || {};
  return !!reported.zwave;
}

class Hub extends BaseModel {
  hasReported() {
    return !!this.reported;
  }

  get state() {
    const reported = this.reported || {};
    if (!this.id) {
      return null;
    }
    if (!this.hasReported()) {
      return 'unknown';
    }
    if (reported.status === 'offline') {
      return 'offline';
    }
    return 'online';
  }

  isOffline() {
    const reported = this.reported || {};
    return reported.status === 'offline';
  }

  get name() {
    if (!this.id) {
      return 'setup_location';
    }
    return 'Hub';
  }

  get currentState() {
    if (this.state !== 'online') {
      return this.state;
    }
    const result = [];
    if (this.connection === 'cellular') {
      result.push(this.connection);
    }
    if (this.power === 'backup_battery') {
      result.push(this.power);
    }
    if (result.length) {
      return result;
    }
    return this.state;
  }

  get connection() {
    const reported = this.reported || {};
    const cellular = reported.cellular || {};
    if (!this.hasReported()) {
      return 'unknown';
    }
    if (this.isOffline()) {
      return 'none';
    }
    if (cellular.active) {
      return 'cellular';
    }
    return 'ethernet';
  }

  onCellular() {
    const reported = this.reported || {};
    const cellular = reported.cellular || {};
    return !!cellular.active;
  }

  get power() {
    const reported = this.reported || {};
    const battery = reported.battery || {};
    if (!this.hasReported() || this.isOffline()) {
      return 'unknown';
    }
    if (battery.active) {
      return 'backup_battery';
    }
    return 'outlet';
  }

  onBattery() {
    const reported = this.reported || {};
    const battery = reported.battery || {};
    return !!battery.active;
  }

  get fw_version() {
    const reported = this.reported || {};
    return reported.fw_version;
  }

  get mac_address() {
    const reported = this.reported || {};
    return reported.mac_address || reported.mac;
  }

  get remote_ip() {
    if (!this.hasReported() || this.isOffline()) {
      return;
    }
    const reported = this.reported || {};
    const connection = reported.connection || {};
    const remote = connection.remote || {};
    return remote.ip;
  }

  get remote_port() {
    if (!this.hasReported() || this.isOffline()) {
      return;
    }
    const reported = this.reported || {};
    const connection = reported.connection || {};
    const remote = connection.remote || {};
    return remote.port;
  }

  get cellular_carrier() {
    if (!this.hasReported() || this.isOffline()) {
      return;
    }
    const reported = this.reported || {};
    const cellular = reported.cellular || {};
    return cellular.carrier;
  }

  get cellular_iccid() {
    if (!this.hasReported() || this.isOffline()) {
      return;
    }
    const reported = this.reported || {};
    const cellular = reported.cellular || {};
    return cellular.iccid;
  }

  get cellular_rssi() {
    if (!this.hasReported() || this.isOffline()) {
      return 'unknown';
    }
    const reported = this.reported || {};
    const cellular = reported.cellular || {};
    const strength = cellular.strength || {};
    return strength.rssi;
  }

  get cellular_strength() {
    const rssi = this.cellular_rssi;
    if (typeof rssi === 'string') {
      return rssi;
    }
    if (!rssi || rssi === 99) {
      return 'no_signal';
    } else if (rssi < 7) {
      return 'poor';
    } else if (rssi < 10) {
      return 'ok';
    } else if (rssi < 18) {
      return 'good';
    } else if (rssi < 24) {
      return 'very_good';
    }
    return 'excellent';
  }

  get siren() {
    const reported = this.reported || {};
    return !!reported.siren;
  }

  get chirps() {
    const desired = this.desired || {};
    const chirps = desired.chirps || {};
    return chirps.arming !== false;
  }

  isSercomm() {
    return isSercomm(this);
  }

  isType(...types) {
    return isType(this, ...types);
  }

  isZigbee() {
    return isZigbee(this);
  }

  isZwave() {
    return isZwave(this);
  }
}

Hub.isSercomm = isSercomm;
Hub.isType = isType;
Hub.isZigbee = isZigbee;
Hub.isZwave = isZwave;

export default Hub;
