import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {List, ListItem, ListSubheader} from '@material-ui/core';

import * as actions from '../actions/location';

import Colors from '../constants/Colors';
import ActivityHeader from '../components/activity/Header';
import ActivityItem from '../components/activity/Item';

import useActions from '../hooks/useActions';
import useLocation from '../hooks/useLocation';
import useStyleSheet from '../hooks/useStyleSheet';

import {DEFAULT_SIZE, group} from '../lib/Activity';

const styleSheet = (theme) => ({
  list: {
    width: '100%',
  },
  header: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: Colors.get('background'),
  },
  date: {
    fontWeight: '400',
  },
  slideshow: {
    display: 'flex',
    flex: 1,
  },
  item: {
    display: 'flex',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    width: DEFAULT_SIZE,
  },
  icon: {
    display: 'flex',
    width: DEFAULT_SIZE,
    height: DEFAULT_SIZE,
    justifyContent: 'center',
    alignItems: 'center',
  },
  connector: {
    flexGrow: 1,
    flex: 1,
    marginTop: -2,
    marginBottom: -2,
    width: 2,
  },
  connected: {
    backgroundColor: Colors.get('armed'),
  },
  top: {
    flex: -1,
    flexGrow: 0,
    height: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  details: {
    flex: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  description: {

  },
  time: {
    fontSize: 12,
    fontWeight: '200',
  },
  image: {
    width: '100%',
    maxWidth: 640,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  more: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
});

function Activity() {
  const classes = useStyleSheet(styleSheet);
  const {t} = useTranslation('Activity');
  const location = useLocation();
  const {getActivity, setActivity} = useActions(actions);
  const [endReached, setEndReached] = useState(false);
  const [loading, setLoading] = useState(false);
  const sections = useMemo(() => group(location.activity), [location.activity]);
  const before = useMemo(() => {
    const lastSection = sections[sections.length - 1] || {};
    const items = lastSection.data || [];
    const lastItem = items[items.length - 1] || {};
    return lastItem.timestamp;
  }, [sections]);
  const onLoadMore = useCallback(
    () => {
      if (!loading) {
        setLoading(true);
        getActivity(location.id, {before}).then(items => {
            setActivity(location.activity.concat(items));
            setEndReached(!items.length);
            setLoading(false);
        });
      }
    },
    [before, getActivity, loading, location.activity, location.id, setActivity],
  );
  return useMemo(() => (
    <List className={classes.list}>
      {sections.map((section, sectionId) => (
        <div key={sectionId}>
          <ListSubheader className={classes.header}>
            <ActivityHeader classes={classes} {...section} />
          </ListSubheader>
          {section.data.map((item, index) => (
            <ActivityItem
              key={`${sectionId}-${index}`}
              classes={classes}
              index={index}
              item={item}
              siblings={section.data} />
          ))}
        </div>
      ))}
      {!endReached && !loading && (
        <ListItem
         button
         className={classes.more}
         onClick={onLoadMore}>
         {t('view_more')}
       </ListItem>
      )}
    </List>
  ), [classes, endReached, loading, onLoadMore, sections, t]);
}

export default Activity;
