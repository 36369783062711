import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {HOST_NAME} from '../app.json';

import actions from '../actions';

import FileOverride from '../components/FileOverride';

import useActions from '../hooks/useActions';
import useLocation from '../hooks/useLocation';

import {isPaywall} from '../lib/MonitoringPlan';

const selector = ({
  member,
  optimistic,
}) => ({
  authorization: member.token,
  optimistic,
});

const dispatch = [actions.location, actions.optimistic];

function Home() {
  const history = useHistory();
  const location = useLocation();
  const {
    authorization,
    optimistic,
  } = useSelector(selector);
  const [
    {addMode, setSiren, toggleMode, updateDevice},
    {optimisticlyUpdateDevice},
  ] = useActions(dispatch);

  const admin = false; // location.member_state === 'admin';

  const paywall = useMemo(() => isPaywall(location), [location]);

  const onPressDevice = useCallback(
    (device, deviceState) => {
      optimisticlyUpdateDevice(device.id, device.type, deviceState);
      updateDevice(device.id, deviceState);
    },
    [optimisticlyUpdateDevice, updateDevice],
  );

  const onPressMode = useCallback(
    mode => {
      if (!paywall) {
        toggleMode(mode);
      } else if (admin) {
        history.push(`/subscription`);
      }
    },
    [admin, history, paywall, toggleMode],
  );

  const onLongPressMode = useCallback(
    mode => {
      if (admin) {
        if (paywall) {
          history.push(`/subscription`);
        } else {
          history.push(`/modes/${mode.id}`);
        }
      }
    },
    [admin, history, paywall],
  );

  const onPressCamera = useCallback(
    camera => {
      if (admin) {
        history.push(`/cameras/${camera.id}`);
      }
      return;
    },
    [admin, history],
  );

  const setupMonitoring = useCallback(() => history.push(`/monitoring`), [history]);

  const onAddMode = useCallback(() => {
    const {id: location_id} = location;

    if (paywall) {
      history.push(`/subscription`);
    } else {
      addMode(location_id).then(mode => onLongPressMode(mode));
    }
  }, [addMode, history, location, onLongPressMode, paywall]);

  const onSiren = useCallback(() => setSiren(location.hub_id, false), [
    location.hub_id,
    setSiren,
  ]);

  return useMemo(() => (
    <FileOverride namespace="routes" filename="Home">
      {Component => (
        <Component
          admin={admin}
          authorization={authorization}
          hostName={HOST_NAME}
          location={location}
          onAddMode={onAddMode}
          onPressCamera={onPressCamera}
          onPressDevice={onPressDevice}
          onPressMode={onPressMode}
          onLongPressMode={onLongPressMode}
          onSiren={onSiren}
          optimistic={optimistic}
          paywall={paywall}
          setupMonitoring={setupMonitoring}
        />
      )}
    </FileOverride>
  ), [admin, authorization, location, onAddMode, onLongPressMode, onPressCamera, onPressDevice, onPressMode, onSiren, optimistic, paywall, setupMonitoring]);
}

export default Home;
