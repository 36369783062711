import dealer from './dealer';
import location from './location';
import member from './member';
import optimistic from './optimistic';
import pusher from './pusher';

const createReducers = () => ({
  dealer,
  location,
  member,
  optimistic,
  pusher,
});

export {location, member};

export default createReducers();
