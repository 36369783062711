import React, {useCallback, useMemo, useRef} from 'react';

import {Field, Form, Formik} from 'formik';
import {Switch} from 'formik-material-ui';

import {HeadlineText, HeaderText} from '../../elements';
import LockSettings from '../LockSettings';

function DoorLockSettings(props) {
  const {
    device,
    env,
    modes,
    onChange,
    onChangeValue,
    paywall = false,
    setValue,
    setTouched,
    value,
  } = props;
  const {state, disarm_on_unlock, arm_on_lock, auto_lock} = value;

  const handleSubmit = useRef(null);

  const fields = useMemo(
    () => [
      {
        // handle state changes without form
        name: 'state',
        component: inputProps => (
          <Switch
            {...inputProps}
            disabled={paywall || device.isOffline()}
            onSwitch={on => onChange({state: on ? 'lock' : 'unlock'})}>
            <HeadlineText i18nKey="Device:device.lock">
              Lock
            </HeadlineText>
          </Switch>
        ),
      },
      {
        name: 'text',
        interactive: false,
        component: (
          <HeaderText i18nKey="Device:device.optional_settings">
            Optional Device Settings:
          </HeaderText>
        ),
      },
      {
        name: 'settings',
        component: ({setValue, setTouched, ...rest}) => (
          <LockSettings
            {...rest}
            device={device}
            env={env}
            modes={modes}
            onChange={data => {
              setTouched(true);
              setValue(data);
              handleSubmit.current();
            }}
          />
        ),
      },
    ],
    [device, env, modes, onChange, paywall],
  );

  const initialValues = useMemo(
    () => ({
      state,
      settings: {
        disarm_on_unlock,
        arm_on_lock,
        auto_lock,
      },
    }),
    [arm_on_lock, auto_lock, disarm_on_unlock, state],
  );

  const onSubmit = useCallback(
    ({settings}) => {
      setTouched(true);
      setValue(settings);
      onChangeValue(settings);
    },
    [onChangeValue, setTouched, setValue],
  );

  const renderSubmit = useCallback(({onPress}) => {
    handleSubmit.current = onPress;
    return null;
  }, []);

  return useMemo(
    () => (
      <Form
        fields={fields}
        initialValues={initialValues}
        onSubmit={onSubmit}
        renderSubmit={renderSubmit}
        unsetSubmittingTimeout={0}
      />
    ),
    [fields, initialValues, onSubmit, renderSubmit],
  );
}

DoorLockSettings.fieldName = 'door_lock_field';

DoorLockSettings.getFieldProps = ({
  device,
  env,
  modes,
  onChange,
  onChangeValue,
  paywall,
  styles,
}) => ({
  device,
  env,
  modes,
  onChange,
  onChangeValue,
  paywall,
  styles,
});

DoorLockSettings.populateInitialValues = device => {
  const {desired = {}} = device || {};
  const {lock} = desired || {};
  const {disarm_on_unlock = false, arm_on_lock = false, auto_lock = null} =
    lock || {};
  return {
    state: device.isLocked(),
    disarm_on_unlock,
    arm_on_lock,
    auto_lock,
  };
};

export default DoorLockSettings;
