import React, {useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import BackButton from '@material-ui/icons/ArrowBack';

import * as actions from '../actions/location';

import useActions from '../hooks/useActions';
import useLeftButton from '../hooks/useLeftButton';
import useLocation from '../hooks/useLocation';
import useRequireAdmin from '../hooks/useRequireAdmin';
import useTitle from '../hooks/useTitle';

import Component from '../routes/HubSettings';


function HubSettings() {
  const history = useHistory();
  const {t} = useTranslation('HubSettings');
  const {setChirp, setSiren, setZwaveMode, updateHub} = useActions(actions);
  const location = useLocation();
  const {hub} = location;

  const onChirp = useCallback(chirp => setChirp(hub.id, chirp), [
    hub.id,
    setChirp,
  ]);

  const onSiren = useCallback(siren => setSiren(hub.id, siren), [
    hub.id,
    setSiren,
  ]);

  const onChange = useCallback(data => updateHub(hub.id, data), [
    hub.id,
    updateHub,
  ]);

  const onZwaveMode = useCallback(mode => setZwaveMode(hub.id, mode), [
    hub.id,
    setZwaveMode,
  ]);

  useTitle(t('HubSettings:HubSettings'));
  useLeftButton(BackButton, history.goBack);
  useRequireAdmin();

  return useMemo(
    () => (
      <Component
        cellularEnabled={location.plan !== 'free'}
        hub={hub}
        onChange={onChange}
        onChirp={onChirp}
        onSiren={onSiren}
        onZwaveMode={onZwaveMode}
      />
    ),
    [hub, location.plan, onChange, onChirp, onSiren, onZwaveMode],
  );
}

export default HubSettings;
