import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Field, Form, Formik} from 'formik';
import {FormControl} from '@material-ui/core';

import AuthHeader from '../components/AuthHeader';
import {PasswordInput} from '../components/form';

import Button from '../elements/Button';

import useConfig from '../hooks/useConfig';

function ResetPassword({
  headerComponent: Header,
  onSubmit,
}) {
  const {t} = useTranslation('ResetPassword');
  const {vendorName} = useConfig();
  return useMemo(
    () => (
      <Formik
        initialValues={{
          new_password: '',
          new_password_confirmation: '',
        }}
        validate={() => {}}
        onSubmit={onSubmit}>
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Header vendorName={vendorName} />
            <br />
            <FormControl fullWidth>
              <Field
                component={PasswordInput}
                name="new_password"
                autoComplete="new-password"
                placeholder={t('placeholders.new_password')} />
              <br />
              <Field
                component={PasswordInput}
                name="new_password_confirmation"
                autoComplete="new-password"
                placeholder={t('placeholders.new_password_confirmation')} />
              <br />
              <Button
                variant="contained"
                color="primary"
                i18nKey={
                  isSubmitting ? 'Common:common.saving' : 'Common:common.save'
                }
                onClick={submitForm}
                disabled={isSubmitting}>
                {isSubmitting ? 'saving...' : 'save'}
              </Button>
            </FormControl>
          </Form>
        )}
      </Formik>
    ),
    [onSubmit, t, vendorName],
  );
}

ResetPassword.defaultProps = {
  headerComponent: AuthHeader,
};

export default ResetPassword;
