export default [
  {
    name: 'Apartment or Condo',
    value: 'A',
  },
  {
    name: 'Business',
    value: 'B',
  },
  {
    name: 'Single-family',
    value: 'S',
  },
  {
    name: 'Multi-family',
    value: 'M',
  },
];
