import createTextComponent from './createTextComponent';

const InfoText = createTextComponent('InfoText', 'caption');

InfoText.defaultProps = {
  // marginTop: Spacing.FORM_INPUT_SPACING,
  // marginBottom: Spacing.FORM_INPUT_SPACING,
  // color: 'secondary',
};

export default InfoText;
