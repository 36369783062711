import React, {useCallback, useMemo} from 'react';

import {HeaderText} from '../../elements';
import Component from '../TakeoverSettings';

function TakeoverSettings(props) {
  const {onChangeValue, value, setValue, setTouched, device} = props;
  const onChange = useCallback(
    inputs => {
      setTouched(true);
      setValue({inputs});
      onChangeValue({inputs});
    },
    [onChangeValue, setTouched, setValue],
  );

  return useMemo(
    () => (
      <>
        <HeaderText i18nKey="Device:device.optional_settings">
          Optional Device Settings:
        </HeaderText>
        <Component value={value} device={device} onChange={onChange} />
      </>
    ),
    [device, onChange, value],
  );
}

TakeoverSettings.fieldName = 'takeover_field';

TakeoverSettings.getFieldProps = ({device, onChangeValue, styles}) => ({
  device,
  onChangeValue,
  styles,
});

TakeoverSettings.populateInitialValues = device => {
  const {desired = {}} = device || {};
  let {inputs} = desired || {};
  inputs = inputs || new Array(16).fill();
  return {inputs};
};

export default TakeoverSettings;
