import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import {Box, Container} from '@material-ui/core';
import {ThemeProvider} from '@material-ui/core/styles';

import getDashboardNavigator from './dashboard';

import Loading from '../components/Loading';

import {ForgotPassword, Login, ResetPassword, Signup} from '../containers';

import useConfig from '../hooks/useConfig';

import getTheme from '../lib/darkTheme';

export default () => {
  const selector = state => ({
    loggedIn: state.member.loggedIn,
    rehydrated: state.member._persist.rehydrated,
  });

  function App() {
    const {getColor} = useConfig();
    const [ready, setReady] = useState(false);
    const {loggedIn, rehydrated} = useSelector(selector);
    const theme = useMemo(getTheme, []);
    useEffect(() => {
      if (rehydrated && !loggedIn) {
        setReady(true);
      }
    }, [loggedIn, rehydrated]);
    const Dashboard = useMemo(getDashboardNavigator, []);
    return useMemo(() => {
      if(ready && !loggedIn)
        return (
          <ThemeProvider theme={theme}>
            <Box bgcolor={getColor('darkBackground')} height="100vh" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Container maxWidth="sm">
                <Switch>
                  <Route exact path="/">
                    <Login />
                  </Route>
                  <Route path="/forgot_password">
                    <ForgotPassword />
                  </Route>
                  <Route path="/reset_password/:token">
                    <ResetPassword />
                  </Route>
                  <Route path="/signup">
                    <Signup />
                  </Route>
                  <Route path="*">
                    <Redirect to="/" />
                  </Route>
                </Switch>
              </Container>
            </Box>
          </ThemeProvider>
        );
      if (loggedIn) {
        return <Dashboard />;
      }
      return <Loading splash />;
    }, [getColor, loggedIn, ready, theme]);
  }

  return App;
};
