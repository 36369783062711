import React, {useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import BackButton from '@material-ui/icons/ArrowBack';

import * as actions from '../actions/location';

import useActions from '../hooks/useActions';
import useInstallMode from '../hooks/useInstallMode';
import useLeftButton from '../hooks/useLeftButton';
import useLocation from '../hooks/useLocation';
import usePusher from '../hooks/usePusher';
import useRequireAdmin from '../hooks/useRequireAdmin';
import useSetState from '../hooks/useSetState';
import useTimeout from '../hooks/useTimeout';
import useTitle from '../hooks/useTitle';

import Component from '../routes/AddRfid';

const onError = err => alert(err.error || err.message || err);

function AddRfid() {
  const history = useHistory();
  const {t} = useTranslation('AddRfid');
  const {addRfid} = useActions(actions);
  const location = useLocation();
  const {is_setup, modes, rfids} = location;
  const [setFound, clearFound] = useTimeout();

  const initialState = useMemo(() => {
    let initial_mode_id = null;
    if ((modes || []).length > 0) {
      for (let i = 0; i < modes.length; i++) {
        if (modes[i].name.toLowerCase() === 'away') {
          initial_mode_id = modes[i].id;
          break;
        }
      }
      if (!initial_mode_id) {
        initial_mode_id = modes[0].id;
      }
    }
    return {initial_mode_id};
  }, [modes]);

  const [state, setState] = useSetState(initialState);

  const onPush = useCallback(
    (event, {token}) => {
      const rfid = (rfids || []).find(rfid => rfid.token === token);
      if (rfid) {
        clearFound();
        setState({found: rfid.name});
        setFound(() => setState({found: null}), 1500);
      } else {
        setState({token});
      }
    },
    [clearFound, rfids, setFound, setState],
  );

  const onSubmit = useCallback(
    ({name, mode_id, active, schedule}, {setSubmitting}) => {
      const {token} = state;
      if (!name) {
        setSubmitting(false);
        return onError(t('Please enter a name for the fob.'));
      }

      return addRfid({location_id: location.id, token, name, mode_id, active, schedule})
        .then(() => {
          setSubmitting(false);
          setState({token: null});
        })
        .catch(err => {
          setSubmitting(false);
          onError(err);
        });
    },
    [addRfid, location.id, setState, state, t],
  );

  useInstallMode(location.id);
  useLeftButton(BackButton, history.goBack);
  usePusher(location.id, 'rfid', onPush);
  useRequireAdmin();
  useTitle(t('AddRfid:AddRfid'));

  return useMemo(
    () => (
      <Component
        is_setup={is_setup}
        modes={modes}
        onSubmit={onSubmit}
        onFinish={history.goBack}
        {...state}
      />
    ),
    [history.goBack, is_setup, modes, onSubmit, state],
  );
}

export default AddRfid;
