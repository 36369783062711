import React from 'react';
import {useTranslation} from 'react-i18next';

import {fieldToSelect} from 'formik-material-ui';

import {MenuItem, TextField} from '@material-ui/core';

import Overrides from '../../constants/Overrides';
import StructureTypes from '../../constants/StructureTypes';

function StructureTypePicker(props) {
  const {t} = useTranslation('StructureType');
  return (
    <TextField id={props.field.name} {...fieldToSelect(props)} select>
      {StructureTypes.map(({value}, i) => (
        <MenuItem key={i} value={value}>{t(value)}</MenuItem>
      ))}
    </TextField>
  );
}

StructureTypePicker.getInitialValue = ({structure_type} = {}, fallback) =>
  structure_type ||
  fallback ||
  Overrides.get('vendor', 'default_structure_type', 'S');

export default StructureTypePicker;
