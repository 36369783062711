import React, {useMemo} from 'react';

import clsx from 'clsx';

import PaywallIcon from '@material-ui/icons/Help';

import {
  Alarmed as AlarmedIcon,
  Armed as ArmedIcon,
  Disarmed as DisarmedIcon,
} from '@scout-app/assets/icons';

import Colors from '../../constants/Colors';

import LongPressable from '../LongPressable';

import Text from '../../elements/Text';

import useStyleSheet from '../../hooks/useStyleSheet';

const styleSheet = (theme) => ({
  container: {
    display: 'flex',
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.get('disarmed'),
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
  },
  alarmedContainer: {
    backgroundColor: Colors.get('alarmed'),
  },
  armedContainer: {
    backgroundColor: Colors.get('armed'),
  },
  armingContainer: {
    backgroundColor: Colors.get('armed'),
  },
  disarmedContainer: {
  },
  paywallContainer: {
  },
  triggeredContainer: {
    backgroundColor: Colors.get('armed'),
  },

  name: {
    flex: 1,
    fontSize: 18,
    color: '#fff',
  },
  alarmedName: {
  },
  armedName: {
  },
  armingName: {
  },
  disarmedName: {
    color: Colors.get('primaryText'),
  },
  paywallName: {
    color: Colors.get('primaryText'),
  },
  triggeredName: {
  },

  iconContainer: {
    width: 36,
    height: 36,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 18,
    backgroundColor: '#fff',
    overflow: 'hidden',
  },
  alarmedIconContainer: {
  },
  armedIconContainer: {
  },
  armingIconContainer: {
  },
  disarmedIconContainer: {
    backgroundColor: '#d0d0d0',
  },
  paywallIconContainer: {
    backgroundColor: 'transparent',
  },
  triggeredIconContainer: {
  },

  icon: {
    fontSize: 36,
    color: '#fff',
  },
  alarmedIcon: {
    fontSize: 32,
    color: Colors.get('alarmed'),
  },
  armedIcon: {
    color: Colors.get('armed'),
  },
  armingIcon: {
    color: Colors.get('armed'),
  },
  disarmedIcon: {
    backgroundColor: '#d0d0d0',
  },
  paywallIcon: {
    color: Colors.get('primaryText'),
  },
  triggeredIcon: {
    fontSize: 32,
    color: Colors.get('armed'),
  },
});

const ICONS = {
  alarmed: AlarmedIcon,
  armed: ArmedIcon,
  arming: DisarmedIcon,
  disarmed: DisarmedIcon,
  paywall: PaywallIcon,
  triggered: AlarmedIcon,
};

function getModeState(state, paywall) {
  if(paywall)
    return {paywall};
  return {[state]: true};
};

function Mode({mode, paywall = false, onPress, onLongPress}) {
  const classes = useStyleSheet(styleSheet);
  const {
    alarmed,
    armed,
    arming,
    disarmed,
    triggered,
  } = useMemo(() => getModeState(mode.state || 'disarmed', paywall), [mode.state, paywall]);
  const Icon = useMemo(() => ICONS[paywall ? 'paywall' : mode.state] || ICONS.paywall, [mode.state, paywall]);
  return useMemo(
    () => (
      <LongPressable
        onClick={() => onPress(mode)}
        onLongPress={() => onLongPress(mode)}
        className={clsx(classes.container, {
          [classes.alarmedContainer]: alarmed,
          [classes.armedContainer]: armed,
          [classes.armingContainer]: arming,
          [classes.disarmedContainer]: disarmed,
          [classes.paywallContainer]: paywall,
          [classes.triggeredContainer]: triggered,
        })}>
        <Text className={clsx(classes.name, {
          [classes.alarmedName]: alarmed,
          [classes.armedName]: armed,
          [classes.armingName]: arming,
          [classes.disarmedName]: disarmed,
          [classes.paywallName]: paywall,
          [classes.triggeredName]: triggered,
        })}>{mode.name}</Text>
        <div className={clsx(classes.iconContainer, {
          [classes.alarmedIconContainer]: alarmed,
          [classes.armedIconContainer]: armed,
          [classes.armingIconContainer]: arming,
          [classes.disarmedIconContainer]: disarmed,
          [classes.paywallIconContainer]: paywall,
          [classes.triggeredIconContainer]: triggered,
        })}>
          <Icon className={clsx(classes.icon, {
            [classes.alarmedIcon]: alarmed,
            [classes.armedIcon]: armed,
            [classes.armingIcon]: arming,
            [classes.disarmedIcon]: disarmed,
            [classes.paywallIcon]: paywall,
            [classes.triggeredIcon]: triggered,
          })} />
        </div>
      </LongPressable>
    ),
    [alarmed, armed, arming, classes.alarmedContainer, classes.alarmedIcon, classes.alarmedIconContainer, classes.alarmedName, classes.armedContainer, classes.armedIcon, classes.armedIconContainer, classes.armedName, classes.armingContainer, classes.armingIcon, classes.armingIconContainer, classes.armingName, classes.container, classes.disarmedContainer, classes.disarmedIcon, classes.disarmedIconContainer, classes.disarmedName, classes.icon, classes.iconContainer, classes.name, classes.paywallContainer, classes.paywallIcon, classes.paywallIconContainer, classes.paywallName, classes.triggeredContainer, classes.triggeredIcon, classes.triggeredIconContainer, classes.triggeredName, disarmed, mode, onLongPress, onPress, paywall, triggered],
  );
};

export default Mode;
