import {useMemo} from 'react';
import {bindActionCreators} from 'redux';
import {useDispatch} from 'react-redux';

export default function useActions(actions) {
  const dispatch = useDispatch();
  return useMemo(() => {
    if (Array.isArray(actions)) {
      return actions.map(group => bindActionCreators(group, dispatch));
    }
    return bindActionCreators(actions, dispatch);
  }, [actions, dispatch]);
}
