import React, {useMemo} from 'react';

import clsx from 'clsx';

import Activity from '../../lib/Activity';

function ActivityIcon({classes, hasPreview, index, item, siblings}) {
  const [
    topConnected,
    bottomConnected,
    icon,
  ] = useMemo(() => [
     Activity.isTopConnected(item, siblings, index),
     Activity.isBottomConnected(item, siblings, index),
     Activity.getIcon(item),
  ], [index, item, siblings]);
  const [
    top,
    bottom,
  ] = useMemo(() => [
    <div className={clsx(classes.connector, {
      [classes.connected]: topConnected,
      [classes.top]: hasPreview,
    })} />,
    <div className={clsx(classes.connector, {
      [classes.connected]: bottomConnected
    })} />,
  ], [bottomConnected, classes.connected, classes.connector, classes.top, hasPreview, topConnected]);
  return useMemo(() => (
    <div className={classes.iconContainer}>
      {top}
      <div className={classes.icon}>{icon}</div>
      {bottom}
    </div>
  ), [bottom, classes.icon, classes.iconContainer, icon, top]);
}

export default ActivityIcon;
