import {useCallback, useEffect, useRef, useState} from 'react';

import usePusher from './usePusher';

export default function useRfidSwipe(location_id) {
  const [rfidState, setRfidState] = useState('off');
  const timeoutRef = useRef(null);

  const onSwipe = useCallback(() => {
    setRfidState('on');
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setRfidState('off');
    }, 1000);
  }, []);

  useEffect(
    () => {
      return () => {
        clearTimeout(timeoutRef.current);
      };
    },
    [],
  );

  usePusher(location_id, 'rfid', onSwipe);

  return rfidState;
}
