import Pusher from 'pusher-js';

import {
  CHANNEL_SUBSCRIBED,
  CHANNEL_UNSUBSCRIBED,
  LISTENER_ADDED,
  LISTENER_REMOVED,
  PUSHER_STATUS,
} from './ActionTypes';

import {
  getActivity,
  getDevices,
  getEnvironmental,
  setCamera,
  setDevice,
  setLocationState,
  setModeState,
  setHub,
  removeDevice,
} from './location';

import {clearOptimisticlyUpdate} from './optimistic';

import {HOST_NAME, PUSHER_CLUSTER, PUSHER_KEY} from '../app.json';

const DEBUG = true;

var _client;
const defaultCallback = (event, data, dispatch, getState) => {
  if (DEBUG) {
    console.log(event, JSON.stringify(data));
  }
};

const callbacks = {
  location: (event, data, dispatch, getState) => {
    const {location} = getState();
    let updateActivity = false;
    if (DEBUG) {
      console.log(event, JSON.stringify(data));
    }
    if (!location.id) {
      return;
    }
    if (event === 'device') {
      updateActivity = !!data.event;
      if (data.event === 'paired') {
        dispatch(getDevices(location.id));
      } else if (data.event === 'unpaired') {
        dispatch(removeDevice(data.id));
      } else if (['alarmed', 'dismissed'].indexOf(data.event) > -1) {
        dispatch(getEnvironmental(location.id));
      } else if (data.event === 'triggered') {
        dispatch(clearOptimisticlyUpdate(data.id, data.type));
        dispatch(setDevice(data, true));
        updateActivity = data.type !== 'motion_sensor';
      }
    } else if (event === 'mode') {
      if (data.event === 'dismissed') {
        data.event = 'disarmed';
      }
      if (data.event) {
        dispatch(setModeState(data.mode_id, data.event));
        updateActivity = data.event !== 'arming';
      }
    } else if (event === 'hub') {
      if (data.event === 'unpaired') {
        dispatch(setHub(null));
      } else {
        dispatch(setHub(data));
      }
      updateActivity = !!data.event;
    } else if (event === 'camera') {
      if (['connected', 'disconnected'].indexOf(data.event) > -1) {
        dispatch(setCamera(data));
      }
      updateActivity = !!data.event;
    } else if (event === 'member') {
      if (data.event === 'panic') {
        dispatch(
          setLocationState({
            alarmed: 'panic',
          }),
        );
      }
      updateActivity = !!data.event;
    } else if (event === 'integration') {
      updateActivity = !!data.event;
      if (['alarmed', 'dismissed'].indexOf(data.event) > -1) {
        dispatch(getEnvironmental(location.id));
      }
    }
    if (updateActivity) {
      dispatch(getActivity(location.id));
    }
  },
  mode: defaultCallback,
  device: defaultCallback,
  rfid: defaultCallback,
  hub: defaultCallback,
  camera: defaultCallback,
};

const setStatus = status => dispatch =>
  dispatch({
    type: PUSHER_STATUS,
    status,
  });

export const setClient = client => (_client = client);

const getClient = () => (dispatch, getState) => {
  if (!_client) {
    const {member} = getState();
    _client = new Pusher(PUSHER_KEY, {
      authEndpoint: `${HOST_NAME}/auth/pusher`,
      auth: {
        headers: {
          Authorization: member.token,
        },
      },
      cluster: PUSHER_CLUSTER,
      forceTLS: !DEBUG,
    });
    _client.connection.bind('state_change', ({current}) =>
      dispatch(setStatus(current)),
    );
  }
  return Promise.resolve(_client);
};

const addSubscription = (location_id, subscription) => dispatch =>
  dispatch({
    type: CHANNEL_SUBSCRIBED,
    location_id,
    subscription,
  });

const removeSubscription = location_id => dispatch =>
  dispatch({
    type: CHANNEL_UNSUBSCRIBED,
    location_id,
  });

const addListener = (location_id, channel, callback) => dispatch =>
  dispatch({
    type: LISTENER_ADDED,
    location_id,
    channel,
    callback,
  });

const removeListener = (location_id, channel, index) => dispatch =>
  dispatch({
    type: LISTENER_REMOVED,
    location_id,
    channel,
    index,
  });

export const subscribe = (location_id, trigger, cb) => (dispatch, getState) =>
  dispatch(getClient()).then(client => {
    let done = null;
    const promise = new Promise(resolve => (done = resolve));
    if (typeof trigger === 'function') {
      const temp = cb;
      cb = trigger;
      trigger = temp;
    }
    const channel = [location_id, trigger].filter(part => !!part).join('/');
    let {subscription} = getState().pusher;
    const subscribed = !!subscription;
    if (!subscribed) {
      subscription = {
        socket: client.subscribe(`private-${location_id}`),
        listeners: 0,
      };
      dispatch(addSubscription(location_id, subscription));
    }
    let listeners = getState().pusher.listeners[channel];
    if (!listeners) {
      const callback = (event, data) => {
        if (!subscribed && event === 'pusher:subscription_succeeded') {
          done(data);
        }
        (getState().pusher.listeners[channel] || []).forEach(listener =>
          listener(event, data, dispatch, getState),
        );
      };
      listeners = [];
      if (trigger) {
        subscription.socket.bind(trigger, callback.bind(this, trigger));
      } else {
        subscription.socket.bind_global(callback);
      }
    }
    if (!cb) {
      cb = callbacks[trigger || 'location'];
    }
    const index = listeners.indexOf(cb);
    if (index < 0) {
      dispatch(addListener(location_id, channel, cb));
    }
    if (subscribed) {
      done();
    }
    return promise;
  });

export const unsubscribe = (location_id, trigger, cb) => (dispatch, getState) =>
  dispatch(getClient()).then(client => {
    if (typeof trigger === 'function') {
      const temp = cb;
      cb = trigger;
      trigger = temp;
    }
    const channel = [location_id, trigger].filter(part => !!part).join('/');
    const {subscription} = getState().pusher;
    const listeners = getState().pusher.listeners[channel];
    if (subscription) {
      if (listeners) {
        if (!cb) {
          cb = callbacks[trigger || 'location'];
        }
        const index = listeners.indexOf(cb);
        if (index > -1) {
          if (listeners.length === 1) {
            if (trigger) {
              subscription.socket.unbind(trigger);
            } else {
              subscription.socket.unbind_global();
            }
          }
          dispatch(removeListener(location_id, channel, index));
        }
      }
      if (!getState().pusher.subscription.listeners) {
        client.unsubscribe(`private-${location_id}`);
        dispatch(removeSubscription(location_id));
      }
    }
  });
