import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Container, IconButton, Switch} from '@material-ui/core';

import Images from '../constants/Images';

import BoldText from '../elements/BoldText';
import Button from '../elements/Button';
import HeadlineText from '../elements/HeadlineText';
import Text from '../elements/Text';

import useStyleSheet from '../hooks/useStyleSheet';

const styleSheet = (theme) => ({
  container: {
    padding: 24,
  },
  centered: {
    textAlign: 'center',
  },
  image: {
    width: '100%',
    maxWidth: 320,
    marginTop: '-10%',
    marginBottom: '-10%',
  },
  detail: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 3,
    marginBottom: 3,
  },
  name: {
    flex: 1,
  },
  value: {
    textAlign: 'right',
  },
  buttons: {
    marginRight: theme.spacing(1),
  },
  option: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

function HubSettings({
  cellularEnabled,
  hub,
  onChange,
  onChirp,
  onSiren,
  onZwaveMode,
}) {
  const {t} = useTranslation('Hub');
  const classes = useStyleSheet(styleSheet);

  const renderItem = useCallback(
    ({i18nKey, name, value}, i) => {
      if (value) {
        return (
          <div key={i} className={classes.detail}>
            <Text i18nKey={i18nKey}>{name}</Text>
            <Text className={classes.value}>{value}</Text>
          </div>
        );
      }
    },
    [classes.detail, classes.value],
  );

  const renderSection = useCallback(
    ({i18nKey, title}, items) => (
      <>
        <HeadlineText i18nKey={i18nKey}>{title}:</HeadlineText>
        {items.map(renderItem)}
      </>
    ),
    [renderItem],
  );

  return useMemo(
    () => (
      <Container maxWidth="md" className={classes.container}>
        <div className={classes.centered}>
          <img
            className={classes.image}
            alt=""
            src={Images.get(
              'DEVICES',
              hub.isSercomm() ? 'SERCOMM.HUB' : 'HUB',
            )}
          />
        </div>
        {renderSection(
          {
            title: 'Details',
            i18nKey: 'HubSettings:Details',
          },
          [
            {
              name: 'Status',
              i18nKey: 'HubSettings:Status',
              value: t(hub.state),
            },
            {
              name: 'Power',
              i18nKey: 'HubSettings:Power',
              value: t(hub.power),
            },
            {
              name: 'Firmware Version',
              i18nKey: 'HubSettings:FirmwareVersion',

              value: hub.fw_version,
            },
            {
              name: 'Serial Number',
              i18nKey: 'HubSettings:SerialNumber',

              value: hub.serial_number,
            },
            {
              name: 'MAC Address',
              i18nKey: 'HubSettings:MACAddress',
              value: hub.mac_address,
            },
          ],
        )}
        {renderSection(
          {
            title: 'Connection',
            i18nKey: 'HubSettings:Connection',
          },
          [
            {
              name: 'Type',
              i18nKey: 'HubSettings:Type',
              value: t(hub.connection),
            },
            {
              name: 'IP Address',
              i18nKey: 'HubSettings:IPAddress',
              value: hub.remote_ip,
            },
            {
              name: 'Port',
              i18nKey: 'HubSettings:Port',
              value: hub.remote_port,
            },
          ],
        )}
        {cellularEnabled &&
          renderSection(
            {
              title: 'CellularDetails',
              i18nKey: 'HubSettings:CellularDetails',
            },
            [
              {
                name: 'Carrier',
                i18nKey: 'HubSettings:Carrier',
                value: hub.cellular_carrier,
              },
              {
                name: 'Signal Strength',
                i18nKey: 'HubSettings:SignalStrength',
                value: t(hub.cellular_strength),
              },
            ],
        )}
        <div className={classes.detail}>
          <BoldText i18nKey="HubSettings:arming_chirps">
            Arming Chirps:
          </BoldText>
          <Switch
            checked={hub.chirps}
            disabled={hub.isOffline()}
            onChange={chirps => onChange({chirps})}
            name="chirps" />
        </div>
        {hub.isZwave() && (
          <div className={classes.detail}>
            <BoldText i18nKey="HubSettings:z_wave_settings">
              Z-Wave Settings:
            </BoldText>
            <Button
              i18nKey="HubSettings:exclusion"
              disabled={hub.isOffline()}
              onClick={() => onZwaveMode('exclusion')}>
              Exclusion
            </Button>
          </div>
        )}
        <HeadlineText i18nKey="HubSettings:test_device">
          Test this Device:
        </HeadlineText>
        <Text i18nKey="HubSettings:test_instructions">
          Using the icons below, you can test the features of this device in
          real-time. The system will be disarmed during testing.
        </Text>
        <div className={classes.detail}>
          <BoldText i18nKey="HubSettings:chirp_test">
            Chirp Test:
          </BoldText>
          <div className={classes.buttons}>
            <IconButton
              size="small"
              disabled={hub.isOffline()}
              onClick={() => onChirp('single')}>
              1X
            </IconButton>
          </div>
        </div>
        <div className={classes.detail}>
          <BoldText i18nKey="HubSettings:siren_test">
            Siren Test:
          </BoldText>
          <Switch
            checked={hub.siren}
            disabled={hub.isOffline()}
            onChange={onSiren}
            name="siren" />
        </div>
      </Container>
    ),
    [cellularEnabled, classes.buttons, classes.centered, classes.container, classes.detail, classes.image, hub, onChange, onChirp, onSiren, onZwaveMode, renderSection, t],
  );
}

export default HubSettings;
