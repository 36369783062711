import colors from './colors';
import components from './components';
import elements from './elements';
import images from './images';
import translations from './translations';
import vendor from './vendor';

export default {
  colors,
  components,
  defaultLanguage: 'es',
  forceLanguage: 'es',
  elements,
  integrations: [],
  images,
  translations,
  vendor,
};
