import React, {useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import * as actions from '../actions/member';
import useActions from '../hooks/useActions';

import Component from '../routes/ForgotPassword';

function ForgotPassword() {
  const history = useHistory();
  const {t} = useTranslation('ForgotPassword');
  const {forgotPassword} = useActions(actions);
  const onSubmit = useCallback(
    email => {
      if (!email) {
        alert(t('MissingEmail'));
        return Promise.reject();
      }
      return forgotPassword(email);
    },
    [forgotPassword, t],
  );
  return useMemo(
    () => <Component onBack={() => history.goBack()} onSubmit={onSubmit} />,
    [history, onSubmit],
  );
}

export default ForgotPassword;
