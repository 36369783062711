import { createMuiTheme } from '@material-ui/core/styles';

import Colors from '../constants/Colors';

export default () =>
  createMuiTheme({
    palette: {
      type: Colors.get('background') === '#fff' ? 'light' : 'dark',
      primary: {
        main: Colors.get('accent'),
      },
      secondary: {
        main: Colors.get('secondaryText'),
      },
      error: {
        main: Colors.get('alarmed'),
      },
      background: {
        default: Colors.get('background'),
      },
    },
  });
