import Isemail from 'isemail';
import {isValidNumber} from 'libphonenumber-js';

export const validateEmail = email => {
  if (!email || !Isemail.validate(email)) {
    return false;
  }

  return true;
};

export const validatePhone = (value, country = 'US') => {
  if (!value || !isValidNumber(value, country)) {
    return false;
  }

  return true;
};

export default {
  validateEmail,
  validatePhone,
};
