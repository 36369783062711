import React, {useMemo} from 'react';

import Spinner from 'react-spinkit';

import Colors from '../constants/Colors';

import Text from '../elements/Text';

import useConfig from '../hooks/useConfig';
import useStyleSheet from '../hooks/useStyleSheet';

const styleSheet = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: Colors.get('secondaryText'),
    fontSize: 12,
    marginLeft: 5,
    marginRight: theme.spacing(2),
  },
});

function PairingIndicator({
  children,
  i18nKey,
}) {
  const {getColor} = useConfig();
  const classes = useStyleSheet(styleSheet);
  return useMemo(
    () => (
      <div className={classes.container}>
        <Text
          i18nKey={children ? i18nKey : 'pairing:pairing.pairing'}
          className={classes.text}>
          {children || 'Pairing...'}
        </Text>
        <Spinner
          name="circle"
          color={getColor('pairingIndicator')}
        />
      </div>
    ),
    [children, classes.container, classes.text, getColor, i18nKey],
  );
}

export default PairingIndicator;
