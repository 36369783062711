const name = 'Qido';
const app_id = 'qido';

const address_fields = [
  'street',
  'number',
  'type',
  'floor',
  'door',
  'complement',
];
const core_devices = ['access_sensor', 'motion_sensor', 'remote_control'];
const default_country = 'PE';
const default_currency = 'PEN';
const default_currency_symbol = 'S/';
const default_document_type = 'DNI';
const default_hub = 'sercomm';
const default_state = null;
const default_timezone = 'America/Lima';
const document_types = ['CE', 'DNI', 'RUC'];
const enabled_devices = (type, model) => {
  if (type === 'camera') {
    return model === 'rc8510a';
  }
  return ['access_sensor', 'motion_sensor', 'remote_control'].includes(type);
};
const free_camera_subscriptions = 999; // 5
const hub_pairing_mode = 'install_code';
const initial_devices = ['hub']; // disallow camera-only for now
const monitoring_fields = [
  'name',
  'phone',
  'secondary_name',
  'alternative_phone',
];
const privacy_uri = '/privacy-policy.pdf';
const ssid_prefix = 'Scout';
const ssid_regexp = /^(QIDO|Scout)-[a-f0-9]{6}$/;
const structure_types = ['A', 'B', 'S']; // skip multi-family
const support_email = 'alarmas.peru@qido.com'; // 'support@qidoalarmas.com';
const support_phone_number = '(01) 513-86-07';
const support_uri = 'https://www.qido.com/faq';
const tos_uri = '/tos.pdf';

export default {
  address_fields,
  allows_name_change: false,
  app_id,
  can_upgrade_plans: false,
  core_devices,
  default_country,
  default_currency,
  default_currency_symbol,
  default_document_type,
  default_hub,
  default_state,
  default_timezone,
  disabled_actions: ['phone_numbers'],
  document_types,
  enabled_devices,
  free_camera_subscriptions,
  has_coupon_codes: true,
  has_monitoring_contract: false,
  has_permits: false,
  has_prepaid_subscriptions: true,
  hub_pairing_mode,
  initial_devices,
  monitoring_fields,
  name,
  privacy_uri,
  required_actions: ['count_down', 'professional_monitoring', 'siren'],
  ssid_prefix,
  ssid_regexp,
  structure_types,
  support_email,
  support_phone_number,
  support_uri,
  test_mode_period: false,
  tos_uri,
  uses_full_name: true,
  uses_monitoring_keys: true,
  uses_pass_codes: false,
};
