import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useConfirm} from 'material-ui-confirm';

import BackButton from '@material-ui/icons/ArrowBack';
import {Delete as DeleteButton} from '@scout-app/assets/icons';

import * as actions from '../actions/location';

import useActions from '../hooks/useActions';
import useDevice from '../hooks/useDevice';
import useLeftButton from '../hooks/useLeftButton';
import useLocation from '../hooks/useLocation';
import useRequireAdmin from '../hooks/useRequireAdmin';
import useRightButton from '../hooks/useRightButton';
import useTitle from '../hooks/useTitle';

import Component from '../routes/Device';

const onError = err => alert(err.error || err.message || err);

function Device() {
  const history = useHistory();
  const {t} = useTranslation('Device');
  const {setTestMode, unpairDevice, updateDevice} = useActions(actions);
  const location = useLocation();
  const device = useDevice();
  const confirm = useConfirm();
  const isZwave = useMemo(() => device.isZwave(), [device]);
  const canTest = useMemo(() => device.canTest(), [device]);
  const deviceType = useMemo(() => device.getType(), [device]);
  const [name, setName] = useState(device.name);
  const [exclusion, setExclusion] = useState(false);
  const unpaired = useRef(false);

  const onDelete = useCallback(() => {
    confirm({
      title: t('UnpairDevice'),
      description: t('ConfirmUnpairDevice', {
        device_name: device.name,
        location_name: location.name,
      }),
    }).then(confirmed => {
      if(isZwave) {
        setExclusion(true);
      }
      else {
        history.goBack();
        unpairDevice(device.id).catch(onError);
      }
    })
    .catch(() => {});
  }, [confirm, t, device.name, device.id, location.name, isZwave, history, unpairDevice]);

  const onBack = useCallback(() => {
    if (name && name !== device.name) {
      updateDevice(device.id, {name}).catch(onError);
    }
    history.goBack();
  }, [name, device.name, device.id, history, updateDevice]);

  const onRoute = useCallback(
    (route, props) => {
      history.push(route);
    },
    [history],
  );

  const onChange = useCallback(
    (data, {setSubmitting}) =>
      updateDevice(device.id, data)
        .catch(err => {
          onError(err);
          setSubmitting(false);
        }),
    [device.id, updateDevice],
  );

  const onSubmit = useCallback(
    (data, formik) => {
      const fields = Object.keys(data).reduce((result, key) => ({
        ...result,
        ...(data[key] || {}),
      }), {});
      onChange(fields, formik);
    },
    [onChange],
  );

  useEffect(() => {
    if (canTest) {
      setTestMode(device.id, true);
    }
    return () => {
      if (canTest && !unpaired.current) {
        setTestMode(device.id, false);
      }
    };
  }, [canTest, device.id, setTestMode]);

  useTitle(device?.name);
  useLeftButton(BackButton, onBack);
  useRightButton(DeleteButton, location.member_state === 'admin' ? onDelete : null);
  useRequireAdmin();

  // TODO - handle device exclusion

  return useMemo(
    () => (
      <Component
        canTest={canTest}
        device={device}
        deviceType={deviceType}
        exclusion={exclusion}
        location={location}
        onChange={onChange}
        onRoute={onRoute}
        onSubmit={onSubmit}
        setName={setName} />
    ),
    [canTest, device, deviceType, exclusion, location, onChange, onRoute, onSubmit],
  );
}

export default Device;
