import {useEffect} from 'react';

import useAppBar from './useAppBar';

function useLeftButton(icon, onClick) {
  const {setLeftButton} = useAppBar();
  useEffect(() => {
    setLeftButton({icon, onClick});
    return () => {
      setLeftButton(null);
    }
  }, [icon, onClick, setLeftButton]);
}

export default useLeftButton;
