import {
  CHANNEL_SUBSCRIBED,
  CHANNEL_UNSUBSCRIBED,
  LISTENER_ADDED,
  LISTENER_REMOVED,
  PUSHER_STATUS,
} from '../actions/ActionTypes';

const initialState = {
  location_id: null,
  subscription: null,
  status: 'unavailable',
  listeners: {},
};

export default (state = initialState, action) => {
  let listeners = {};
  let channel_listeners = {};
  switch (action.type) {
    case CHANNEL_SUBSCRIBED:
      return {
        ...state,
        location_id: action.location_id,
        subscription: action.subscription,
      };
    case CHANNEL_UNSUBSCRIBED:
      return {
        ...state,
        location_id: null,
        subscription: null,
      };
    case LISTENER_ADDED:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          listeners: state.subscription.listeners + 1,
        },
        listeners: {
          ...state.listeners,
          [action.channel]: [
            ...(state.listeners[action.channel] || []),
            action.callback,
          ],
        },
      };
    case LISTENER_REMOVED:
      listeners = {...state.listeners};
      channel_listeners = listeners[action.channel] || [];
      if (channel_listeners.length > 1) {
        listeners = {
          ...listeners,
          [action.channel]: [
            ...channel_listeners.slice(0, action.index),
            ...channel_listeners.slice(action.index + 1),
          ],
        };
      } else {
        delete listeners[action.channel];
      }
      return {
        ...state,
        subscription: {
          ...state.subscription,
          listeners: state.subscription.listeners - 1,
        },
        listeners,
      };
    case PUSHER_STATUS:
      return {
        ...state,
        status: action.status,
      };
    default:
      return state;
  }
};
