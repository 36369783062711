import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import useModel from './useModel';

import Rfid from '../models/Rfid';

const getSelector = params => ({location}) => {
  const {rfid_id} = params || {};
  return { rfid: location.rfids.find(({id}) => id === rfid_id) || {} };
};

function useRfid() {
  const params = useParams();
  const selector = useMemo(() => getSelector(params), [params]);
  const {rfid} = useSelector(selector);
  return useModel(rfid, Rfid);
}

export default useRfid;
