/* eslint global-require: 0 */

/*
const ACCESS_SENSOR = {
  OPEN: require('../src/img/devices/access_sensor_open.png'),
  PAIRING: {
    FILLED: require('../src/img/installation/access_sensor/pairing_filled.png'),
  },
  PLACEMENT: require('../src/img/installation/access_sensor/placement.png'),
  ADHESIVE: require('../src/img/installation/access_sensor/adhesive.png'),
  ADHESIVE_SMALL: require('../src/img/installation/access_sensor/adhesive_small.png'),
  LAYOUT_1: require('../src/img/installation/access_sensor/layout_1.png'),
  LAYOUT_2: require('../src/img/installation/access_sensor/layout_2.png'),
  SERCOMM: {
    PAIRING: {
      FILLED: require('../src/img/installation/access_sensor/sercomm_pairing_filled.png'),
    },
    ADHESIVE: require('../src/img/installation/access_sensor/sercomm_adhesive.png'),
    ADHESIVE_SMALL: require('../src/img/installation/access_sensor/sercomm_adhesive_small.png'),
  },
};

const BETA = {
  PAIRING: {
    FILLED: require('../src/img/installation/beta/pairing_filled.png'),
  },
};

const CAMERA = {
  PAIRING: {
    FILLED: require('../src/img/installation/camera/pairing_filled.png'),
    UNFILLED: require('../src/img/installation/camera/pairing_unfilled.png'),
  },
  DESCRIPTION: require('../src/img/installation/camera/description.png'),
  POWER: require('../src/img/installation/camera/power.png'),
  CONNECT_WIFI: require('../src/img/installation/camera/connect_wifi.png'),
  CONNECTION: {
    FILLED: require('../src/img/installation/camera/connection_filled.png'),
    UNFILLED: require('../src/img/installation/camera/connection_unfilled.png'),
  },
  PLACEMENT: require('../src/img/installation/camera/placement.png'),
  RECORDING: require('../src/img/installation/camera/recording.png'),
};

const DEVICES = {
  CAMERA: require('../src/img/devices/camera.png'),
  CAMERA_RECORDING: require('../src/img/devices/camera_recording.png'),
  DOORBELL: require('../src/img/devices/doorbell.png'),
  SERCOMM: {
    HUB: require('../src/img/devices/sercomm_hub.png'),
  },
  KEYPAD: require('../src/img/devices/keypad.png'),
};

const DOORBELL = {
  PAIRING: {
    FILLED: require('../src/img/installation/doorbell/pairing_filled.png'),
    UNFILLED: require('../src/img/installation/doorbell/pairing_unfilled.png'),
  },
  DESCRIPTION: require('../src/img/installation/doorbell/description.png'),
  POWER: require('../src/img/installation/doorbell/power_on.png'),
  CONNECT_WIFI: require('../src/img/installation/doorbell/connect_wifi.png'),
  CONNECTION: {
    FILLED: require('../src/img/installation/doorbell/connection_filled.png'),
    UNFILLED: require('../src/img/installation/doorbell/connection_unfilled.png'),
  },
  WARNING: require('../src/img/installation/doorbell/warning.png'),
  ELECTRICITY: {
    OFF: require('../src/img/installation/doorbell/electricity_off.png'),
    ON: require('../src/img/installation/doorbell/electricity_on.png'),
  },
  REMOVAL: require('../src/img/installation/doorbell/removal.png'),
  PLATE: {
    INSTALL: require('../src/img/installation/doorbell/plate_install.png'),
    CONNECT: require('../src/img/installation/doorbell/plate_connect.png'),
    ATTACH: require('../src/img/installation/doorbell/plate_attach.png'),
  },
  INSTALLED: require('../src/img/installation/doorbell/installed.png'),
  POWER_KIT: {
    DESCRIPTION: require('../src/img/installation/doorbell/power_kit.png'),
    SCREWS: require('../src/img/installation/doorbell/power_kit_screws.png'),
    HELP_1: require('../src/img/installation/doorbell/power_kit_help_1.png'),
    HELP_2: require('../src/img/installation/doorbell/power_kit_help_2.png'),
    INTERIOR: require('../src/img/installation/doorbell/power_kit_interior.png'),
    EXTERIOR: require('../src/img/installation/doorbell/power_kit_exterior.png'),
  },
};

const DOOR_LOCK = {
  KWIKSET: {
    PAIRING: {
      FILLED: require('../src/img/installation/door_lock/kwikset_pairing_filled.png'),
    }
  },
  YALE: {
    PAIRING: {
      FILLED: require('../src/img/installation/door_lock/yale_pairing_filled.png'),
    }
  },
};

const GLASS_BREAK = {
  TESTING: require('../src/img/devices/glass_break_testing.png'),
  SHATTER: require('../src/img/devices/glass_break_shatter.png'),
  GOCONTROL: {
    PAIRING: {
      FILLED: require('../src/img/installation/glass_break/gocontrol_pairing_filled.png'),
    }
  },
  JOHNSON_CONTROLS: {
    PAIRING: {
      FILLED: require('../src/img/installation/glass_break/johnson_controls_paired.png'),
    },
    BATTERIES: {
      CLOSE: require('../src/img/installation/glass_break/johnson_controls_close_cover.png'),
    },
    MOUNTING: require('../src/img/installation/glass_break/johnson_controls_adhesive_remove.png'),
  },
};

const HUB = {
  PULL_TABS: require('../src/img/installation/hub/pull_tab_icon.png'),
  SENSOR_SEQUENCE: require('../src/img/installation/hub/sensor_sequence.png'),
  POWER: require('../src/img/installation/hub/power.png'),
  CONNECT: require('../src/img/installation/hub/connect.png'),
  LIGHTS: require('../src/img/installation/hub/light.png'),
  ENTER_CODE: require('../src/img/installation/hub/enter_code.png'),
  PAIRING: {
    UNFILLED: require('../src/img/installation/hub/pairing_unfilled.png'),
    FILLED: require('../src/img/installation/hub/pairing_filled.png'),
  },
  LIGHT_KEY: require('../src/img/installation/light_key.png'),
  SERCOMM: {
    POWER: require('../src/img/installation/hub/sercomm_power.png'),
    CONNECT: require('../src/img/installation/hub/sercomm_connect.png'),
    LIGHTS: require('../src/img/installation/hub/sercomm_light.png'),
    ENTER_CODE: require('../src/img/installation/hub/sercomm_enter_code.png'),
    PAIRING: {
      UNFILLED: require('../src/img/installation/hub/sercomm_pairing_unfilled.png'),
      FILLED: require('../src/img/installation/hub/sercomm_pairing_filled.png'),
    }
  },
};

const KEY_PAD = {
  PAIRING: {
    FILLED: require('../src/img/installation/key_pad/key_pad_pairing_filled.png'),
  },
  BRACKET: require('../src/img/installation/key_pad/key_pad_bracket.png'),
  MOUNTING: require('../src/img/installation/key_pad/key_pad_mounting.png'),
  TESTING: require('../src/img/installation/key_pad/key_pad_testing.png'),
  RESET: require('../src/img/installation/key_pad/key_pad_reset.png'),
};

const MOTION_SENSOR = {
  MOTION_START: require('../src/img/devices/motion_sensor_motion_start.png'),
  PAIRING: {
    FILLED: require('../src/img/installation/motion_sensor/pairing_filled.png'),
  },
  PLACEMENT: require('../src/img/installation/motion_sensor/placement.png'),
  SERCOMM: {
    PAIRING: {
      FILLED: require('../src/img/installation/motion_sensor/sercomm_pairing_filled.png'),
    },
    PLACEMENT: require('../src/img/installation/motion_sensor/sercomm_placement.png'),
  },
};

const SMOKE_ALARM = {
  TESTING: require('../src/img/devices/smoke_alarm_testing.png'),
  SMOKE: require('../src/img/devices/smoke_alarm_smoke.png'),
  CO: require('../src/img/devices/smoke_alarm_co.png'),
  PAIRING: {
    FILLED: require('../src/img/installation/smoke_alarm/pairing_filled.png'),
  },
  SERCOMM: {
    PAIRING: {
      FILLED: require('../src/img/installation/smoke_alarm/sercomm_smoke_alarm_pairing_filled.png'),
    },
    BATTERIES: require('../src/img/installation/smoke_alarm/sercomm_batteries.png'),
    BRACKET: require('../src/img/installation/smoke_alarm/sercomm_smoke_alarm_mounting_bracket.png'),
    MOUNTING: require('../src/img/installation/smoke_alarm/sercomm_smoke_alarm_mounting.png'),
  },
};

const SWITCH = {
  PAIRING: {
    FILLED: require('../src/img/installation/switch/pairing_filled.png'),
  },
};

*/

const VENDOR = {
  LOGO: require('./img/logo.png'),
};

/*
const WATER_SENSOR = {
  WET: require('../src/img/devices/water_sensor_wet.png'),
  PAIRING: {
    FILLED: require('../src/img/installation/water_sensor/pairing_filled.png'),
  },
  BATTERIES: require('../src/img/installation/water_sensor/batteries.png'),
};
*/

export default {
  //  ACCESS_SENSOR,
  //  BETA,
  //  CAMERA,
  //  DEVICES,
  //  DOORBELL,
  //  DOOR_LOCK,
  //  GLASS_BREAK,
  //  HUB,
  //  KEY_PAD,
  //  MOTION_SENSOR,
  //  SMOKE_ALARM,
  //  SWITCH,
  VENDOR,
  //  WATER_SENSOR,
};
