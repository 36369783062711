import BaseModel from './BaseModel';

const getName = ({fname, lname}) => {
  if (fname && lname) {
    return `${fname} ${lname}`;
  }
  return fname;
};

class Member extends BaseModel {
  get currentState() {
    return this.state;
  }

  name() {
    return getName(this);
  }
}

Member.getName = getName;

export default Member;
