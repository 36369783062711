import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Field, Form, Formik} from 'formik';
import {Container, FormControl, FormControlLabel} from '@material-ui/core';
import {Switch, TextField} from 'formik-material-ui';

import {Locked as UserCodeIcon} from '@scout-app/assets/icons';

import {BoldText, HeadlineText, ParagraphText, Text} from '../elements';

import {AutoSubmit, Scheduler} from '../components/form';

import Colors from '../constants/Colors';

import useStyleSheet from '../hooks/useStyleSheet';

const styleSheet = (theme) => ({
  container: {
    padding: 24,
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  icon: {
    fontSize: 36,
    color: Colors.get('accent'),
  },
  label: {
    flexGrow: 1,
  }
})

function UserCode({hasDoorLock, onSubmit, user_code}) {
  const {t} = useTranslation('AddUserCode');
  const classes = useStyleSheet(styleSheet);
  const {active, disarm_on_entry, name, schedule, token} = user_code;

  const initialValues = useMemo(
    () => ({
      name,
      token,
      disarm_on_entry,
      scheduler: {active, schedule},
    }),
    [active, disarm_on_entry, name, schedule, token],
  );

  const _onSubmit = useCallback(
    (formData) => {
      const {
        name,
        token,
        disarm_on_entry,
        scheduler: {active, schedule},
      } = formData;
      onSubmit({
        name,
        token,
        disarm_on_entry,
        active,
        schedule,
      });
    },
    [onSubmit],
  );

  return useMemo(() => (
    <Container maxWidth="md" className={classes.container}>
      <Formik
        initialValues={initialValues}
        onSubmit={_onSubmit}>
        {({ submitForm, isSubmitting }) => (
          <Form>
            <FormControl fullWidth>
              <Field
                component={TextField}
                type="text"
                name="name"
                placeholder={t('add_user_code.name')} />
              <br />
              <Field
                component={TextField}
                type="number"
                name="token"
                placeholder={t('add_user_code.code')} />
              <div className={classes.centered}>
                <UserCodeIcon className={classes.icon} />
              </div>
              <HeadlineText i18nKey="AddUserCode:add_user_code.permissions">
                Permissions of this code:
              </HeadlineText>
              <ParagraphText i18nKey="AddUserCode:add_user_code.choose_active_settings">
              With the selector below, you can choose if and when this code is active.
              </ParagraphText>
              <Field
                component={Scheduler}
                name="scheduler" />
              {user_code.active && hasDoorLock && (
                <FormControlLabel
                  classes={classes}
                  label={
                    <>
                      <BoldText i18nKey="AddUserCode:add_user_code.disarm_title">
                        Disarm when entered
                      </BoldText>
                      <Text i18nKey="AddUserCode:add_user_code.disarm_description">
                        Disarm active mode if lock is allowed to disarm.
                      </Text>
                    </>
                  }
                  control={
                    <Field component={Switch} name="disarm_on_entry" type="checkbox" />
                  }
                  labelPlacement="start" />
              )}
              <AutoSubmit />
            </FormControl>
          </Form>
        )}
      </Formik>
    </Container>
  ), [_onSubmit, classes, hasDoorLock, initialValues, t, user_code.active]);
}

export default UserCode;
