import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';

import useLocation from './useLocation';

function useRequireAdmin() {
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if(location && location.member_state !== 'admin') {
      history.replace(`/locations/${location.id}`);
    }
  }, [history, location]);
}

export default useRequireAdmin;
