import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Field, Form, Formik} from 'formik';
import {FormControl} from '@material-ui/core';

import {Container, Box} from '@material-ui/core';

import {BoldText, Button, HeaderText, Text} from '../elements';
import {PasswordInput} from '../components/form';

import Member from '../models/Member';
import useModel from '../hooks/useModel';
import useStyleSheet from '../hooks/useStyleSheet';

const styleSheet = {
  container: {
    padding: 24,
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
};

const styles = {
  bold: {
    flex: 1,
  },
  text: {
    flex: 2,
  },
};

function AccountDetails({
  member: rawMember,
  onEdit,
  onUpdateNotifications,
  onUpdatePassword,
}) {
  const {t} = useTranslation('AccountDetails');
  const member = useModel(rawMember, Member);
  const classes = useStyleSheet(styleSheet);
  const {email, phone_number} = member;
  const name = useMemo(() => member.name(), [member]);
  return useMemo(
    () => (
      <Container maxWidth="md" className={classes.container}>
        <Formik
          initialValues={{
            password: '',
            new_password: '',
            new_password_confirmation: '',
          }}
          validate={() => {}}
          onSubmit={onUpdatePassword}>
          {({ submitForm, isSubmitting }) => (
            <Form>
              <FormControl fullWidth>
                <HeaderText i18nKey="Common:common.user_details">
                  User Details
                </HeaderText>
                <Box className={classes.box}>
                  <BoldText noWrap style={styles.bold}>{t('labels.name')}:</BoldText>
                  <Text style={styles.text}>{name}</Text>
                </Box>
                <Box className={classes.box}>
                  <BoldText noWrap style={styles.bold}>{t('labels.phone')}:</BoldText>
                  <Text style={styles.text}>{phone_number}</Text>
                </Box>
                <Box className={classes.box}>
                  <BoldText noWrap style={styles.bold}>{t('labels.email')}:</BoldText>
                  <Text style={styles.text}>{email}</Text>
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  i18nKey="Common:common.update"
                  onClick={onEdit}
                  disabled={isSubmitting}>
                  Update
                </Button>
                <br />
                <HeaderText i18nKey="Common:common.update_password">
                  Update Password
                </HeaderText>
                <Field
                  autoComplete="off"
                  component={PasswordInput}
                  name="password" />
                <br />
                <Field
                  autoComplete="new-password"
                  component={PasswordInput}
                  name="new_password"
                  placeholder={t('placeholders.new_password')} />
                <br />
                <Field
                  autoComplete="new-password"
                  component={PasswordInput}
                  name="new_password_confirmation"
                  placeholder={t('placeholders.new_password_confirmation')} />
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  i18nKey={
                    isSubmitting ? 'Common:common.updating' : 'Common:common.update'
                  }
                  onClick={submitForm}
                  disabled={isSubmitting}>
                  {isSubmitting ? 'Updating...' : 'Update'}
                </Button>
              </FormControl>
            </Form>
          )}
        </Formik>
      </Container>
    ),
    [classes.box, classes.container, email, name, onEdit, onUpdatePassword, phone_number, t],
  );
}

export default AccountDetails;
