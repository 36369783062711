import React, {useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Members from '../components/Members';
import Rfids from '../components/Rfids';
import UserCodes from '../components/UserCodes';

import useLocation from '../hooks/useLocation';

function Settings() {
  const history = useHistory();
  const {t} = useTranslation('Settings');
  const location = useLocation();

  const admin = location.member_state === 'admin';

  const {members, rfids, user_codes} = location;

  const onEditLocation = useCallback(() => {
    if (admin) {
      history.push(`/locations/${location.id}/edit`);
    }
  }, [admin, history, location.id]);

  const onSubscription = useCallback(() => {
    if (admin) {
      if (!location.hasAnyDevice()) {
        history.push(`/devices/new`);
      } else {
        history.push(`/subscription`);
      }
    }
  }, [admin, history, location]);

  const onAddMember = useCallback(() => {
    if (admin) {
      history.push(`/members/new`);
    }
  }, [admin, history]);

  const onMember = useCallback(
    member => history.push(`/members/${member.id}`),
    [history],
  );

  const onRfid = useCallback(
    rfid => {
      if (admin) {
        history.push(`/rfids/${rfid.id}`);
      }
    },
    [admin, history],
  );

  const onAddRfid = useCallback(() => {
    if (admin) {
      history.push(`/rfids/new`);
    }
  }, [admin, history]);

  const onUserCode = useCallback(
    user_code => {
      if (admin) {
        history.push(`/user_codes/${user_code.id}`);
      }
    },
    [admin, history],
  );

  const onAddUserCode = useCallback(() => {
    if (admin) {
      history.push(`/user_codes/new`);
    }
  }, [admin, history]);

  return useMemo(
    () => (
      <List>
        {admin && (
          <ListItem onClick={onEditLocation}>
            <ListItemText primary={t('settings.location_name')} />
            <ListItemIcon>
              <ChevronRightIcon />
            </ListItemIcon>
          </ListItem>
        )}
        <ListItem disabled>
          <ListItemText primary={t('settings.members')} />
        </ListItem>
        <Members
          admin={admin}
          members={members}
          onPress={onMember}
          onAddMember={onAddMember} />
        {location.canAddFobs() && (
          <>
            <ListItem disabled>
              <ListItemText primary={t('settings.key_fobs')} />
            </ListItem>
            <Rfids
              admin={admin}
              rfids={rfids}
              onPress={onRfid}
              onAddRfid={onAddRfid}
            />
          </>
        )}
        {location.canAddUserCodes() && (
          <>
            <ListItem disabled>
              <ListItemText primary={t('settings.lock_users')} />
            </ListItem>
            <UserCodes
              admin={admin}
              user_codes={user_codes}
              onPress={onUserCode}
              onAddUserCode={onAddUserCode}
            />
          </>
        )}
        {false && admin && (
          <ListItem onClick={onSubscription}>
            <ListItemText primary={t('settings.subscription')} />
            <ListItemIcon>
              <ChevronRightIcon />
            </ListItemIcon>
          </ListItem>
        )}
      </List>
    ),
    [admin, location, members, onAddMember, onAddRfid, onAddUserCode, onEditLocation, onMember, onRfid, onSubscription, onUserCode, rfids, t, user_codes],
  );
}

export default Settings;
