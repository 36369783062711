import React, {useMemo} from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import AddButton from './AddButton';
import Rfid from './Rfid';

function Rfids({admin = true, rfids, onPress, onAddRfid}) {
  return useMemo(
    () => (
      <List>
        {(rfids || []).map((rfid, i) => (
          <Rfid key={i} admin={admin} rfid={rfid} onPress={onPress} />
        ))}
        {admin && (
          <ListItem onClick={onAddRfid}>
            <AddButton i18nKey="Common:common.add_key_fob">
              add key fob
            </AddButton>
          </ListItem>
        )}
      </List>
    ),
    [admin, onAddRfid, onPress, rfids],
  );
}

export default Rfids;
