import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useConfirm} from 'material-ui-confirm';

import BackButton from '@material-ui/icons/ArrowBack';
import {Delete as DeleteButton} from '@scout-app/assets/icons';

import * as actions from '../actions/location';

import useActions from '../hooks/useActions';
import useLeftButton from '../hooks/useLeftButton';
import useRightButton from '../hooks/useRightButton';
import useMember from '../hooks/useMember';
import useTitle from '../hooks/useTitle';

import Component from '../routes/Member';

const selector = state => ({
  admin: state.location.member_state === 'admin',
  location_id: state.location.id,
  location_name: state.location.name,
  member_id: state.member.id,
});

const onError = err => alert(err.error || err.message || err);

function Member() {
  const history = useHistory();
  const {t} = useTranslation('Member');

  const {removeMember, setAdmin} = useActions(actions);
  const member = useMember();
  const {admin, location_id, location_name, member_id} = useSelector(
    selector,
  );
  const confirm = useConfirm();
  const onDelete = useCallback(() =>
    confirm({
      title: t('RemoveMember'),
      description: t('ConfirmRemoveMember', {location_name, member_name: member.fname}),
    }).then(() => {
      history.goBack();
      removeMember(location_id, member.id).catch(onError);
    })
    .catch(() => {})
  , [confirm, history, location_id, location_name, member.fname, member.id, removeMember, t]);

  const onToggle = useCallback(
    (e, isAdmin) => setAdmin(location_id, member.id, isAdmin === null ? member.state === 'admin' : isAdmin),
    [location_id, member.id, member.state, setAdmin],
  );

  const name = useMemo(() => member.name(), [member]);

  useTitle(name);
  useLeftButton(BackButton, history.goBack);
  useRightButton(DeleteButton, admin && member.id !== member_id ? onDelete : null);

  return useMemo(
    () => (
      <Component
        enabled={admin && member.id !== member_id}
        member={member}
        onToggle={onToggle}
      />
    ),
    [admin, member, member_id, onToggle],
  );

}

export default Member;
