import {useMemo} from 'react';

function useModel(object, Model) {
  return useMemo(() => {
    if (object && !(object instanceof Model)) {
      return new Model(object);
    }
    return object;
  }, [Model, object]);
}

export default useModel;
