import React from 'react';

import H1Text from '../elements/H1Text';

const SignupHeader = () => (
  <>
    <H1Text
      i18nKey="SignupHeader:welcome"
      //color="login"
      component="h2">
      welcome!
    </H1Text>
    <H1Text
      i18nKey="SignupHeader:sign_up"
      //color="login"
      >
      register below:
    </H1Text>
  </>
);

export default SignupHeader;
