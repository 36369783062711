import {useMemo} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';

const isFunction = obj => typeof obj === 'function';

export default function useStyleSheet(styleSheet = {}, ...rest) {
  const theme = useTheme();
  const styles = useMemo(
    () => (isFunction(styleSheet) ? styleSheet(theme, ...rest) : styleSheet),
    [rest, styleSheet, theme],
  );
  const useStyles = useMemo(() => makeStyles(styles), [styles]);
  return useStyles();
}
