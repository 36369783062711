import {useEffect} from 'react';

import useAppBar from './useAppBar';

function useTitle(nextTitle) {
  const {setTitle} = useAppBar();
  useEffect(() => {
    setTitle(nextTitle);
    return () => {
      setTitle(null);
    }
  }, [nextTitle, setTitle]);
}

export default useTitle;
