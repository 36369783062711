import {RECEIVE_TOKEN} from '../actions/ActionTypes';

import {setClient} from '../actions/pusher';

export default (/* store */) => next => action => {
  switch (action.type) {
    case RECEIVE_TOKEN:
      setClient(null);
      break;
    default:
      break;
  }
  next(action);
};
