import api from '../lib/api';

import {
  FINISH_INSTALL,
  RECEIVE_ACTIVITY,
  RECEIVE_ALARM,
  RECEIVE_ALARM_CONTACTS,
  RECEIVE_CAMERAS,
  RECEIVE_DEALER,
  RECEIVE_DEVICES,
  RECEIVE_ENVIRONMENTAL,
  RECEIVE_HUB,
  RECEIVE_INTEGRATION,
  RECEIVE_LOCATION,
  RECEIVE_LOCATIONS,
  RECEIVE_LOCATION_STATE,
  RECEIVE_MEMBERS,
  RECEIVE_MODES,
  RECEIVE_MONITORING,
  RECEIVE_RFIDS,
  RECEIVE_SUBSCRIPTION,
  RECEIVE_USER_CODES,
  RECEIVE_VISIBILITY,
  REMOVE_LOCATION,
  RESET_LOCATION,
  START_INSTALL,
  SET_ADDED_SENSORS,
  RESET_ADDED_SENSORS,
} from './ActionTypes';

import {logout} from './member';

const expiredSession = dispatch => err => {
  if (err === 'invalid or expired session') {
    dispatch(logout());
  }
  throw err;
};

export const resetLocation = () => dispatch =>
  dispatch({
    type: RESET_LOCATION,
  });

export const setLocation = location => dispatch => {
  return dispatch({
    type: RECEIVE_LOCATION,
    location,
  });
};

export const setHub = hub => dispatch =>
  dispatch({
    type: RECEIVE_HUB,
    hub,
  });

const setModes = modes => dispatch =>
  dispatch({
    type: RECEIVE_MODES,
    modes,
  });

export const setActivity = activity => dispatch =>
  dispatch({
    type: RECEIVE_ACTIVITY,
    activity,
  });

export const setDevices = devices => dispatch =>
  dispatch({
    type: RECEIVE_DEVICES,
    devices,
  });

const setMembers = members => dispatch =>
  dispatch({
    type: RECEIVE_MEMBERS,
    members,
  });

const setRfids = rfids => dispatch =>
  dispatch({
    type: RECEIVE_RFIDS,
    rfids,
  });

export const setCameras = cameras => dispatch =>
  dispatch({
    type: RECEIVE_CAMERAS,
    cameras,
  });

const setAlarmContacts = alarm_contacts => dispatch =>
  dispatch({
    type: RECEIVE_ALARM_CONTACTS,
    alarm_contacts,
  });

export const setMonitoring = pro_monitoring => dispatch =>
  dispatch({
    type: RECEIVE_MONITORING,
    pro_monitoring,
  });

export const updateMonitoring = (location_id, pro_monitoring) => (
  dispatch,
  getState,
) =>
  api
    .put(getState, `/locations/${location_id}/pro_monitoring`, pro_monitoring)
    .then(pro_monitoring => {
      dispatch(setMonitoring(pro_monitoring));
      return pro_monitoring;
    })
    .catch(expiredSession(dispatch));

const setIntegration = (name, integration) => dispatch =>
  dispatch({
    type: RECEIVE_INTEGRATION,
    name,
    integration,
  });

export const setLastAlarm = timestamp => dispatch =>
  dispatch({
    type: RECEIVE_ALARM,
    timestamp,
  });

const setEnvironmental = environmental => dispatch =>
  dispatch({
    type: RECEIVE_ENVIRONMENTAL,
    environmental,
  });

const requestHub = location_id => (dispatch, getState) =>
  api.get(getState, `/locations/${location_id}/hub`);

export const getHub = location_id => dispatch =>
  dispatch(requestHub(location_id))
    .then(hub => {
      dispatch(setHub(hub));
      return hub;
    })
    .catch(expiredSession(dispatch));

const setLocations = locations => dispatch =>
  dispatch({
    type: RECEIVE_LOCATIONS,
    locations,
  });

const removeLocation = location_id => dispatch =>
  dispatch({
    type: REMOVE_LOCATION,
    location_id,
  });

export const addLocation = data => (dispatch, getState) =>
  api
    .post(getState, '/locations', data)
    .then(location =>
      Promise.all([
        location,
        dispatch(setLocations([...getState().member.locations, location])),
        dispatch(setLocation(location)),
      ]),
    )
    .then(([location]) => location)
    .catch(expiredSession(dispatch));

export const addHub = (location_id, data) => (dispatch, getState) =>
  api
    .post(getState, `/locations/${location_id}/hub`, data)
    .then(hub => {
      dispatch(setHub(hub));
      return hub;
    })
    .catch(expiredSession(dispatch));

export const setDevice = (update, merge = false) => (dispatch, getState) =>
  Promise.resolve(
    [...getState().location.devices].map(device => {
      if (device.id === update.id) {
        if (merge) {
          return {
            ...device,
            ...update,
          };
        }
        return update;
      }
      return device;
    }),
  ).then(devices => dispatch(setDevices(devices)));

const requestRfids = location_id => (dispatch, getState) =>
  api
    .get(getState, `/locations/${location_id}/rfids`)
    .catch(expiredSession(dispatch));

export const getRfids = location_id => dispatch =>
  dispatch(requestRfids(location_id))
    .then(rfids => dispatch(setRfids(rfids)))
    .catch(expiredSession(dispatch));

export const requestActivity = (location_id, data) => (dispatch, getState) =>
  api.get(getState, `/locations/${location_id}/activity`, data);

export const getActivity = (location_id, data) => dispatch =>
  dispatch(requestActivity(location_id, data))
    .then(activity => {
      if (!data) {
        dispatch(setActivity(activity));
      }
      return activity;
    })
    .catch(expiredSession(dispatch));

export const getActivityEvent = (location_id, timestamp) => (
  dispatch,
  getState,
) =>
  api
    .get(getState, `/locations/${location_id}/activity/${timestamp}`)
    .catch(expiredSession(dispatch));

const requestMembers = location_id => (dispatch, getState) =>
  api.get(getState, `/locations/${location_id}/members`);

export const getMembers = location_id => dispatch =>
  dispatch(requestMembers(location_id))
    .then(members => dispatch(setMembers(members)))
    .catch(expiredSession(dispatch));

export const requestModes = location_id => (dispatch, getState) =>
  api.get(getState, `/locations/${location_id}/modes`);

export const getModes = location_id => dispatch =>
  dispatch(requestModes(location_id))
    .then(modes => dispatch(setModes(modes)))
    .catch(expiredSession(dispatch));

const requestDevices = location_id => (dispatch, getState) =>
  api.get(getState, `/locations/${location_id}/devices`);

export const getDevices = location_id => dispatch =>
  dispatch(requestDevices(location_id))
    .then(devices => dispatch(setDevices(devices)))
    .catch(expiredSession(dispatch));

const setUserCodes = user_codes => dispatch =>
  dispatch({
    type: RECEIVE_USER_CODES,
    user_codes,
  });

const requestUserCodes = location_id => (dispatch, getState) =>
  api.get(getState, `/locations/${location_id}/user_codes`);

export const getUserCodes = location_id => dispatch =>
  dispatch(requestUserCodes(location_id))
    .then(user_codes => dispatch(setUserCodes(user_codes)))
    .catch(expiredSession(dispatch));

const setUserCode = update => (dispatch, getState) =>
  Promise.resolve(
    [...getState().location.user_codes].map(user_code => {
      if (user_code.id === update.id) {
        return update;
      }
      return user_code;
    }),
  ).then(user_codes => dispatch(setUserCodes(user_codes)));

export const addUserCode = data => (dispatch, getState) =>
  api
    .post(getState, '/user_codes', data)
    .then(user_code => {
      dispatch(setUserCodes([...getState().location.user_codes, user_code]));
      return user_code;
    })
    .catch(expiredSession(dispatch));

export const updateUserCode = (user_code_id, data) => (dispatch, getState) =>
  api
    .put(getState, `/user_codes/${user_code_id}`, data)
    .then(user_code => {
      dispatch(setUserCode(user_code));
      return user_code;
    })
    .catch(expiredSession(dispatch));

export const removeUserCode = user_code_id => (dispatch, getState) =>
  api
    .del(getState, `/user_codes/${user_code_id}`)
    .then(() =>
      [...getState().location.user_codes].filter(
        user_code => user_code.id !== user_code_id,
      ),
    )
    .then(user_codes => dispatch(setUserCodes(user_codes)))
    .catch(expiredSession(dispatch));

const requestCameras = location_id => (dispatch, getState) =>
  api.get(getState, `/locations/${location_id}/cameras`);

export const getCameras = location_id => dispatch =>
  dispatch(requestCameras(location_id))
    .then(cameras => {
      dispatch(setCameras(cameras));
      return cameras;
    })
    .catch(expiredSession(dispatch));

export const requestLocation = location_id => (dispatch, getState) =>
  api.get(getState, `/locations/${location_id}`);

export const getDealer = location_id => (dispatch, getState) =>
  api.get(getState, `/locations/${location_id}/dealer`).then(dealer => {
    dispatch({
      type: RECEIVE_DEALER,
      dealer,
    });
    return dealer;
  });

export const getLocation = location_id => dispatch =>
  dispatch(requestLocation(location_id))
    .then(location => {
      const promises = [
        dispatch(requestActivity(location_id)).then(
          activity => (location.activity = activity),
        ),
        dispatch(requestMembers(location_id)).then(
          members => (location.members = members),
        ),
        dispatch(getDealer(location_id)),
      ];
      if (location.hub_id) {
        promises.push(
          dispatch(requestHub(location_id)).then(hub => (location.hub = hub)),
        );
      } else {
        location.hub_id = null;
        location.hub = null;
      }
      if ((location.mode_ids || []).length) {
        promises.push(
          dispatch(requestModes(location_id)).then(
            modes => (location.modes = modes),
          ),
        );
      } else {
        location.mode_ids = [];
        location.modes = [];
      }
      if ((location.device_ids || []).length) {
        promises.push(
          dispatch(requestDevices(location_id)).then(
            devices => (location.devices = devices),
          ),
        );
      } else {
        location.device_ids = [];
        location.devices = [];
      }
      if ((location.rfid_ids || []).length) {
        promises.push(
          dispatch(requestRfids(location_id)).then(
            rfids => (location.rfids = rfids),
          ),
        );
      } else {
        location.rfid_ids = [];
        location.rfids = [];
      }
      if ((location.user_code_ids || []).length) {
        promises.push(
          dispatch(requestUserCodes(location_id)).then(
            user_codes => (location.user_codes = user_codes),
          ),
        );
      } else {
        location.user_code_ids = [];
        location.user_codes = [];
      }
      if ((location.camera_ids || []).length) {
        promises.push(
          dispatch(requestCameras(location_id)).then(
            cameras => (location.cameras = cameras),
          ),
        );
      } else {
        location.camera_ids = [];
        location.cameras = [];
      }
      location.address = location.address || {};
      location.alarm_contacts = location.alarm_contacts || {};
      location.amazon_user_id = location.amazon_user_id || null;
      location.connected_accounts = location.connected_accounts || {};
      location.device_groups = location.device_groups || [];
      location.environmental = location.environmental || {};
      location.pro_monitoring = location.pro_monitoring || {};
      location.state = location.state || {};
      location.structure_type = location.structure_type || null;
      location.subscription_id = location.subscription_id || null;
      return Promise.all(promises).then(() => location);
    })
    .then(location => dispatch(setLocation(location)))
    .catch(expiredSession(dispatch));

export const addDevice = (location_id, device_type) => (dispatch, getState) =>
  api
    .put(getState, '/devices', {location_id, device_type})
    .catch(expiredSession(dispatch));

export const addNestCamera = (location_id, camera_id) => (dispatch, getState) =>
  api
    .post(getState, '/cameras/nest', {location_id, camera_id})
    .then(camera => {
      dispatch(setCameras([...getState().location.cameras, camera]));
      return camera;
    })
    .catch(expiredSession(dispatch));

export const addNestDevice = (location_id, device_type, device_id) => (
  dispatch,
  getState,
) =>
  api
    .post(getState, '/devices/nest', {location_id, device_type, device_id})
    .then(device => {
      dispatch(setDevices([...getState().location.devices, device]));
      return device;
    })
    .catch(expiredSession(dispatch));

export const addLogitechCamera = (location_id, camera_id) => (
  dispatch,
  getState,
) =>
  api
    .post(getState, '/cameras/logitech', {location_id, camera_id})
    .then(camera => {
      dispatch(setCameras([...getState().location.cameras, camera]));
      return camera;
    })
    .catch(expiredSession(dispatch));

export const getDevice = device_id => (dispatch, getState) =>
  api
    .get(getState, `/devices/${device_id}`)
    .then(device => dispatch(setDevice(device)))
    .catch(expiredSession(dispatch));

export const removeDevice = device_id => (dispatch, getState) =>
  dispatch(
    setDevices(
      [...getState().location.devices].filter(
        device => device.id !== device_id,
      ),
    ),
  );

export const unpairDevice = (device_id, zwave = false) => (
  dispatch,
  getState,
) =>
  api
    .del(getState, `/devices/${device_id}`)
    .then(() => {
      if (!zwave) {
        dispatch(removeDevice(device_id));
      }
    })
    .catch(expiredSession(dispatch));

const setRfid = update => (dispatch, getState) =>
  Promise.resolve(
    [...getState().location.rfids].map(rfid => {
      if (rfid.id === update.id) {
        return update;
      }
      return rfid;
    }),
  ).then(rfids => dispatch(setRfids(rfids)));

export const addRfid = data => (dispatch, getState) =>
  api
    .post(getState, '/rfids', data)
    .then(rfid => {
      dispatch(setRfids([...getState().location.rfids, rfid]));
      return rfid;
    })
    .catch(expiredSession(dispatch));

export const updateRfid = (rfid_id, data) => (dispatch, getState) =>
  api
    .put(getState, `/rfids/${rfid_id}`, data)
    .then(rfid => {
      dispatch(setRfid(rfid));
      return rfid;
    })
    .catch(expiredSession(dispatch));

export const removeRfid = rfid_id => (dispatch, getState) =>
  api
    .del(getState, `/rfids/${rfid_id}`)
    .then(() =>
      [...getState().location.rfids].filter(rfid => rfid.id !== rfid_id),
    )
    .then(rfids => dispatch(setRfids(rfids)))
    .catch(expiredSession(dispatch));

export const removeMode = mode_id => (dispatch, getState) =>
  api
    .del(getState, `/modes/${mode_id}`)
    .then(() =>
      [...getState().location.modes].filter(mode => mode.id !== mode_id),
    )
    .then(modes => dispatch(setModes(modes)))
    .catch(expiredSession(dispatch));

export const setModeState = (mode_id, state) => (dispatch, getState) =>
  Promise.resolve(
    [...getState().location.modes].map(mode => {
      if (mode.id === mode_id || state !== 'disarmed') {
        return {
          ...mode,
          state: mode.id === mode_id ? state : 'disarmed',
        };
      }
      return mode;
    }),
  ).then(modes => dispatch(setModes(modes)));

const setMode = update => (dispatch, getState) =>
  Promise.resolve(
    [...getState().location.modes].map(mode => {
      if (mode.id === update.id) {
        return update;
      }
      return mode;
    }),
  ).then(modes => dispatch(setModes(modes)));

export const addMode = location_id => (dispatch, getState) =>
  api
    .post(getState, '/modes', {location_id})
    .then(mode => {
      dispatch(setModes([...getState().location.modes, mode]));
      return mode;
    })
    .catch(expiredSession(dispatch));

export const updateMode = (mode_id, data) => (dispatch, getState) =>
  api
    .put(getState, `/modes/${mode_id}`, data)
    .then(mode => {
      dispatch(setMode(mode));
      return mode;
    })
    .catch(expiredSession(dispatch));

export const addModeDevice = (mode_id, device_id) => (dispatch, getState) =>
  api
    .put(getState, `/modes/${mode_id}/devices/${device_id}`)
    .then(mode => {
      dispatch(setMode(mode));
      return mode;
    })
    .catch(expiredSession(dispatch));

export const removeModeDevice = (mode_id, device_id) => (dispatch, getState) =>
  api
    .del(getState, `/modes/${mode_id}/devices/${device_id}`)
    .then(mode => {
      dispatch(setMode(mode));
      return mode;
    })
    .catch(expiredSession(dispatch));

export const addModeAction = (mode_id, action_type, data) => (
  dispatch,
  getState,
) =>
  api
    .put(getState, `/modes/${mode_id}/actions/${action_type}`, data)
    .then(mode => {
      dispatch(setMode(mode));
      return mode;
    })
    .catch(expiredSession(dispatch));

export const removeModeAction = (mode_id, action_type) => (
  dispatch,
  getState,
) =>
  api
    .del(getState, `/modes/${mode_id}/actions/${action_type}`)
    .then(mode => {
      dispatch(setMode(mode));
      return mode;
    })
    .catch(expiredSession(dispatch));

export const requestTriggerModeAction = (mode_id, action_type, data) => (
  dispatch,
  getState,
) => api.post(getState, `/modes/${mode_id}/actions/${action_type}`, data);

export const triggerModeAction = (mode_id, action_type, data) => dispatch =>
  dispatch(requestTriggerModeAction(mode_id, action_type, data))
    .then(mode => dispatch(setMode(mode)))
    .catch(expiredSession(dispatch));

export const requestModeToggleById = (mode_id, state) => (dispatch, getState) =>
  api.post(getState, `/modes/${mode_id}`, {state});

export const requestModeToggle = mode => dispatch => {
  const state = mode.state === 'disarmed' ? 'arming' : 'disarm';
  return dispatch(requestModeToggleById(mode.id, state));
};

export const toggleMode = mode => dispatch => {
  let newState = 'disarm';
  let tempState = 'disarmed';
  if (mode.state === tempState) {
    newState = tempState = 'arming';
  }
  return dispatch(setModeState(mode.id, tempState))
    .then(() => dispatch(requestModeToggleById(mode.id, newState)))
    .then(result => dispatch(setModeState(result.id, result.state)))
    .catch(error => {
      dispatch(setModeState(mode.id, mode.state));
      expiredSession(dispatch)(error);
    });
};

export const triggerMode = (mode_id, data = {}) => (dispatch, getState) =>
  api
    .post(getState, `/modes/${mode_id}`, {
      state: 'alarmed',
      ...data,
    })
    .then(result => dispatch(setModeState(result.id, result.state)))
    .catch(expiredSession(dispatch));

export const getEnvironmental = location_id => (dispatch, getState) =>
  api
    .get(getState, `/locations/${location_id}/environmental`)
    .then(environmental => dispatch(setEnvironmental(environmental)))
    .catch(expiredSession(dispatch));

export const requestDismissEnvironmental = location_id => (
  dispatch,
  getState,
) => api.del(getState, `/locations/${location_id}/environmental`);

export const dismissEnvironmental = location_id => dispatch =>
  dispatch(requestDismissEnvironmental(location_id))
    .then(environmental => dispatch(setEnvironmental(environmental)))
    .catch(expiredSession(dispatch));

export const requestTriggerEnvironmental = (location_id, data) => (
  dispatch,
  getState,
) => api.post(getState, `/locations/${location_id}/environmental`, data);

export const triggerEnvironmental = (location_id, data) => dispatch =>
  dispatch(requestTriggerEnvironmental(location_id, data)).catch(
    expiredSession(dispatch),
  );

export const setLocationState = state => dispatch =>
  dispatch({
    type: RECEIVE_LOCATION_STATE,
    state,
  });

export const triggerAlarm = location_id => (dispatch, getState) =>
  api
    .put(getState, `/locations/${location_id}/alarms`)
    .then(result => dispatch(setLocationState(result)))
    .catch(expiredSession(dispatch));

export const panic = location_id => (dispatch, getState) =>
  api
    .put(getState, `/locations/${location_id}/panic`)
    .then(result => dispatch(setLocationState(result)))
    .catch(expiredSession(dispatch));

export const dismissPanic = location_id => (dispatch, getState) =>
  api
    .del(getState, `/locations/${location_id}/panic`)
    .then(result => dispatch(setLocationState(result)))
    .catch(expiredSession(dispatch));

export const setGroupVisibility = (group, hidden) => dispatch =>
  dispatch({
    type: RECEIVE_VISIBILITY,
    group,
    hidden,
  });

export const updateDevice = (device_id, data) => (dispatch, getState) =>
  api
    .put(getState, `/devices/${device_id}`, data)
    .then(device => {
      dispatch(setDevice(device));
      return device;
    })
    .catch(expiredSession(dispatch));

export const setTestMode = (device_id, test) => (dispatch, getState) =>
  api[test ? 'put' : 'del'](getState, `/devices/${device_id}/test`).catch(
    () => {},
  );

export const setAdmin = (location_id, member_id, admin) => (
  dispatch,
  getState,
) =>
  api
    .put(getState, `/locations/${location_id}/members/${member_id}`, {admin})
    .then(members => dispatch(setMembers(members)))
    .catch(expiredSession(dispatch));

export const setInstallMode = (location_id, install) => (dispatch, getState) =>
  api[install ? 'put' : 'del'](
    getState,
    `/locations/${location_id}/install`,
  ).catch(() => {});

export const setZwaveMode = (hub_id, mode) => (dispatch, getState) =>
  api
    .put(getState, `/hubs/${hub_id}/zwave`, {mode})
    .then(hub => dispatch(setHub(hub)))
    .catch(expiredSession(dispatch));

export const setSiren = (hub_id, on) => (dispatch, getState) =>
  api
    .put(getState, `/hubs/${hub_id}/siren`, {on})
    .then(hub => dispatch(setHub(hub)))
    .catch(expiredSession(dispatch));

export const updateHub = (hub_id, data) => (dispatch, getState) =>
  api
    .put(getState, `/hubs/${hub_id}`, data)
    .then(hub => dispatch(setHub(hub)))
    .catch(expiredSession(dispatch));

export const setChirp = (hub_id, type) => (dispatch, getState) =>
  api
    .put(getState, `/hubs/${hub_id}/chirp`, {type})
    .then(hub => dispatch(setHub(hub)))
    .catch(expiredSession(dispatch));

export const getAlarmContacts = location_id => (dispatch, getState) =>
  api
    .get(getState, `/locations/${location_id}/alarm_contacts`)
    .then(alarm_contacts => dispatch(setAlarmContacts(alarm_contacts)))
    .catch(expiredSession(dispatch));

export const updateAlarmContacts = (location_id, data) => (
  dispatch,
  getState,
) =>
  api
    .put(getState, `/locations/${location_id}/alarm_contacts`, data)
    .then(alarm_contacts => dispatch(setAlarmContacts(alarm_contacts)))
    .catch(expiredSession(dispatch));

export const updateLocation = (location_id, data) => (dispatch, getState) =>
  api
    .put(getState, `/locations/${location_id}`, data)
    .then(location => dispatch(setLocation(location)))
    .catch(expiredSession(dispatch));

export const deleteLocation = location_id => (dispatch, getState) =>
  api
    .del(getState, `/locations/${location_id}`)
    .then(() => dispatch(removeLocation(location_id)))
    .catch(expiredSession(dispatch));

export const setCamera = update => (dispatch, getState) =>
  Promise.resolve(
    [...getState().location.cameras].map(camera => {
      if (camera.id === update.id) {
        return update;
      }
      return camera;
    }),
  ).then(cameras => dispatch(setCameras(cameras)));

export const verifyCamera = mac => (dispatch, getState) =>
  api.get(getState, `/cameras/mac/${mac}`).catch(expiredSession(dispatch));

export const addCamera = (location_id, mac, method = 'wifi') => (
  dispatch,
  getState,
) =>
  api
    .put(getState, '/cameras', {location_id, mac})
    .then(result => {
      if (method !== 'qrcode') {
        dispatch(setCameras([...getState().location.cameras, result]));
      }
      return result;
    })
    .catch(expiredSession(dispatch));

export const getCamera = camera_id => (dispatch, getState) =>
  api
    .get(getState, `/cameras/${camera_id}`)
    .then(camera => {
      dispatch(setCamera(camera));
      return camera;
    })
    .catch(expiredSession(dispatch));

export const removeCamera = camera_id => (dispatch, getState) =>
  api
    .del(getState, `/cameras/${camera_id}`)
    .then(() =>
      [...getState().location.cameras].filter(
        camera => camera.id !== camera_id,
      ),
    )
    .then(cameras => dispatch(setCameras(cameras)))
    .catch(expiredSession(dispatch));

export const updateCamera = (camera_id, data) => (dispatch, getState) =>
  api
    .put(getState, `/cameras/${camera_id}`, data)
    .then(camera => {
      dispatch(setCamera(camera));
      return camera;
    })
    .catch(expiredSession(dispatch));

export const getCameraActivity = (camera_id, data) => (dispatch, getState) =>
  api
    .get(getState, `/cameras/${camera_id}/activity`, data)
    .catch(expiredSession(dispatch));

export const removeCameraActivity = (camera_id, timestamp) => (
  dispatch,
  getState,
) =>
  api
    .del(getState, `/cameras/${camera_id}/activity/${timestamp}`)
    .catch(expiredSession(dispatch));

export const getStream = camera_id => (dispatch, getState) =>
  api
    .get(getState, `/cameras/${camera_id}/stream`)
    .catch(expiredSession(dispatch));

export const playMessage = (camera_id, type) => (dispatch, getState) =>
  api
    .post(getState, `/cameras/${camera_id}/message`, {type})
    .catch(expiredSession(dispatch));

export const findMember = email => (dispatch, getState) =>
  api.get(getState, '/members', {email}).catch(expiredSession(dispatch));

export const addMember = (location_id, data) => (dispatch, getState) =>
  api
    .post(getState, `/locations/${location_id}/members`, data)
    .then(members => dispatch(setMembers(members)))
    .catch(expiredSession(dispatch));

export const removeMember = (location_id, member_id) => (dispatch, getState) =>
  api
    .del(getState, `/locations/${location_id}/members/${member_id}`)
    .then(members => dispatch(setMembers(members)))
    .catch(expiredSession(dispatch));

export const getIntegration = (location_id, type, data) => (
  dispatch,
  getState,
) =>
  api
    .get(getState, `/locations/${location_id}/connected_accounts/${type}`, data)
    .catch(expiredSession(dispatch));

export const getIntegrationUrl = (location_id, type) => (dispatch, getState) =>
  api
    .get(getState, `/locations/${location_id}/connected_accounts/${type}/auth`)
    .catch(expiredSession(dispatch));

export const addIntegration = (location_id, type, data) => (
  dispatch,
  getState,
) =>
  api
    .post(
      getState,
      `/locations/${location_id}/connected_accounts/${type}`,
      data,
    )
    .then(integration => {
      dispatch(setIntegration(type, integration));
      return integration;
    })
    .catch(expiredSession(dispatch));

export const updateIntegration = (location_id, type, data) => (
  dispatch,
  getState,
) =>
  api
    .put(getState, `/locations/${location_id}/connected_accounts/${type}`, data)
    .then(integration => dispatch(setIntegration(type, integration)))
    .catch(expiredSession(dispatch));

export const removeIntegration = (location_id, type) => (dispatch, getState) =>
  api
    .del(getState, `/locations/${location_id}/connected_accounts/${type}`)
    .then(() => dispatch(setIntegration(type, null)))
    .catch(expiredSession(dispatch));

export const migrateIntegration = (location_id, type, version) => (
  dispatch,
  getState,
) =>
  api
    .put(
      getState,
      `/locations/${location_id}/connected_accounts/${type}/migrate`,
      {version},
    )
    .catch(expiredSession(dispatch));

export const getAvailablePlans = location_id => (dispatch, getState) =>
  api
    .get(getState, `/locations/${location_id}/plans`)
    .catch(expiredSession(dispatch));

export const getPendingSubscription = location_id => (dispatch, getState) =>
  api
    .get(getState, `/locations/${location_id}/pending_subscription`)
    .catch(expiredSession(dispatch));

export const setSubscription = (subscription, plan_code) => dispatch =>
  dispatch({
    type: RECEIVE_SUBSCRIPTION,
    subscription,
    plan_code,
  });

export const getSubscription = location_id => (dispatch, getState) =>
  api
    .get(getState, `/locations/${location_id}/subscription`)
    .then(subscription => {
      dispatch(setSubscription(subscription, subscription.plan));
      return subscription;
    })
    .catch(expiredSession(dispatch));

export const previewSubscription = (location_id, {plan_code, ...data}) => (
  dispatch,
  getState,
) =>
  api
    .get(getState, `/locations/${location_id}/subscription/${plan_code}`, data)
    .catch(expiredSession(dispatch));

export const addSubscription = (location_id, data) => (dispatch, getState) =>
  api
    .post(getState, `/locations/${location_id}/subscription`, data)
    .then(subscription => {
      dispatch(setSubscription(subscription, data.plan_code));
      return subscription;
    })
    .catch(expiredSession(dispatch));

export const updateSubscription = (location_id, data) => (dispatch, getState) =>
  api
    .put(getState, `/locations/${location_id}/subscription`, data)
    .then(subscription => {
      dispatch(setSubscription(subscription, data.plan_code));
      return subscription;
    })
    .catch(expiredSession(dispatch));

export const getBillingInfo = location_id => (dispatch, getState) =>
  api
    .get(getState, `/locations/${location_id}/billing`)
    .catch(expiredSession(dispatch));

export const updateBillingInfo = (location_id, data) => (dispatch, getState) =>
  api
    .put(getState, `/locations/${location_id}/billing`, data)
    .catch(expiredSession(dispatch));

export const getCouponCode = (location_id, coupon_code) => (
  dispatch,
  getState,
) => api.put(getState, `/locations/${location_id}/plans`, {coupon_code});

export const startInstall = () => dispatch => {
  dispatch({type: START_INSTALL});
  return Promise.resolve();
};

export const finishInstall = () => (dispatch, getState) => {
  dispatch({type: FINISH_INSTALL});
  window.location.href = `/locations/${getState().location.id}`;
};

export const getCertificate = location_id => (dispatch, getState) =>
  api
    .get(getState, `/locations/${location_id}/certificate`, {email: true})
    .catch(expiredSession(dispatch));

export const setAddedSensors = sensors => dispatch =>
  dispatch({
    type: SET_ADDED_SENSORS,
    sensors,
  });

export const resetAddedSensors = () => dispatch =>
  dispatch({type: RESET_ADDED_SENSORS});
