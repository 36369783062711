import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import human from 'humanparser';

import BackButton from '@material-ui/icons/ArrowBack';

import * as actions from '../actions/location';

import useActions from '../hooks/useActions';
import useLeftButton from '../hooks/useLeftButton';
import useSetState from '../hooks/useSetState';
import useTitle from '../hooks/useTitle';

import Component from '../routes/AddMember';

const selector = ({location}) => ({location_id: location.id});

const onError = err => alert(err.error || err.message || err);

function AddMember() {
  const history = useHistory();
  const {t} = useTranslation('AddMember');
  const {addMember, findMember} = useActions(actions);

  useTitle(t('AddMember:AddMember'));
  useLeftButton(BackButton, history.goBack);
  const {location_id} = useSelector(selector);
  const [state, setState] = useSetState();

  const search = useCallback(
    ({email}, {setSubmitting}) => {
      if (!email) {
        setSubmitting(false);
        return onError(t('Please enter an email address.'));
      }

      return findMember(email)
        .then(({member_id}) => setState({member_id, searched: true}))
        .catch(onError)
        .finally(() => setSubmitting(false));
    },
    [findMember, setState, t],
  );

  const save = useCallback(
    ({admin, email, name}, {setSubmitting}) => {
      const {member_id} = state;
      const member = {
        admin: !!admin,
        email,
        member_id,
      };

      if (!member_id) {
        if (!name) {
          setSubmitting(false);
          return onError(t("Please enter the member's name."));
        }
        const { firstName: fname, lastName: lname } = human.parseName(name);
        member.fname = fname;
        member.lname = lname;
      }

      return addMember(location_id, member)
        .then(() => history.goBack())
        .catch(onError)
        .finally(() => setSubmitting(false));
    },
    [addMember, history, location_id, state, t],
  );

  return useMemo(() => {
    return (
      <Component
        {...state}
        onChange={setState}
        onSubmit={state.searched ? save : search}
      />
    );
  }, [save, search, setState, state]);
}

export default AddMember;
