import React, {useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';

import * as actions from '../actions/location';

import Component from '../components/Devices';

import useActions from '../hooks/useActions';
import useLocation from '../hooks/useLocation';

function Devices() {
  const history = useHistory();
  const location = useLocation();

  const {setGroupVisibility, updateDevice} = useActions(actions);

  const admin = location.member_state === 'admin';

  const onEdit = useCallback(
    (id, group) => {
      if (admin) {
        // history.push(`/device_groups/${id === -1 ? 'new' : id}`);
      }
    },
    [admin],
  );

  const onDevice = useCallback(
    device => {
      if (admin) {
        // history.push(`/devices/${device.id}`);
      }
    },
    [admin],
  );

  const onCamera = useCallback(
    camera => {
      if (admin) {
        // history.push(`/cameras/${camera.id}`);
      }
    },
    [admin],
  );

  const onHub = useCallback(() => {
    if (admin) {
      history.push(`/hub`);
    }
  }, [admin, history]);

  const onIntegration = useCallback(
    integration => {
      const {key, onPress} = integration;
      if (admin) {
        if (onPress) {
          onPress();
        } else {
          // history.push(`/integrations/${key}`);
        }
      }
    },
    [admin],
  );

  const onLongPressDevice = useCallback(
    device => {
      if (device.canSwitch()) {
        updateDevice(device.id, {state: device.isOn() ? 'off' : 'on'});
      } else if (device.isDoorLock()) {
        updateDevice(device.id, {state: device.isLocked() ? 'unlock' : 'lock'});
      }
    },
    [updateDevice],
  );

  const onLongPressCamera = useCallback(
    camera => {
      onCamera(camera);
    },
    [onCamera],
  );

  const onAddDevice = useCallback(() => {
    if (admin) {
      history.push(`/devices/new`);
    }
  }, [admin, history]);

  return useMemo(
    () => (
      <Component
        location={location}
        setGroupVisibility={setGroupVisibility}
        onEdit={onEdit}
        onDevice={onDevice}
        onCamera={onCamera}
        onHub={onHub}
        onIntegration={onIntegration}
        onLongPressDevice={onLongPressDevice}
        onLongPressCamera={onLongPressCamera}
        onAddDevice={onAddDevice}
      />
    ),
    [
      location,
      onAddDevice,
      onCamera,
      onDevice,
      onEdit,
      onHub,
      onIntegration,
      onLongPressCamera,
      onLongPressDevice,
      setGroupVisibility,
    ],
  );
}

export default Devices;
