import React, {useMemo} from 'react';
import {Field, Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';

import {TextField} from 'formik-material-ui';

import {Text} from '../../elements';
import DeviceImage from '../DeviceImage';

const noop = () => {};

function Naming(props) {
  const {device, setName, value} = props;
  const {t} = useTranslation('Device');

/*
  const fields = useMemo(
    () => [
      {
        name: 'image',
        interactive: false,
        component: (
          <DeviceImage device={device} />
        ),
      },
      {
        name: 'text',
        interactive: false,
        hidden: !device.isNestDevice(),
        component: (
          <Text>{device.name}</Text>
        ),
      },
      {
        name: 'name',
        hidden: device.isNestDevice(),
        component: TextField,
        clearButtonMode: 'always',
        placeholder: t('DeviceName'),
        returnKeyType: 'done',
        onChangeText: setName,
      },
    ],
    [device, setName, t],
  );

  const initialValues = useMemo(
    () => ({
      name: value.name,
    }),
    [value.name],
  );
*/

  return useMemo(
    () => (
      <>
        <DeviceImage device={device} />
        <Field
          component={TextField}
          type="text"
          name="name"
          placeholder={t('DeviceName')} />
      </>
    ),
    [device, t],
  );
}

Naming.fieldName = 'name_field';

Naming.getFieldProps = ({device, setName, styles}) => ({
  device,
  setName,
  styles,
});

Naming.populateInitialValues = device => ({
  name: device.name,
});

export default Naming;
