import { createMuiTheme } from '@material-ui/core/styles';

import Colors from '../constants/Colors';

export default () =>
  createMuiTheme({
    palette: {
      type: Colors.get('darkBackground') === '#fff' ? 'light' : 'dark',
      primary: {
        main: Colors.get('loginText'),
      },
      secondary: {
        main: Colors.get('loginText'),
      },
      error: {
        main: Colors.get('alarmed'),
      },
      background: {
        default: Colors.get('darkBackground'),
      },
    },
    typography: {
      allVariants: {
        color: Colors.get('loginText'),
      },
    },
  });
