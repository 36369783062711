import {RECEIVE_DEALER} from '../actions/ActionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_DEALER:
      return action.dealer;
    default:
      return state;
  }
};
