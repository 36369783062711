import React, {useMemo} from 'react';

import clsx from 'clsx';

import Text from '../../elements/Text';

import Activity from '../../lib/Activity';
import moment from '../../lib/moment';

function ActivityDetail({classes, item = {}, style}) {
  const {description, timestamp} = item;
  const time = useMemo(() => moment(timestamp, `YYYY-MM-DD'T'HH:mm:ssZ`).format('LTS'), [timestamp]);

  const hasPreview = useMemo(() => Activity.hasPreview(item), [item]);
  const src = item?.meta?.image_url;
  return useMemo(() => (
    <div className={classes.details}>
      <Text className={clsx(classes.description, style)}>
        {description}
      </Text>
      <Text className={clsx(classes.time, style)}>
        {time}
      </Text>
      {hasPreview && (
        <img className={classes.image} src={src} alt="" />
      )}
    </div>
  ), [classes.description, classes.details, classes.image, classes.time, description, hasPreview, src, style, time]);
}

export default ActivityDetail;
