import React, {useMemo} from 'react';

import clsx from 'clsx';

import LongPressable from './LongPressable';

import Text from '../elements/Text';

import Model from '../models/Camera';

import useDeviceState from '../hooks/useDeviceState';
import useModel from '../hooks/useModel';

function Camera(props) {
  const {classes, filtering, selected, onPress, onLongPress} = props;
  const camera = useModel(props.camera, Model);
  const currentState = useDeviceState('Camera', camera);
  const Icon = useMemo(() => camera.getType().icon, [camera]);
  const color = useMemo(() => {
    if (camera.isOffline()) {
      return 'red';
    }
    // TODO - yellow if muted or in privacy mode?
  }, [camera]);
  return useMemo(() => {
    return (
      <LongPressable
        className={clsx(classes.toggle, classes[color], {
          [classes.filtering]: filtering && !selected,
        })}
        onClick={() => onPress(camera)}
        onLongPress={() => onLongPress(camera)}>
        <Icon className={clsx(classes.icon, classes[color], classes[camera.type])} />
        <div className={classes.state}>
          <Text className={clsx(classes.info, classes[color])}>{currentState}</Text>
        </div>
        <Text className={clsx(classes.name, classes[color])}>{camera.name}</Text>
      </LongPressable>
    );
  }, [camera, classes, color, currentState, filtering, onLongPress, onPress, selected]);
}

export default Camera;
