import jwt_decode from 'jwt-decode';

import {
  BEGIN_LOGOUT,
  LOGOUT,
  RECEIVE_TOKEN,
  SET_TOKEN,
} from '../actions/ActionTypes';

export default store => next => action => {
  switch (action.type) {
    case SET_TOKEN: {
      const {token} = action;
      const {id, fname, email, exp} = jwt_decode(token);
      store.dispatch({
        type: RECEIVE_TOKEN,
        member: {id, fname, email, exp},
        token,
      });
      break;
    }
    case BEGIN_LOGOUT: {
      store.dispatch({type: LOGOUT});
      break;
    }
    default:
      break;
  }
  next(action);
};
