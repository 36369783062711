import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Field, Form, Formik} from 'formik';
import {Container, FormControl, MenuItem} from '@material-ui/core';
import {Select, TextField} from 'formik-material-ui';

import {Rfid as RfidIcon} from '@scout-app/assets/icons';

import {HeadlineText, ParagraphText} from '../elements';

import {AutoSubmit, Scheduler} from '../components/form';

import Colors from '../constants/Colors';

import useStyleSheet from '../hooks/useStyleSheet';

const styleSheet = (theme) => ({
  container: {
    padding: 24,
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  icon: {
    fontSize: 36,
    color: Colors.get('item'),
  }
})

function Rfid({modes, onSubmit, rfid}) {
  const {t} = useTranslation('AddRfid');
  const classes = useStyleSheet(styleSheet);
  const {active, mode_id, name, schedule} = rfid;

  const initialValues = useMemo(
    () => ({
      name,
      mode_id,
      scheduler: {active, schedule},
    }),
    [active, mode_id, name, schedule],
  );

  const _onSubmit = useCallback(
    (formData) => {
      const {
        name,
        mode_id,
        scheduler: {active, schedule},
      } = formData;
      onSubmit({
        name,
        mode_id,
        active,
        schedule,
      });
    },
    [onSubmit],
  );

  return useMemo(() => (
    <Container maxWidth="md" className={classes.container}>
      <Formik
        initialValues={initialValues}
        onSubmit={_onSubmit}>
        {({ submitForm, isSubmitting }) => (
          <Form>
            <FormControl fullWidth>
              <Field
                component={TextField}
                type="text"
                name="name"
                placeholder={t('add_rfid.fob_name')} />
              <div className={classes.centered}>
                <RfidIcon className={classes.icon} />
              </div>
              {modes.length > 0 && (
                <>
                  <HeadlineText i18nKey="AddRfid:add_rfid.mode_to_arm">
                    This fob will arm the following mode:
                  </HeadlineText>
                  <Field
                    component={Select}
                    name="mode_id">
                    {modes.map((mode, i) => (
                      <MenuItem key={i} value={mode.id}>{mode.name}</MenuItem>
                    ))}
                  </Field>
                  <br />
                  <ParagraphText i18nKey="AddRfid:add_rfid.mode_node">
                    Note: active fobs can disarm any mode, but will only arm the mode chosen above.
                  </ParagraphText>
                </>
              )}
              <HeadlineText i18nKey="AddRfid:add_rfid.permissions">
                Permissions of this fob:
              </HeadlineText>
              <ParagraphText i18nKey="AddRfid:add_rfid.permissions_description">
                With the selector below, you can choose if and when this fob has permission to arm or disarm the system.
              </ParagraphText>
              <Field
                component={Scheduler}
                name="scheduler" />
              <AutoSubmit />
            </FormControl>
          </Form>
        )}
      </Formik>
    </Container>
  ), [_onSubmit, classes.centered, classes.container, classes.icon, initialValues, modes, t]);
}

export default Rfid;
