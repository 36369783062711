
import {
  AccessSensor,
  DoorLock,
  DoorPanel,
  FirstAlertSmokeAlarm,
  GlassBreak1 as GlassBreak,
  CategoryKeyPad as Keypad,
  Light,
  MotionSensor,
  NestProtect,
  PanicButton,
  RemoteControl,
  SercommAccessSensor,
  SercommMotionSensor,
  Siren,
  SmokeAlarm,
  Switch,
  Settings as Unknown,
  WaterSensor,
} from '@scout-app/assets/icons';

import Images from './Images';

// TODO - export function to allow for overrides
export default {
  door_panel: {
    name: 'Door Panel',
    type: 'door_panel',
    default_state: 'close',
    image: Images.defer('DEVICES', 'DOOR_PANEL'),
    icon: () => DoorPanel,
    iconSize: () => 40,
    state_images: {
      open: Images.defer('DOOR_PANEL', 'OPEN'),
      close: Images.defer('DOOR_PANEL', 'CLOSE'),
    },
    settings: [
      {
        name: "'Chirp' when sensor opens",
        key: 'chirp',
      },
      {
        name: "'Chirp' hub when sensor opens",
        key: 'chirp_hub',
      },
    ],
    can_test: true,
    can_trigger: true,
    reports_state: true,
  },
  access_sensor: {
    name: 'Access Sensor',
    type: 'access_sensor',
    default_state: 'close',
    image: device =>
      Images.get(
        'DEVICES',
        device.isSercomm() ? 'SERCOMM.ACCESS_SENSOR' : 'ACCESS_SENSOR',
      ),
    icon: device =>
      device.isSercomm() ? SercommAccessSensor : AccessSensor,
    iconSize: device => (device.isSercomm() ? 54 : 36),
    state_images: {
      open: Images.defer('ACCESS_SENSOR', 'OPEN'),
      close: Images.defer('ACCESS_SENSOR', 'CLOSE'),
    },
    settings: [
      {
        name: "'Chirp' hub when sensor opens",
        key: 'chirp_hub',
      },
    ],
    can_test: true,
    can_trigger: true,
    reports_state: true,
  },
  motion_sensor: {
    name: 'Motion Sensor',
    type: 'motion_sensor',
    default_state: 'motion_stop',
    image: device =>
      Images.get(
        'DEVICES',
        device.isSercomm() ? 'SERCOMM.MOTION_SENSOR' : 'MOTION_SENSOR',
      ),
    icon: device =>
      device.isSercomm() ? SercommMotionSensor : MotionSensor,
    iconSize: device => (device.isSercomm() ? 54 : 40),
    state_images: {
      motion_start: Images.defer('MOTION_SENSOR', 'MOTION_START'),
      motion_stop: Images.defer('MOTION_SENSOR', 'MOTION_STOP'),
    },
    can_test: true,
    can_trigger: true,
    reports_state: true,
  },
  nest_protect: {
    name: 'Nest Protect',
    type: 'nest_protect',
    default_state: 'ok',
    icon: () => NestProtect,
    iconSize: () => 64,
    iconStyle: () => ({}),
    is_environmental: true,
    reports_state: true,
  },
  outlet: {
    name: 'Outlet',
    type: 'outlet',
    icon: () => Unknown,
    iconSize: () => 48,
    iconStyle: () => ({}),
    reports_state: true,
  },
  switch: {
    name: 'Switch',
    type: 'switch',
    default_state: 'on',
    icon: () => Switch,
    image: Images.defer('DEVICES', 'SWITCH'),
    reports_state: true,
  },
  dimmer_switch: {
    name: 'Dimmer Switch',
    type: 'dimmer_switch',
    icon: () => Switch,
    iconSize: () => 48,
    iconStyle: () => ({}),
    reports_state: true,
  },
  light_bulb: {
    name: 'Light Bulb',
    type: 'light_bulb',
    icon: () => Light,
    iconSize: () => 48,
    iconStyle: () => ({}),
    reports_state: true,
  },
  smoke_alarm: {
    name: 'Smoke Alarm',
    type: 'smoke_alarm',
    image: device =>
      Images.get(
        'DEVICES',
        device.isSercomm() ? 'SERCOMM.SMOKE_ALARM' : 'SMOKE_ALARM',
      ),
    imageStyle: device =>
      device.isSercomm()
        ? {
            marginTop: -25,
            marginBottom: -20,
          }
        : {},
    icon: device =>
      device.isSercomm() ? SmokeAlarm : FirstAlertSmokeAlarm,
    iconSize: () => 54,
    default_state: 'ok',
    state_images: {
      testing: Images.defer('SMOKE_ALARM', 'TESTING'),
      smoke: Images.defer('SMOKE_ALARM', 'SMOKE'),
      co: Images.defer('SMOKE_ALARM', 'CO'),
      ok: Images.defer('SMOKE_ALARM', 'OK'),
    },
    can_test: true,
    is_environmental: true,
    reports_state: true,
  },
  glass_break: {
    name: 'Glass Break Detector',
    type: 'glass_break',
    image: device =>
      Images.get(
        'DEVICES',
        device.hasManufacturer('gocontrol')
          ? 'GLASS_BREAK.GOCONTROL'
          : 'GLASS_BREAK.JOHNSON_CONTROLS',
      ),
    imageStyle: () => ({
      marginTop: -15,
      marginBottom: -20,
    }),
    icon: () => GlassBreak,
    default_state: 'ok',
    state_images: {
      testing: Images.defer('GLASS_BREAK', 'TESTING'),
      ok: Images.defer('GLASS_BREAK', 'OK'),
      shatter: Images.defer('GLASS_BREAK', 'SHATTER'),
    },
    can_test: true,
    can_trigger: true,
    reports_state: true,
  },
  door_lock: {
    name: 'Door Lock',
    type: 'door_lock',
    icon: () => DoorLock,
    iconSize: () => 58,
    image: device =>
      Images.get(
        'DEVICES',
        device.hasManufacturer('yale') ? 'DOOR_LOCK.YALE' : 'DOOR_LOCK.KWIKSET',
      ),
    imageStyle: () => ({
      marginTop: -15,
      marginBottom: -30,
    }),
    reports_state: true,
  },
  key_pad: {
    name: 'Keypad',
    type: 'key_pad',
    image: Images.defer('DEVICES', 'KEYPAD'),
    icon: () => Keypad,
    iconSize: () => 46,
  },
  take_over: {
    name: 'Takeover',
    type: 'take_over',
    icon: () => Unknown,
    iconSize: () => 48,
    iconStyle: () => ({}),
  },
  unknown: {
    icon: () => Unknown,
    iconSize: () => 48,
    iconStyle: () => ({}),
  },
  water_sensor: {
    name: 'Water Sensor',
    type: 'water_sensor',
    image: Images.defer('DEVICES', 'WATER_SENSOR'),
    icon: () => WaterSensor,
    iconSize: () => 54,
    default_state: 'dry',
    state_images: {
      dry: Images.defer('WATER_SENSOR', 'DRY'),
      wet: Images.defer('WATER_SENSOR', 'WET'),
    },
    can_test: true,
    is_environmental: true,
    reports_state: true,
  },
  panic_button: {
    name: 'Panic Button',
    type: 'panic_button',
    image: Images.defer('DEVICES', 'PANIC_BUTTON'),
    icon: () => PanicButton,
    iconSize: () => 48,
    can_test: true,
  },
  remote_control: {
    name: 'Remote Control',
    type: 'remote_control',
    image: Images.defer('DEVICES', 'REMOTE_CONTROL'),
    imageStyle: () => ({
      marginTop: -35,
      marginBottom: -30,
    }),
    icon: () => RemoteControl,
    iconSize: () => 48,
    can_test: true,
  },
  siren: {
    name: 'Siren',
    type: 'siren',
    image: Images.defer('DEVICES', 'SIREN'),
    imageStyle: () => ({
      marginTop: -35,
      marginBottom: -35,
    }),
    icon: () => Siren,
    iconSize: () => 36,
    settings: [
      {
        name: 'Enable siren during intrusion and environmental alarms', // TODO - confirm text and key, ensure text is translated
        key: 'siren_on_alarm',
      },
    ],
  },
};
