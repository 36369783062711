import React, {useMemo} from 'react';
import {Field, Form, Formik} from 'formik';

import {HeaderText} from '../../elements';
import Slider from '../form/Slider';

const noop = () => {};

function Dimming(props) {
  const {device, onChange, paywall = false, value} = props;

  const fields = useMemo(
    () => [
      {
        name: 'text',
        interactive: false,
        component: (
          <HeaderText i18nKey="Device:device.optional_settings">
            Optional Device Settings:
          </HeaderText>
        ),
      },
      {
        name: 'dimmer_switch',
        component: ({value: {state, level}, setValue, setTouched, ...rest}) => (
          <Slider
            {...rest}
            disabled={paywall || device.isOffline()}
            state={state}
            level={level}
            onSwitch={on => {
              const state = on ? 'on' : 'off';
              setTouched(true);
              setValue({
                level: on ? 100 : 0,
                state,
              });
              onChange({state});
            }}
            onLevel={level => {
              setTouched(true);
              setValue({level, state});
              onChange({level});
            }}
          />
        ),
      },
    ],
    [device, onChange, paywall],
  );

  return useMemo(
    () => (
      <Form
        fields={fields}
        initialValues={value}
        onSubmit={noop}
        renderSubmit={noop}
        unsetSubmittingTimeout={0}
      />
    ),
    [fields, value],
  );
}

Dimming.fieldName = 'dimmable_field';

Dimming.getFieldProps = ({
  device,
  onChange,
  onChangeValue,
  paywall,
  styles,
}) => ({
  device,
  onChange,
  onChangeValue,
  paywall,
  styles,
});

Dimming.populateInitialValues = device => ({
  dimmer_switch: {
    state: device.state,
    level: device.level,
  },
});

export default Dimming;
