import React, {createContext, useCallback, useContext, useMemo} from 'react';

import Colors from '../constants/Colors';
import Overrides from '../constants/Overrides';

const context = createContext({
  appId: null,
  config: {},
  coreDevices: [],
  getColor: function getColor() {},
  initialDevices: [],
  ssidPrefix: null,
  ssidRegExp: null,
  supportEmail: null,
  supportPhoneNumber: null,
  vendor: {},
  vendorName: null,
});

const {Provider} = context;

export function useConfig() {
  return useContext(context);
}

export default function ConfigProvider({app: config, children}) {
  const appId = useMemo(() => Overrides.appId(), []); // TODO - add to scout-app and read directly from config
  const coreDevices = useMemo(() => Overrides.coreDevices(), []); // TODO - add to scout-app and read directly from config
  const getColor = useCallback(name => Colors.get(name), []); // TODO - move from Colors to live entirely in config
  const initialDevices = useMemo(() => Overrides.initialDevices(), []); // TODO - add to scout-app and read directly from config
  const ssidPrefix = useMemo(() => Overrides.ssidPrefix(), []); // TODO - add to scout-app and read directly from config
  const ssidRegExp = useMemo(() => Overrides.ssidRegExp(), []); // TODO - add to scout-app and read directly from config
  const supportEmail = useMemo(() => Overrides.supportEmail(), []); // TODO - add to scout-app and read directly from config
  const supportPhoneNumber = useMemo(() => Overrides.supportPhoneNumber(), []); // TODO - add to scout-app and read directly from config
  const vendor = useMemo(() => Overrides.get('vendor'), []); // TODO - add to scout-app and read directly from config
  const vendorName = useMemo(() => Overrides.vendorName(), []); // TODO - add to scout-app and read directly from config
  const value = useMemo(
    () => ({
      appId,
      config,
      coreDevices,
      getColor,
      initialDevices,
      ssidPrefix,
      ssidRegExp,
      supportEmail,
      supportPhoneNumber,
      vendor,
      vendorName,
    }),
    [
      appId,
      config,
      coreDevices,
      getColor,
      initialDevices,
      ssidPrefix,
      ssidRegExp,
      supportEmail,
      supportPhoneNumber,
      vendor,
      vendorName,
    ],
  );
  return <Provider value={value}>{React.Children.only(children)}</Provider>;
}
