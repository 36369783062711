import {
  Amazon,
  Google,
  Hue,
  Ifttt,
  Logitech,
  Lifx,
  Nest,
} from '@scout-app/assets/icons';

import {IFTTT_CHANNEL_KEY} from '../app.json';

export default [
  {
    key: 'nest',
    name: 'Works with Nest',
    icon: Nest,
    iconSize: 28,
  },
  {
    key: 'amazon',
    name: 'Amazon Alexa',
    icon: Amazon,
    iconSize: 18,
  },
  {
    key: 'ifttt',
    name: 'IFTTT',
    onPress: () => window.open(`https://ifttt.com/${IFTTT_CHANNEL_KEY}`, '_blank'),
    icon: Ifttt,
    iconSize: 18,
  },
  {
    key: 'logitech',
    name: 'Logitech Circle',
    icon: Logitech,
    iconSize: 36,
    beta: true,
  },
  {
    key: 'hue',
    name: 'Philips hue',
    type: 'hue',
    icon: Hue,
  },
  {
    key: 'lifx',
    name: 'LIFX',
    type: 'lifx',
    icon: Lifx,
  },
  {
    key: 'google',
    name: 'Google Assistant',
    icon: Google,
    iconSize: 36,
  },
];
