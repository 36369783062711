import BaseModel from './BaseModel';
import Camera from './Camera';
import Device from './Device';
import Hub from './Hub';
import Member from './Member';

const canUseModes = ({cameras = [], hub_id}) => {
  if (hub_id) {
    return true;
  }
  for (let i = 0; i < cameras.length; i++) {
    if (!Camera.isDoorbell(cameras[i])) {
      return true;
    }
  }
  return false;
};

const hasIntegration = (location, ...names) => {
  const connected_accounts = location.connected_accounts || {};
  for (let i = 0, l = names.length; i < l; i++) {
    switch (names[i]) {
      case 'amazon':
        return !!location.amazon_user_id || !!connected_accounts[names[i]];
      case 'hue':
      case 'lifx':
      case 'logitech':
      case 'nest':
      case 'ring':
        if (
          connected_accounts[names[i]] &&
          connected_accounts[names[i]].token
        ) {
          return true;
        }
        break;
      case 'ifttt':
        return (
          !!connected_accounts[names[i]] &&
          !!connected_accounts[names[i]].length
        );
      case 'google':
        return !!connected_accounts[names[i]];
      default:
        break;
    }
  }
  return false;
};

const hasAnyDevice = (location, includeHub = true) => {
  const {camera_ids, cellular_device_ids, device_ids, hub_id} = location;
  const hasCamera = camera_ids && camera_ids.length;
  const hasDevice = device_ids && device_ids.length;
  const hasHub = includeHub && !!hub_id;
  const hasStandaloneDevice = cellular_device_ids && cellular_device_ids.length;
  return hasCamera || hasDevice || hasHub || hasStandaloneDevice;
};

const hasHub = location => {
  const {hub_id} = location;
  return hub_id;
};

const hasCameras = location => {
  const {camera_ids} = location;
  return camera_ids && camera_ids.length;
};

class Location extends BaseModel {
  constructor(json) {
    super(json);
    this.cameras = (this.cameras || []).map(camera => {
      if (!(camera instanceof Camera)) {
        return new Camera(camera);
      }
      return camera;
    });
    this.devices = (this.devices || []).map(device => {
      if (!(device instanceof Device)) {
        return new Device(device);
      }
      return device;
    });
    if (this.hub && !(this.hub instanceof Hub)) {
      this.hub = new Hub(this.hub);
    }
    this.members = (this.members || []).map(member => {
      if (!(member instanceof Member)) {
        return new Member(member);
      }
      return member;
    });
  }

  get admin() {
    return this.member_state === 'admin';
  }

  activeMode() {
    const active = (this.modes || []).filter(
      mode => ['armed', 'arming', 'alarmed'].indexOf(mode.state) > -1,
    )[0];
    return active && active.id;
  }

  hasDeviceType(...types) {
    const devices = this.devices || [];
    for (let i = 0; i < devices.length; i++) {
      if (devices[i].isType(...types)) {
        return true;
      }
    }
    return false;
  }

  hasUserCodes() {
    const user_code_ids = this.user_code_ids || [];
    return !!user_code_ids.length;
  }

  hasCameras() {
    const camera_ids = this.camera_ids || [];
    return !!camera_ids.length;
  }

  hasDoorPanel() {
    return this.hasDeviceType('door_panel');
  }

  hasDoorLock() {
    return this.hasDeviceType('door_lock');
  }

  hasEnvironmentalSensor() {
    return this.hasDeviceType('smoke_alarm', 'water_sensor');
  }

  hasKeypad() {
    return this.hasDeviceType('key_pad');
  }

  hasHub() {
    return !!this.hub;
  }

  canAddFobs() {
    return (this.rfid_ids || []).length > 0 || this.hasDoorPanel();
  }

  canAddUserCodes() {
    return (
      (this.user_code_ids || []).length > 0 ||
      this.hasDeviceType('door_lock', 'key_pad')
    );
  }

  lowBatteries() {
    const devices = this.devices || [];
    let lowBatteries = 0;
    for (let i = 0; i < devices.length; i++) {
      if (devices[i].hasLowBattery()) {
        lowBatteries++;
      }
    }
    return lowBatteries;
  }

  hasSelfMonitoring() {
    return this.plan === 'self' || this.plan === 'pro';
  }

  hasProMonitoring() {
    return this.plan === 'pro';
  }

  hasIntegration(...names) {
    return hasIntegration(this, ...names);
  }

  getIntegration(type) {
    const connected_accounts = this.connected_accounts || {};
    return connected_accounts[type] || {};
  }

  hasDevice(device_id) {
    return (this.device_ids || []).indexOf(device_id) > -1;
  }

  hasLightingIntegration() {
    return this.hasIntegration('hue', 'lifx');
  }

  hasAnyDevice(includeHub) {
    return hasAnyDevice(this, includeHub);
  }

  canUseModes() {
    return canUseModes(this);
  }

  get triggerable_devices() {
    return (this.devices || []).filter(device => device.canTrigger());
  }

  get scout_cameras() {
    return (this.cameras || []).filter(camera => Camera.isScout(camera));
  }

  get triggerable_cameras() {
    return (this.cameras || []).filter(
      camera => Camera.isScout(camera) && !Camera.isDoorbell(camera),
    );
  }
}

Location.canUseModes = canUseModes;
Location.hasAnyDevice = hasAnyDevice;
Location.hasIntegration = hasIntegration;
Location.hasHub = hasHub;
Location.hasCameras = hasCameras;

export default Location;
