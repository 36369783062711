import {useCallback, useEffect, useRef, useState} from 'react';

import usePusher from './usePusher';

export default function useRemoteTest(location_id, device_id) {
  const [deviceState, setDeviceState] = useState('ok');
  const timeoutRef = useRef(null);

  const onTrigger = useCallback(
    (event, data) => {
      if (
        device_id &&
        event === 'device' &&
        data.event === 'triggered' &&
        data.id === device_id
      ) {
        setDeviceState('testing');
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          setDeviceState('ok');
        }, 1000);
      }
    },
    [device_id],
  );

  useEffect(
    () => {
      return () => {
        clearTimeout(timeoutRef.current);
      };
    },
    [],
  );

  usePusher(location_id, 'device', onTrigger);

  return deviceState;
}
