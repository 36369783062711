import React, {useMemo} from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import AddButton from './AddButton';
import Member from './Member';

function Members({admin = true, members, onPress, onAddMember}) {
  return useMemo(
    () => (
      <List>
        {(members || []).map((member, i) => (
          <Member key={i} admin={admin} member={member} onPress={onPress} />
        ))}
        {admin && (
          <ListItem onClick={onAddMember}>
            <AddButton i18nKey="Members:add_member">
              add member
            </AddButton>
          </ListItem>
        )}
      </List>
    ),
    [admin, members, onAddMember, onPress],
  );
}

export default Members;
