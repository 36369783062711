import React, {useMemo} from 'react';

import clsx from 'clsx';

import Colors from '../../constants/Colors';

import Detail from './Detail';
import Icon from './Icon';
import Slideshow from './Slideshow';

import useStyleSheet from '../../hooks/useStyleSheet';

import Activity from '../../lib/Activity';

function ActivityItem(props) {
  const styleSheet = useMemo(() => {
    const colors = Activity.getStyle(props.item);
    return {
      row: colors.row ? {
        backgroundColor: Colors.get(colors.row),
      } : {},
      text: colors.text ? {
        color: Colors.get(colors.text),
      } : {},
    };
  }, [props.item]);
  const styles = useStyleSheet(styleSheet);
  return useMemo(() => {
    if(props.item?.type === 'slideshow') {
      return (<Slideshow {...props} />);
    }
    return (
      <div className={clsx(props.classes.item, styles.row)}>
        <Icon {...props} />
        <Detail {...props} style={styles.text} />
      </div>
    );
  }, [props, styles.row, styles.text]);
}

export default ActivityItem;
