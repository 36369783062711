import React, {useMemo} from 'react';

import Modes from '../components/modes/Modes';
import Warning from '../components/Warning';

import ParagraphText from '../elements/ParagraphText';

import useStyleSheet from '../hooks/useStyleSheet';

const disarmed = ({state}) => state === 'disarmed';

const quickAccessDeviceTypes = ['door_lock'];

const styleSheet = (theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  bold: {
    fontWeight: 'bold',
  },
});

function Home({
  admin,
  authorization,
  hostName,
  location,
  onAddMode,
  onPressCamera,
  onPressDevice,
  onPressMode,
  onLongPressMode,
  onSiren,
  optimistic,
  paywall,
  setupMonitoring,
}) {
  const classes = useStyleSheet(styleSheet);

  const {cameras, modes} = location;

  const canUseModes = useMemo(() => location.canUseModes(), [location]);

  const hasCameras = useMemo(() => cameras.length > 0, [cameras.length]);

  const sortedModes = useMemo(() => {
    const head = modes.slice(0, 3);
    const tail = modes.slice(3);
    return head.concat(
      tail.sort((m1, m2) => {
        if (!disarmed(m1)) {
          return -1;
        }
        if (!disarmed(m2)) {
          return 1;
        }
        return 0;
      }),
    );
  }, [modes]);

  const modeSliderProps = useMemo(
    () => ({
      admin,
      location,
      modes: sortedModes,
      onAddMode,
      onLongPress: onLongPressMode,
      onPress: onPressMode,
      paywall,
    }),
    [
      admin,
      location,
      onAddMode,
      onLongPressMode,
      onPressMode,
      paywall,
      sortedModes,
    ],
  );

  const cameraSliderProps = useMemo(
    () => ({
      authorization,
      cameras,
      hostName,
      vertical: !location.hasDoorLock(),
    }),
    [authorization, cameras, hostName, location],
  );

  const modesProps = useMemo(
    () => ({
      admin,
      modes,
      onPress: onPressMode,
      onLongPress: onLongPressMode,
      onAddMode,
      paywall,
    }),
    [admin, modes, onAddMode, onLongPressMode, onPressMode, paywall],
  );

  const header = useMemo(() => {
    if (hasCameras && !canUseModes) {
      return (
        <ParagraphText i18nKey="Home:home.camera_instructions" marginBottom="1em">
          <span className={classes.bold}>Tap</span> to view.
        </ParagraphText>
      );
    }
    if (admin) {
      return (
        <ParagraphText i18nKey="Home:home.admin_mode_instructions" marginBottom="1em">
          <span className={classes.bold}>Tap</span> to arm. <span className={classes.bold}>Hold</span> to edit.
        </ParagraphText>
      );
    }
    return (
      <ParagraphText i18nKey="Home:home.member_mode_instructions" marginBottom="1em">
        <span className={classes.bold}>Tap</span> to arm.
      </ParagraphText>
    );
  }, [admin, canUseModes, classes.bold, hasCameras]);

  const content = useMemo(() => {
    // if (hasCameras) {
    //   return (
    //     <>
    //       {canUseModes && <ModeSlider {...modeSliderProps} />}
    //       <CameraSnapshotSlider {...cameraSliderProps} />
    //     </>
    //   );
    // }
    return <Modes {...modesProps} />;
  }, [modesProps]);

  const warning = useMemo(
    () => (
      <Warning
        location={location}
        onMonitoring={setupMonitoring}
        onSiren={onSiren}
      />
    ),
    [location, onSiren, setupMonitoring],
  );

  const quickAccessDevices = useMemo(
    () =>
      location.devices.filter(({type}) =>
        quickAccessDeviceTypes.includes(type),
      ),
    [location.devices],
  );

  const quickAccess = null;
  // const quickAccess = useMemo(
  //   () => (
  //     <QuickAccess
  //       devices={quickAccessDevices}
  //       optimistic={optimistic}
  //       onPress={onPressDevice}
  //     />
  //   ),
  //   [onPressDevice, optimistic, quickAccessDevices],
  // );

  return useMemo(
    () => (
      <div className={classes.container}>
        <>
          {warning}
          {header}
        </>
        {content}
        {quickAccess}
      </div>
    ),
    [classes.container, content, header, warning],
  );
}

export default Home;
