import React, {useCallback, useMemo, useRef} from 'react';
import {Field, Form, Formik} from 'formik';

import {Switch} from 'formik-material-ui';

import {Text, HeaderText} from '../../elements';

function DeviceSettings(props) {
  const {
    deviceType,
    onChangeValue,
    value,
    setValue,
    setTouched,
  } = props;

  const handleSubmit = useRef(null);

  const fields = useMemo(
    () =>
      [
        {
          name: 'text',
          interactive: false,
          component: (
            <HeaderText i18nKey="Device:device.optional_settings">
              Optional Device Settings:
            </HeaderText>
          ),
        },
      ].concat(
        deviceType.settings.map((setting, i) => ({
          name: setting.key,
          component: inputProps => (
            <Switch {...inputProps} key={i} onSwitch={handleSubmit.current}>
              <Text i18nKey={`Device:device.${setting.key}`}>
                {setting.name}
              </Text>
            </Switch>
          ),
        })),
      ),
    [deviceType.settings],
  );

  const onSubmit = useCallback(
    data => {
      setTouched(true);
      setValue(data);
      onChangeValue(data);
    },
    [onChangeValue, setTouched, setValue],
  );

  const renderSubmit = useCallback(({onPress}) => {
    handleSubmit.current = onPress;
    return null;
  }, []);

  return useMemo(
    () => (
      <Form
        fields={fields}
        initialValues={value}
        onSubmit={onSubmit}
        renderSubmit={renderSubmit}
        unsetSubmittingTimeout={0}
      />
    ),
    [fields, onSubmit, renderSubmit, value],
  );
}

DeviceSettings.fieldName = 'settings_field';

DeviceSettings.getFieldProps = ({deviceType, onChangeValue, styles}) => ({
  deviceType,
  onChangeValue,
  styles,
});

DeviceSettings.populateInitialValues = device => {
  const deviceType = device.getType();
  return deviceType.settings.reduce((result, setting) => ({
    ...result,
    [setting.key]: device[setting.key],
  }), {});
};

export default DeviceSettings;
