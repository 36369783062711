import React, {useMemo} from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import AddButton from './AddButton';
import UserCode from './UserCode';

function UserCodes({admin = true, user_codes, onPress, onAddUserCode}) {
  return useMemo(
    () => (
      <List>
        {(user_codes || []).map((user_code, i) => (
          <UserCode key={i} admin={admin} user_code={user_code} onPress={onPress} />
        ))}
        {admin && (
          <ListItem onClick={onAddUserCode}>
            <AddButton i18nKey="Common:common.add_user_code">
              add use code
            </AddButton>
          </ListItem>
        )}
      </List>
    ),
    [admin, onAddUserCode, onPress, user_codes],
  );
}

export default UserCodes;
