import React, {useCallback, useMemo} from 'react';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Colors from '../constants/Colors';
import IntegrationTypes from '../constants/IntegrationTypes';

import Text from '../elements/Text';

import Camera from './Camera';
import Device from './Device';
import Hub from './Hub';
import Integration from './Integration';
import LongPressable from './LongPressable';

import useModel from '../hooks/useModel';
import useStyleSheet from '../hooks/useStyleSheet';

import Location from '../models/Location';

const styleSheet = (theme) => {
  return {
    group: {
      paddingLeft: '1vw',
      paddingRight: '1vw',
    },

    header: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 10,
    },
    flex: {
      flex: 1,
    },
    filter: {
      marginRight: 10,
      color: Colors.get('item'),
    },
    toggleIcon: {
      fontSize: 20,
      color: Colors.get('item'),
    },
    hr: {
      height: 1,
      backgroundColor: '#eee',
      marginTop: 5,
      marginBottom: 10,
    },

    grid: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      //paddingLeft: '1vw',
    },
    toggle: {
      position: 'relative',
      height: '32vw',
      width: '32vw',
      // marginLeft: '0.5vw',
      marginLeft: '1vw',
      display: 'flex',
      borderStyle: 'solid',
      borderWidth: 1,
      // marginRight: theme.spacing(1),
      marginTop: '1vw',
      overflow: 'hidden',
      backgroundColor: '#fff',
      borderColor: Colors.get('item'),
      // flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        height: '15.5vw',
        width: '15.5vw',
      }
    },
    info: {
      backgroundColor: 'transparent',
      fontSize: 12,
      color: Colors.get('item'),
    },
    state: {
      position: 'absolute',
      right: 0,
      top: 0,
      paddingTop: 2,
      paddingRight: 4,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      backgroundColor: 'transparent',
      color: Colors.get('item'),
    },
    name: {
      position: 'absolute',
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: 'transparent',
      fontSize: 12,
      paddingBottom: 2,
      paddingLeft: 4,
      paddingRight: 4,
      color: Colors.get('item'),
    },
    icon: {
      backgroundColor: 'transparent',
      color: Colors.get('item'),
      fontSize: '12vw',
      [theme.breakpoints.up('sm')]: {
        fontSize: '6vw',
      }
    },
    temperature: {
      position: 'absolute',
      top: 0,
      left: 0,
      paddingTop: 2,
      paddingLeft: 4,
      backgroundColor: 'transparent',
      color: Colors.get('item'),
    },
    lowBattery: {
      backgroundColor: 'transparent',
      color: Colors.get('item'),
    },
    red: {
      borderColor: Colors.get('alarmed'),
      color: Colors.get('alarmed'),
    },
    yellow: {
      borderColor: Colors.get('warningYellow'),
      color: Colors.get('warningYellow'),
    },
    filtering: {
      opacity: 0.4,
    },

    amazon: {
      fontSize: '18vw',
      [theme.breakpoints.up('sm')]: {
        fontSize: '9vw',
      }
    },
    google: {
      fontSize: '10vw',
      [theme.breakpoints.up('sm')]: {
        fontSize: '5vw',
      }
    },
    logitech: {
      fontSize: '16vw',
      [theme.breakpoints.up('sm')]: {
        fontSize: '8vw',
      }
    },
  };
};

function DeviceGroup(props) {
  const {
    filtering,
    group,
    hidden,
    onCamera,
    onDevice,
    onEdit,
    onHub,
    onIntegration,
    onLongPressCamera,
    onLongPressDevice,
    onVisibilityChange,
  } = props;
  const classes = useStyleSheet(styleSheet);
  const location = useModel(props.location, Location);
  const {admin, devices = [], cameras = [], hub} = location;
  const {name, device_ids = []} = group || {};

  const _onCamera = useCallback(
    camera => {
      if (filtering) {
        if (onDevice) {
          onDevice(camera);
        }
      } else if (onCamera) {
        onCamera(camera);
      }
    },
    [filtering, onCamera, onDevice],
  );

  const _onEdit = useCallback(() => {
    if (onEdit) {
      onEdit(group || {});
    }
  }, [group, onEdit]);

  const _onVisibilityChange = useCallback(() => {
    if (onVisibilityChange) {
      onVisibilityChange(hidden !== false);
    }
  }, [hidden, onVisibilityChange]);

  const ToggleIcon = hidden ? KeyboardArrowDownIcon : KeyboardArrowUpIcon;

  // TODO - migrate to Accordion
  return useMemo(() => {
    let index = 0;
    return (
      <>
        {!filtering && (
          <div className={classes.group}>
            <LongPressable
              onClick={_onVisibilityChange}
              onLongPress={_onEdit}
              className={classes.header}>
              <Text
                i18nKey={group ? undefined : 'Devices:all_devices'}
                className={classes.flex}>
                {group ? name : 'All Devices'}
              </Text>
              {false && !group && admin && (
                <Text
                  i18nKey="Devices:filter"
                  className={classes.filter}
                  onClick={_onEdit}>
                  Filter
                </Text>
              )}
              <ToggleIcon className={classes.toggleIcon} />
            </LongPressable>
            <div className={classes.hr} />
          </div>
        )}
        {!hidden && (
          <div className={classes.grid}>
            {!!hub && (!group || device_ids.includes(hub.id)) && (
              <Hub
                key={hub.id}
                classes={classes}
                hub={hub}
                index={index++}
                filtering={filtering}
                selected={filtering && device_ids.includes(hub.id)}
                onPress={onHub}
              />
            )}
            {devices.map(device => {
              if (!group || filtering || device_ids.includes(device.id)) {
                return (
                  <Device
                    key={device.id}
                    classes={classes}
                    device={device}
                    filtering={filtering}
                    index={index++}
                    selected={filtering && device_ids.includes(device.id)}
                    onPress={onDevice}
                    onLongPress={onLongPressDevice}
                  />
                );
              }
              return null;
            })}
            {cameras.map(camera => {
              if (!group || filtering || device_ids.includes(camera.id)) {
                return (
                  <Camera
                    key={camera.id}
                    camera={camera}
                    classes={classes}
                    filtering={filtering}
                    index={index++}
                    selected={filtering && device_ids.includes(camera.id)}
                    onPress={_onCamera}
                    onLongPress={onLongPressCamera}
                  />
                );
              }
              return null;
            })}
            {!group && IntegrationTypes.map(integration => {
              if (location.hasIntegration(integration.key)) {
                return (
                  <Integration
                    key={integration.key}
                    classes={classes}
                    index={index++}
                    integration={integration}
                    onPress={onIntegration}
                  />
                );
              }
              return null;
            })}
          </div>
        )}
      </>
    );
  }, [_onCamera, _onEdit, _onVisibilityChange, admin, cameras, classes, device_ids, devices, filtering, group, hidden, hub, location, name, onDevice, onHub, onIntegration, onLongPressCamera, onLongPressDevice]);
}

export default DeviceGroup;
