import React, {useCallback, useMemo, useState} from 'react';

import {Field, Form, Formik} from 'formik';
import {FormControl} from '@material-ui/core';

import {Button, H1Text, ParagraphText} from '../elements';
import {EmailInput} from '../components/login';

function ForgotPassword({onBack, onSubmit}) {
  const [sent, setSent] = useState(false);

  const onSubmitForm = useCallback(
    ({email}) =>
      onSubmit(email)
        .then(() => setSent(true))
        .catch(() => {}),
    [onSubmit],
  );

  return useMemo(() => (
    <Formik
      initialValues={{email: ''}}
      validate={() => {}}
      onSubmit={onSubmitForm}>
      {({ submitForm, isSubmitting }) => (
        <Form>
          <H1Text
            i18nKey="ForgotPassword:forgot_password.recover_password"
            color="login">
            Recover Password
          </H1Text>
          <br />
          {sent && (
            <ParagraphText
              i18nKey="ForgotPassword:forgot_password.confirmation"
              color="login">
              If an account with the email provided exists you will be receiving
              an email momentarily with a link to update your password.
            </ParagraphText>
          )}
          {!sent && (
            <FormControl fullWidth>
              <Field
                component={EmailInput}
                name="email" />
              <br />
              <Button
                variant="contained"
                color="primary"
                i18nKey={
                  isSubmitting ? 'Common:common.recovering' : 'Common:common.recover'
                }
                onClick={submitForm}
                disabled={isSubmitting}>
                {isSubmitting ? 'Recovering...' : 'Recover'}
              </Button>
            </FormControl>
          )}
        </Form>
      )}
    </Formik>
  ), [onSubmitForm, sent]);
}

export default ForgotPassword;
