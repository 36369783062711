import React, {useMemo} from 'react';
import {Provider} from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from '@material-ui/core/styles';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {BrowserRouter} from 'react-router-dom';
import {SnackbarProvider} from 'notistack';

import MomentUtils from '@date-io/moment';

import initI18n from '../lib/i18next';

import {
  AppBarProvider,
  ConfigProvider,
  DialogProvider,
  TranslationProvider,
} from '../context';

import getAppNavigator from '../navigators/app';
import getTheme from '../lib/theme';
import moment from '../lib/moment';

export default app => {
  initI18n(app);

  const {store} = app;

  return function AppContainer() {
    const App = useMemo(getAppNavigator, []);
    const theme = useMemo(getTheme, []);
    return (
      <ConfigProvider app={app}>
        <AppBarProvider>
          <TranslationProvider>
            <Provider store={store}>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <SnackbarProvider maxSnack={3}>
                  <ThemeProvider theme={theme}>
                    <DialogProvider>
                      <BrowserRouter>
                        <CssBaseline />
                        <App />
                      </BrowserRouter>
                    </DialogProvider>
                  </ThemeProvider>
                </SnackbarProvider>
              </MuiPickersUtilsProvider>
            </Provider>
          </TranslationProvider>
        </AppBarProvider>
      </ConfigProvider>
    );
  };
};
