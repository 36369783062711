import React, {useMemo, useState} from 'react';

import Detail from './Detail';
import Icon from './Icon';

import Activity from '../../lib/Activity';

// TODO - add container styles
// TODO - make swipable
function ActivitySlideshow({item:{items}, ...props}) {
  const [slide, setSlide] = useState(0);
  const current = items[slide];
  return useMemo(() => (
    <div className={props.classes.item}>
      <Icon hasPreview item={current} {...props} />
      <div className={props.classes.slideshow}>
        <Detail item={current} {...props} />
      </div>
    </div>
  ), [current, props]);
}

export default ActivitySlideshow;
