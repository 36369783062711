import React, {createElement, useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {Trans} from 'react-i18next';

import {
  Redirect,
  Route,
  Switch,
  useHistory
} from 'react-router-dom';

import clsx from 'clsx';

import {AppBar, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemText, Toolbar, Typography} from '@material-ui/core';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {Settings as SettingsIcon} from '@scout-app/assets/icons';

import * as actions from '../actions/member';

import AddButton from '../components/AddButton';
import Loading from '../components/Loading';

import MenuItemText from '../elements/MenuItemText';

import useAppBar from '../hooks/useAppBar';
import useActions from '../hooks/useActions';
import useStyleSheet from '../hooks/useStyleSheet';

import {
  AccountDetails,
  AddDevice,
  AddLocation,
  AddMember,
  AddRfid,
  AddUserCode,
  AmazonIntegration,
  Camera,
  Device,
  EditLocation,
  EditProfile,
  GoogleIntegration,
  HubSettings,
  LightingIntegration,
  Location,
  LogitechIntegration,
  Member,
  Mode,
  NotificationSettings,
  Rfid,
  UserCode,
} from '../containers';

const drawerWidth = 240;

const styleSheet = ((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: '#fff',
  },
  rightButton: {
    marginLeft: theme.spacing(2),
    color: '#fff',
  },
  rightIcon: {
    fontSize: 20,
  },
  title: {
    color: '#fff',
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    opacity: 1.0,
    overflow: 'scroll',
  },
  disabled: {
    pointerEvents: 'none',
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    opacity: 0.4,
  },
}));

export default () => {

  const selector = state => ({
    location_id: state.location.id || state.member.locations[0]?.id,
    locations: state.member.locations,
  });

  const subnav = [
    {
      children: createElement(AccountDetails),
      key: 'AccountDetails',
      path: '/profile',
    },
    {
      children: createElement(NotificationSettings),
      key: 'NotificationSettings',
      path: '/notifications',
    }
  ];

  function Dashboard() {
    const history = useHistory();
    const {
      location_id,
      locations,
    } = useSelector(selector);
    const [open, setOpen] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [ready, setReady] = useState(!!location_id);
    const {getLocations, logout} = useActions(actions);
    const {leftButton, rightButton, title} = useAppBar();
    const classes = useStyleSheet(styleSheet);
    const navigate = useCallback(path => {
      setOpen(false);
      history.replace(path);
    }, [history]);
    useEffect(() => {
      if (!loaded) {
        setLoaded(true);
        getLocations().then(() => setReady(true));
      }
    }, [getLocations, loaded]);
    return useMemo(() => {
      if(!ready)
        return (<Loading splash />);
      return (
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}>
            <Toolbar>
              <IconButton
                color="inherit"
                edge="start"
                onClick={leftButton ? leftButton.onClick : () => setOpen(true)}
                className={clsx(classes.menuButton, open && classes.hide)}>
                {leftButton ? <leftButton.icon /> : <SettingsIcon />}
              </IconButton>
              <Typography variant="h6" noWrap className={classes.title}>
                {title}
              </Typography>
              {rightButton && (
                <IconButton
                  color="inherit"
                  edge="end"
                  onClick={rightButton.onClick}
                  className={clsx(classes.rightButton, open && classes.hide)}>
                  <rightButton.icon className={classes.rightIcon} />
                </IconButton>
              )}
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}>
            <div className={classes.drawerHeader}>
              <IconButton onClick={() => setOpen(false)}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <List>
              <ListItem disabled>
                <MenuItemText i18nKey="LeftDrawer:locations">
                  locations
                </MenuItemText>
              </ListItem>
              {locations.map((location, i) => (
                <ListItem button key={i} onClick={() => navigate(`/locations/${location.id}`)}>
                  <ListItemText primary={location.name} />
                </ListItem>
              ))}
              {false && (
                <ListItem button onClick={() => navigate(`/locations/new`)}>
                  <AddButton i18nKey="LeftDrawer:add_location">
                    add location
                  </AddButton>
                </ListItem>
              )}
              <ListItem disabled>
                <MenuItemText i18nKey="LeftDrawer:settings" marginTop="1em">
                  settings
                </MenuItemText>
              </ListItem>
              {subnav.map(({key, path}) => (
                <ListItem button key={key} onClick={() => navigate(path)}>
                  <Trans i18nKey={`LeftDrawer:${key}`}>
                    <ListItemText primary={key} />
                  </Trans>
                </ListItem>
              ))}
              <ListItem button onClick={logout}>
                <Trans i18nKey="LeftDrawer:sign_out">
                  <ListItemText primary="sign out" />
                </Trans>
              </ListItem>
            </List>
          </Drawer>
          <main className={clsx(classes.content, {
            [classes.disabled]: open,
          })}>
            <div className={classes.drawerHeader} />
            <Switch>
              <Route exact path="/">
                <Redirect to={`/locations/${location_id || 'new'}`} />
              </Route>
              <Route path="/cameras/:camera_id">
                <Camera />
              </Route>
              <Route path="/devices/new">
                <AddDevice />
              </Route>
              <Route path="/devices/:device_id">
                <Device />
              </Route>
              <Route path="/hub">
                <HubSettings />
              </Route>
              <Route path="/integrations/amazon">
                <AmazonIntegration />
              </Route>
              <Route path="/integrations/google">
                <GoogleIntegration />
              </Route>
              <Route path="/integrations/hue">
                <LightingIntegration type="hue" />
              </Route>
              <Route path="/integrations/lifx">
                <LightingIntegration type="lifx" />
              </Route>
              <Route path="/integrations/logitech">
                <LogitechIntegration />
              </Route>
              <Route path="/locations/new">
                <AddLocation />
              </Route>
              <Route path="/locations/:location_id/edit">
                <EditLocation />
              </Route>
              <Route path="/locations/:location_id">
                <Location />
              </Route>
              <Route path="/members/new">
                <AddMember />
              </Route>
              <Route path="/members/:member_id">
                <Member />
              </Route>
              <Route path="/modes/:mode_id">
                <Mode />
              </Route>
              <Route path="/profile/edit">
                <EditProfile />
              </Route>
              <Route path="/rfids/new">
                <AddRfid />
              </Route>
              <Route path="/rfids/:rfid_id">
                <Rfid />
              </Route>
              <Route path="/user_codes/new">
                <AddUserCode />
              </Route>
              <Route path="/user_codes/:user_code_id">
                <UserCode />
              </Route>
              {subnav.map(props => (
                <Route {...props} />
              ))}
            </Switch>
          </main>
        </div>
      );
    }, [classes.appBar, classes.appBarShift, classes.content, classes.disabled, classes.drawer, classes.drawerHeader, classes.drawerPaper, classes.hide, classes.menuButton, classes.rightButton, classes.rightIcon, classes.root, classes.title, leftButton, location_id, locations, logout, navigate, open, ready, rightButton, title]);
  }

  return Dashboard;
};
