import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Field, Form, Formik} from 'formik';
import {FormControl} from '@material-ui/core';
import {TextField} from 'formik-material-ui';

import {PasswordInput} from '../components/form';
import {EmailInput} from '../components/login';
import SignupHeader from '../components/SignupHeader';

import Button from '../elements/Button';

function Signup({
  headerComponent: Header,
  onSubmit,
}) {
  const {t} = useTranslation('Signup');
  return useMemo(
    () => (
      <Formik
        initialValues={{
          name: '',
          email: '',
          phone_number: '',
          password: '',
          password_confirmation: '',
        }}
        validate={() => {}}
        onSubmit={onSubmit}>
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Header />
            <br />
            <FormControl fullWidth>
              <Field
                component={TextField}
                type="text"
                autoComplete="name"
                name="name"
                placeholder={t('placeholders.name')} />
              <br />
              <Field
                component={EmailInput}
                name="email" />
              <br />
              <Field
                component={TextField}
                type="tel"
                autoComplete="tel"
                name="phone_number"
                placeholder={t('placeholders.phone_number')} />
              <br />
              <Field
                component={PasswordInput}
                name="password"
                autoComplete="new-password"
                placeholder={t('placeholders.password')} />
              <br />
              <Field
                component={PasswordInput}
                name="password_confirmation"
                autoComplete="new-password"
                placeholder={t('placeholders.password_confirmation')} />
              <br />
              <Button
                variant="contained"
                color="primary"
                i18nKey={
                  isSubmitting ? 'Signup:button.signing_up' : 'Signup:button.sign_up'
                }
                onClick={submitForm}
                disabled={isSubmitting}>
                {isSubmitting ? 'signing up...' : 'sign up'}
              </Button>
            </FormControl>
          </Form>
        )}
      </Formik>
    ),
    [onSubmit, t],
  );
}

Signup.defaultProps = {
  headerComponent: SignupHeader,
};

export default Signup;
