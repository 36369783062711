import React, {useCallback, useMemo} from 'react';
import {useParams} from 'react-router-dom';

import * as actions from '../actions/member';
import useActions from '../hooks/useActions';

import Overrides from '../constants/Overrides';

import FileOverride from '../components/FileOverride';

function onError(err) {
  alert(err.message || err);
}

function ResetPassword(props) {
  const params = useParams();
  const {resetPassword} = useActions(actions);
  const {token} = params || {};
  const onSubmit = useCallback(
    ({new_password, new_password_confirmation}, {setSubmitting}) => {
      resetPassword(token, new_password, new_password_confirmation).catch(err => {
        onError(err);
        setSubmitting(false);
      });
    },
    [resetPassword, token],
  );
  const AuthHeader = useMemo(
    () => Overrides.get('components', 'AuthHeader'),
    [],
  );
  return useMemo(
    () => (
      <FileOverride namespace="routes" filename="ResetPassword">
        {Component => (
          <Component
            headerComponent={AuthHeader}
            onSubmit={onSubmit}
          />
        )}
      </FileOverride>
    ),
    [AuthHeader, onSubmit],
  );
}

export default ResetPassword;
