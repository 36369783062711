import React, {useMemo} from 'react';

import {Form, Formik} from 'formik';
import {Container, FormControl} from '@material-ui/core';

import {
  DeviceSettings,
  DeviceTesting,
  Dimming,
  DoorLockSettings,
  EnvironmentalSettings,
  KeypadSettings,
  Naming,
  RemoteControlSettings,
  RemoteControlTesting,
  RfidTesting,
  Switching,
  TakeoverSettings,
} from '../components/device_sections';

import {AutoSubmit} from '../components/form';

import useStyleSheet from '../hooks/useStyleSheet';

const SECTIONS = {
  unknown: [Naming],
  access_sensor: [Naming, DeviceSettings, DeviceTesting],
  dimmer_switch: [Naming, Dimming],
  door_lock: [Naming, DoorLockSettings],
  door_panel: [Naming, DeviceSettings, DeviceTesting, RfidTesting],
  glass_break: [Naming, DeviceTesting],
  key_pad: [Naming, KeypadSettings],
  light_bulb: [Naming, Dimming],
  motion_sensor: [Naming, DeviceTesting],
  outlet: [Naming, Switching],
  panic_button: [Naming, RemoteControlTesting],
  remote_control: [Naming, RemoteControlSettings, RemoteControlTesting],
  siren: [Naming, DeviceSettings],
  smoke_alarm: [Naming, DeviceTesting, EnvironmentalSettings],
  switch: [Naming, Switching],
  take_over: [Naming, TakeoverSettings],
  water_sensor: [Naming, DeviceTesting, EnvironmentalSettings],
};

const styleSheet = (theme) => ({
  container: {
    padding: 24,
  },
})

function Device(props) {
  const classes = useStyleSheet(styleSheet);
  const {device, onSubmit, ...rest} = props;

  const sections = SECTIONS.unknown; //SECTIONS[device.type] || SECTIONS.unknown;

  const initialValues = useMemo(
    () =>
      sections.reduce((result, section) => {
        if (section.interactive === false) {
          return result;
        }
        return {
          ...result,
          [section.fieldName]: section.populateInitialValues(device),
        };
      }, {}),
    [device, sections],
  );

  return useMemo(() => (
    <Container maxWidth="md" className={classes.container}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}>
        {({ submitForm, isSubmitting }) => (
          <Form>
            <FormControl fullWidth>
              {sections.map((Component, i) => <Component key={i} device={device} {...rest} />)}
              <AutoSubmit />
            </FormControl>
          </Form>
        )}
      </Formik>
    </Container>
  ), [classes.container, device, initialValues, onSubmit, rest, sections]);
}

export default Device;
