import {useReducer} from 'react';

function reducer(state, value) {
  return {
    ...state,
    ...value,
  };
}

export default function useSetState(initialState = {}) {
  return useReducer(reducer, initialState);
}
