import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Field, Form, Formik} from 'formik';
import {CheckboxWithLabel} from 'formik-material-ui';
import {FormControl} from '@material-ui/core';

import AutoSubmit from './form/AutoSubmit';
import HeadlineText from '../elements/HeadlineText';

function NotificationSettings({notifications, onSubmit}) {
  const {t} = useTranslation('NotificationSettings');
  return useMemo(
    () => (
      <Formik
        initialValues={notifications}
        validate={() => {}}
        onSubmit={onSubmit}>
        {({ submitForm, isSubmitting }) => (
          <Form>
            <HeadlineText i18nKey="NotificationSettings:notify_me">
              Notify me when the system...
            </HeadlineText>
            <br />
            <FormControl fullWidth>
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                Label={{ label: t('alarm_state_change') }}
                name="alarm_state_change" />
                <br />
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                Label={{ label: t('power_outage') }}
                name="power_outage" />
              <br />
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                Label={{ label: t('lost_sensor') }}
                name="lost_sensor" />
              <AutoSubmit />
            </FormControl>
          </Form>
        )}
      </Formik>
    ),
    [notifications, onSubmit, t],
  );
}

export default NotificationSettings;
