import React, {useMemo} from 'react';

import {HeaderText} from '../../elements';
import Vectors from '../../constants/Vectors';
import Vector from '../pairing/Vector';
import {useConfig, useRfidSwipe, useStyleSheet} from '../../hooks';

const styleSheet = {
  rfidState: {
    width: 160,
    height: 20,
  },
  option: {
    marginTop: 10,
    marginBottom: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  setting: {
    flex: 1,
  },
  active: {

  }
};

function RfidTesting(props) {
  const {location} = props;
  const {getColor} = useConfig();
  const classes = useStyleSheet(styleSheet);
  const rfidState = useRfidSwipe(location.id);
  const fill = useMemo(() => rfidState === 'on' ? getColor('pairingIndicator') : getColor('disarmed'), [getColor, rfidState]);
  const keyFobTest = useMemo(
    () => (
      <div className={classes.option}>
        <HeaderText
          i18nKey="Device:device.key_fob_test"
          className={classes.setting}>
          Key Fob Test:
        </HeaderText>
        <Vector
          source={Vectors.get('DOOR_PANEL', 'RFID_SWIPE')}
          fill={fill} />
      </div>
    ),
    [classes.option, classes.setting, fill],
  );

  return useMemo(() => <div className={classes.section}>{keyFobTest}</div>, [classes.section, keyFobTest]);
}

RfidTesting.interactive = false;

RfidTesting.fieldName = 'test_rfid_field';

RfidTesting.getFieldProps = ({location, styles}) => ({
  location,
  styles,
});

export default RfidTesting;
