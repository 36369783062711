import AddLocation from './AddLocation.json';
import BillingFooter from './BillingFooter.json';
import BillingHeader from './BillingHeader.json';
import Device from './Device.json';
import EditLocation from './EditLocation.json';
import PairingHelp from './PairingHelp.json';

export default {
  AddLocation,
  BillingFooter,
  BillingHeader,
  Device,
  EditLocation,
  PairingHelp,
};
