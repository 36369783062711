import React, {useMemo} from 'react';
import Text from '../../elements/Text';


function ActivityHeader({classes, title}) {
  return useMemo(() => (
    <Text style={classes.date}>{title}</Text>
  ), [classes.date, title]);
}

export default ActivityHeader;
