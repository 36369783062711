import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Field, Form, Formik} from 'formik';
import {Container, FormControl, FormControlLabel} from '@material-ui/core';
import {Switch, TextField} from 'formik-material-ui';

import {
  BoldText,
  Button,
  HeadlineText,
  ParagraphText,
  Text,
} from '../elements';

import {Scheduler} from '../components/form';

import useStyleSheet from '../hooks/useStyleSheet';

const styleSheet = (theme) => ({
  container: {
    padding: 24,
  },
  label: {
    flexGrow: 1,
  }
})

function AddUserCode({
  active = true,
  code = '',
  disarm_on_entry = true,
  hasDoorLock,
  name = '',
  onSubmit,
  schedule,
}) {
  const {t} = useTranslation('AddUserCode');
  const classes = useStyleSheet(styleSheet);
  const initialValues = useMemo(
    () => ({
      name,
      code,
      disarm_on_entry,
      scheduler: {active, schedule},
    }),
    [active, code, disarm_on_entry, name, schedule],
  );
  const _onSubmit = useCallback(
    (formData, formik) => {
      const {
        name,
        code,
        disarm_on_entry,
        scheduler: {active, schedule},
      } = formData;
      onSubmit({
        name,
        code,
        disarm_on_entry,
        active,
        schedule,
      }, formik);
    },
    [onSubmit],
  );
  return useMemo(() => (
    <Container maxWidth="md" className={classes.container}>
      <Formik
        initialValues={initialValues}
        onSubmit={_onSubmit}>
        {({ submitForm, isSubmitting, values }) => (
          <Form>
            <FormControl fullWidth>
              <Field
                component={TextField}
                type="text"
                name="name"
                placeholder={t('add_user_code.name')} />
              <br />
              <Field
                component={TextField}
                type="number"
                name="code"
                placeholder={t('add_user_code.code')} />
              <HeadlineText i18nKey="AddUserCode:add_user_code.permissions">
                Permissions of this code:
              </HeadlineText>
              <ParagraphText i18nKey="AddUserCode:add_user_code.choose_active_settings">
              With the selector below, you can choose if and when this code is active.
              </ParagraphText>
              <Field
                component={Scheduler}
                name="scheduler" />
              {values.scheduler.active && hasDoorLock && (
                <FormControlLabel
                  classes={classes}
                  label={
                    <>
                      <BoldText i18nKey="AddUserCode:add_user_code.disarm_title">
                        Disarm when entered
                      </BoldText>
                      <Text i18nKey="AddUserCode:add_user_code.disarm_description">
                        Disarm active mode if lock is allowed to disarm.
                      </Text>
                    </>
                  }
                  control={
                    <Field component={Switch} name="disarm_on_entry" type="checkbox" />
                  }
                  labelPlacement="start" />
              )}
              <br />
              <Button
                variant="contained"
                color="primary"
                i18nKey={isSubmitting ? 'Common:common.saving' : 'Common:common.save'}
                onClick={submitForm}
                disabled={isSubmitting}>
                {isSubmitting ? 'saving...' : 'save'}
              </Button>
            </FormControl>
          </Form>
        )}
      </Formik>
    </Container>
  ), [_onSubmit, classes, hasDoorLock, initialValues, t]);
}

export default AddUserCode;
