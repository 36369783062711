import thunk from 'redux-thunk';

import authentication from './authentication';
import pusher from './pusher';

const middleware = [
  thunk,
  authentication,
  pusher,
];

export default middleware;
