import React, {useMemo} from 'react';

import {Field, Form, Formik} from 'formik';
import {FormControl} from '@material-ui/core';

import AuthHeader from '../components/AuthHeader';
import {EmailInput, PasswordInput} from '../components/login';

import Button from '../elements/Button';

import useConfig from '../hooks/useConfig';

function Login({
  headerComponent: Header,
  onForgotPassword,
  onLogin,
  onSignup,
}) {
  const {vendorName} = useConfig();
  return useMemo(
    () => (
      <Formik
        initialValues={{email: '', password: ''}}
        validate={() => {}}
        onSubmit={onLogin}>
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Header vendorName={vendorName} />
            <br />
            <FormControl fullWidth>
              <Field
                component={EmailInput}
                name="email" />
              <br />
              <Field
                component={PasswordInput}
                name="password"
                onButtonPress={onForgotPassword} />
              <br />
              <Button
                variant="contained"
                color="primary"
                i18nKey={
                  isSubmitting ? 'Common:common.signing_in' : 'Common:common.signin'
                }
                onClick={submitForm}
                disabled={isSubmitting}>
                {isSubmitting ? 'signing in...' : 'sign in'}
              </Button>
            </FormControl>
          </Form>
        )}
      </Formik>
    ),
    [onForgotPassword, onLogin, vendorName],
  );
}

Login.defaultProps = {
  headerComponent: AuthHeader,
};

export default Login;
