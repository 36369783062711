import BaseModel from './BaseModel';

export default class UserCode extends BaseModel {
  get state() {
    if (!this.active) {
      return 'disabled';
    }
    if (!this.schedule) {
      return 'enabled';
    }
    // let days = [ 'S', 'M', 'T', 'W', 'T', 'F', 'S' ];
    let state = 'disabled';
    // let times = '';
    if (this.schedule && this.schedule.days && this.schedule.days.length) {
      // state = [].concat(this.schedule.days).sort().map(i => days[i]);
      // if(this.schedule.start_time !== this.schedule.end_time)
      //   times = ` ${moment(this.schedule.start_time, 'H:mm').format('h:mma')}-${moment(this.schedule.end_time, 'H:mm').format('h:mma')}`;
      state = 'scheduled';
      // state = `${state.join('')}${times}`;
    }
    return state;
  }

  hasDay(day) {
    if (!this.schedule) {
      return false;
    }
    return (this.schedule.days || []).indexOf(day) > -1;
  }
}
