import BaseModel from './BaseModel';
import DeviceTypes from '../constants/DeviceTypes';

const canTest = ({type}) => {
  const DeviceType = DeviceTypes[type] || {};
  return !!DeviceType.can_test;
};

const getState = ({type, reported}) => {
  const DeviceType = DeviceTypes[type] || {};
  reported = reported || {};
  const state = reported.trigger && reported.trigger.state;
  if (type === 'nest_protect') {
    if (reported.smoke_alarm_state && reported.smoke_alarm_state !== 'ok') {
      return 'smoke';
    }
    if (reported.co_alarm_state && reported.co_alarm_state !== 'ok') {
      return 'co';
    }
    return 'ok';
  }
  if (state && type === 'smoke_alarm') {
    if (
      state.smoke === 'testing' ||
      state.co === 'testing' ||
      state.malfunction === 'testing'
    ) {
      return 'testing';
    }
    if (state.smoke && state.smoke !== 'ok') {
      return 'smoke';
    }
    if (state.co && state.co !== 'ok') {
      return 'co';
    }
    if (state.malfunction && state.malfunction !== 'ok') {
      return 'malfunction';
    }
    return 'ok';
  }
  return state || DeviceType.default_state || 'unknown';
};

const isLocked = ({reported = {}}) => {
  const trigger = reported.trigger || {};
  return trigger.state === 'locked';
};

const isLocking = ({reported = {}}) => {
  const trigger = reported.trigger || {};
  return trigger.state === 'lock';
};

const isOn = ({reported = {}}) => {
  const trigger = reported.trigger || {};
  return trigger.state === 'on';
};

const isType = ({type}, ...types) => types.indexOf(type) > -1;

const isZwave = ({reported}) => !!(reported || {}).zwave;

class Device extends BaseModel {
  get state() {
    return getState(this);
  }

  set state(event) {
    this.reported = this.reported || {};
    this.reported.trigger = this.reported.trigger || {};
    this.reported.trigger.state = event;
  }

  get chirp() {
    if (!this.isDoorPanel()) {
      return undefined;
    }
    const desired = this.desired || {};
    const chirp = desired.chirp || {};
    return !!chirp.enabled;
  }

  get chirp_hub() {
    if (!this.isDoorPanel() && !this.isAccessSensor()) {
      return undefined;
    }
    const desired = this.desired || {};
    const chirps = desired.chirps || {};
    return !!chirps.hub;
  }

  get siren_on_alarm() {
    if (!this.isSiren()) {
      return undefined;
    }
    const desired = this.desired || {};
    return !!desired.siren_on_alarm;
  }

  isOffline() {
    const reported = this.reported || {};
    return reported.timedout;
  }

  deviceType() {
    const DeviceType = DeviceTypes[this.type] || {};
    return DeviceType.name || this.type;
  }

  defaultState() {
    const DeviceType = DeviceTypes[this.type] || {};
    return DeviceType.default_state || 'unknown';
  }

  get currentState() {
    const reported = this.reported || {};
    if (reported.timedout) {
      return 'offline';
    }
    if (!this.reportsState()) {
      return;
    }
    const {state} = this;
    const states = {
      lock: 'locking',
      unlock: 'unlocking',
    };
    const result = [states[state] || state];
    return result.filter(part => !!part);
  }

  hasLowBattery() {
    const reported = this.reported || {};
    const battery = reported.battery || {};
    return battery.level === 0 || !!battery.low;
  }

  get temperature() {
    const reported = this.reported || {};
    const temperature = reported.temperature || {};
    if (temperature.at && typeof temperature.degrees !== 'undefined') {
      return temperature.degrees;
    }
  }

  get humidity() {
    const reported = this.reported || {};
    const humidity = reported.humidity || {};
    if (humidity.percent && humidity.at) {
      return `${humidity.percent}%`;
    }
  }

  getType() {
    return DeviceTypes[this.type] || {};
  }

  hasSettings() {
    const DeviceType = DeviceTypes[this.type] || {};
    return !!DeviceType.settings;
  }

  canTest() {
    return canTest(this);
  }

  isEnvironmental() {
    const DeviceType = DeviceTypes[this.type] || {};
    return !!DeviceType.is_environmental;
  }

  isType(...types) {
    return isType(this, ...types);
  }

  isAccessSensor() {
    return this.isType('access_sensor');
  }

  isDoorPanel() {
    return this.isType('door_panel');
  }

  isKeypad() {
    return this.isType('key_pad');
  }

  isDoorLock() {
    return this.isType('door_lock');
  }

  isTakeover() {
    return this.isType('take_over');
  }

  isMotionSensor() {
    return this.isType('motion_sensor');
  }

  isNestProtect() {
    return this.isType('nest_protect');
  }

  isNestDevice() {
    return /^nest_/.test(this.type);
  }

  isSmokeAlarm() {
    return this.isType('smoke_alarm', 'nest_protect');
  }

  isWaterSensor() {
    return this.isType('water_sensor');
  }

  isGlassBreak() {
    return this.isType('glass_break');
  }

  isSiren() {
    return this.isType('siren');
  }

  isDimmable() {
    return this.isType('light_bulb', 'dimmer_switch');
  }

  canSwitch() {
    return this.isType('switch', 'outlet', 'light_bulb', 'dimmer_switch');
  }

  inMode(mode_id) {
    return (this.mode_ids || []).indexOf(mode_id) > -1;
  }

  hasManufacturer(str) {
    const reported = this.reported || {};
    return new RegExp(`^${str}`, 'i').test(reported.manufacturer || '');
  }

  isSercomm() {
    return this.hasManufacturer('sercomm');
  }

  get level() {
    const reported = this.reported || {};
    const trigger = reported.trigger || {};
    if (this.isDimmable()) {
      if (this.isOn()) {
        return trigger.level || 0;
      }
      return 0;
    }
  }

  canTrigger() {
    const DeviceType = DeviceTypes[this.type] || {};
    return !!DeviceType.can_trigger;
  }

  reportsState() {
    const DeviceType = DeviceTypes[this.type] || {};
    return !!DeviceType.reports_state;
  }

  isOn() {
    return isOn(this);
  }

  isLocked() {
    return isLocked(this);
  }

  isLocking() {
    return isLocking(this);
  }

  isZwave() {
    return isZwave(this);
  }
}

Device.canTest = canTest;
Device.getState = getState;
Device.isLocked = isLocked;
Device.isLocking = isLocking;
Device.isOn = isOn;
Device.isType = isType;
Device.isZwave = isZwave;

export default Device;
