import React from 'react';
import {useTranslation} from 'react-i18next';
import {TextField} from 'formik-material-ui';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

export default function PasswordInput({onButtonPress, ...rest}) {
  const {t} = useTranslation('Common');
  return (
    <TextField
      autoComplete="current-password"
      type="password"
      placeholder={t('common.password')}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={onButtonPress}
              edge="end">
              <HelpOutlineIcon />
            </IconButton>
          </InputAdornment>
        )
      }}
      required
      {...rest} />
  );
}
