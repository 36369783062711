import React, {useCallback, useMemo, useRef} from 'react';
import {Field, Form, Formik} from 'formik';

import {HeaderText} from '../../elements';
import Component from '../RemoteControlSettings';

function RemoteControlSettings(props) {
  const {
    device,
    modes,
    onChangeValue,
    setValue,
    setTouched,
    value,
  } = props;

  const handleSubmit = useRef(null);

  const fields = useMemo(
    () => [
      {
        name: 'text',
        interactive: false,
        component: (
          <HeaderText i18nKey="Device:device.optional_settings">
            Optional Device Settings:
          </HeaderText>
        ),
      },
      {
        name: 'settings',
        component: ({setValue, setTouched, ...rest}) => (
          <Component
            {...rest}
            device={device}
            modes={modes}
            onChange={data => {
              setTouched(true);
              setValue(data);
              handleSubmit.current();
            }}
          />
        ),
      },
    ],
    [device, modes],
  );

  const initialValues = useMemo(
    () => ({
      settings: value,
    }),
    [value],
  );

  const onSubmit = useCallback(
    ({settings}) => {
      setTouched(true);
      setValue(settings);
      onChangeValue(settings);
    },
    [onChangeValue, setTouched, setValue],
  );

  const renderSubmit = useCallback(({onPress}) => {
    handleSubmit.current = onPress;
    return null;
  }, []);

  return useMemo(
    () => (
      <Form
        fields={fields}
        initialValues={initialValues}
        onSubmit={onSubmit}
        renderSubmit={renderSubmit}
        unsetSubmittingTimeout={0}
      />
    ),
    [fields, initialValues, onSubmit, renderSubmit],
  );
}

RemoteControlSettings.fieldName = 'remote_control_field';

RemoteControlSettings.getFieldProps = ({
  device,
  modes,
  onChangeValue,
  styles,
}) => ({
  device,
  modes,
  onChangeValue,
  styles,
});

RemoteControlSettings.populateInitialValues = device => {
  const {desired = {}} = device || {};
  const modes = desired.modes || {};
  const {away = null, home = null} = modes;
  return {
    modes: {away, home},
  };
};

export default RemoteControlSettings;
