import React, {useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useConfirm} from 'material-ui-confirm';

import BackButton from '@material-ui/icons/ArrowBack';
import {Delete as DeleteButton} from '@scout-app/assets/icons';

import * as actions from '../actions/location';

import useActions from '../hooks/useActions';
import useLeftButton from '../hooks/useLeftButton';
import useLocation from '../hooks/useLocation';
import useRequireAdmin from '../hooks/useRequireAdmin';
import useRightButton from '../hooks/useRightButton';
import useSetState from '../hooks/useSetState';
import useTitle from '../hooks/useTitle';
import useUserCode from '../hooks/useUserCode';

import Component from '../routes/UserCode';

const onError = err => alert(err.error || err.message || err);

function UserCode() {
  const history = useHistory();
  const {t} = useTranslation('UserCodes');
  const {removeUserCode, updateUserCode} = useActions(actions);
  const location = useLocation();
  const user_code = useUserCode();
  const confirm = useConfirm();
  const [state, setState] = useSetState();
  const hasDoorLock = useMemo(() => location.hasDoorLock(), [location]);
  const onDelete = useCallback(() => {
    confirm({
      title: t('RemoveCode'),
      description: t('ConfirmRemoveCode', {
        user_code_name: user_code.name,
        location_name: location.name,
      }),
    }).then(() => {
      history.goBack();
      removeUserCode(user_code.id).catch(onError);
    })
    .catch(() => {});
  }, [confirm, history, location.name, removeUserCode, t, user_code.id, user_code.name]);

  const onBack = useCallback(() => {
    const {name, token, active, schedule, disarm_on_entry} = state;
    const data = {};
    if (name && name !== user_code.name) {
      data.name = name;
    }
    if (token && token !== user_code.token) {
      data.code = token;
    }
    if ('active' in state && active !== user_code.active) {
      data.active = active;
    }
    if ('schedule' in state && schedule !== user_code.schedule) {
      data.schedule = schedule;
    }
    if (
      'disarm_on_entry' in state &&
      disarm_on_entry !== user_code.disarm_on_entry
    ) {
      data.disarm_on_entry = disarm_on_entry;
    }
    history.goBack();
    if (Object.keys(data).length) {
      return updateUserCode(user_code.id, data)
        .catch(onError);
    }
  }, [history, state, updateUserCode, user_code.active, user_code.disarm_on_entry, user_code.id, user_code.name, user_code.schedule, user_code.token]);

  useTitle(user_code.name);
  useLeftButton(BackButton, onBack);
  useRightButton(DeleteButton, location.member_state === 'admin' ? onDelete : null);
  useRequireAdmin();

  return useMemo(
    () => (
      <Component
        hasDoorLock={hasDoorLock}
        onSubmit={setState}
        user_code={user_code}
      />
    ),
    [hasDoorLock, setState, user_code],
  );
}

export default UserCode;
