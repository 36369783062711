import React, {useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import BackButton from '@material-ui/icons/ArrowBack';

import * as actions from '../actions/location';

import useActions from '../hooks/useActions';
import useLeftButton from '../hooks/useLeftButton';
import useLocation from '../hooks/useLocation';
import useRequireAdmin from '../hooks/useRequireAdmin';
import useTitle from '../hooks/useTitle';

import Component from '../routes/AddUserCode';

const onError = err => alert(err.error || err.message || err);

function AddUserCode() {
  const history = useHistory();
  const {t} = useTranslation('AddUserCode');
  const {addUserCode} = useActions(actions);
  const location = useLocation();
  const hasDoorLock = useMemo(() => location.hasDoorLock(), [location]);

  useTitle(t('AddUserCode:AddUserCode'));
  useLeftButton(BackButton, history.goBack);
  useRequireAdmin();

  const onSubmit = useCallback(
    ({name, code, disarm_on_entry, active, schedule}, {setSubmitting}) => {
      if (!name) {
        setSubmitting(false);
        return onError(t('Please enter a name for the code.'));
      }
      if (!code) {
        setSubmitting(false);
        return onError(t('Please enter a code.'));
      }
      if (!/^\d{4,10}$/.test(code)) {
        setSubmitting(false);
        return onError(t('This code is invalid.'));
      }

      return addUserCode({
        location_id: location.id,
        name,
        code,
        disarm_on_entry,
        active,
        schedule,
      })
        .then(() => history.goBack())
        .catch(err => {
          setSubmitting(false);
          return onError(err);
        });
    },
    [addUserCode, history, location.id, t],
  );

  return useMemo(
    () => <Component hasDoorLock={hasDoorLock} onSubmit={onSubmit} />,
    [hasDoorLock, onSubmit],
  );
}

export default AddUserCode;
