import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import ct from 'countries-and-timezones';

import {Field, Form, Formik} from 'formik';
import {Container, FormControl} from '@material-ui/core';
import {TextField} from 'formik-material-ui';

import {CountryPicker, StatePicker, StructureTypePicker, TimezonePicker} from '../components/form';

import Button from '../elements/Button';

import useStyleSheet from '../hooks/useStyleSheet';

const styleSheet = () => ({
  container: {
    padding: 24,
  },
});

function EditLocation({location, onSubmit}) {
  const {t} = useTranslation('EditLocation');
  const classes = useStyleSheet(styleSheet);
  const {name, address} = location || {};
  const {addr1 = '', addr2 = '', city = '', zipcode = ''} = address || {};
  const initialValues = useMemo(() => {
    const state = StatePicker.getInitialValue(address);
    const country = CountryPicker.getInitialValue(address);
    const timezone = TimezonePicker.getInitialValue(location, ct.getCountry(country)?.timezones[0]);
    const structure_type = StructureTypePicker.getInitialValue(location);
    return {
      name,
      structure_type,
      addr1,
      addr2,
      city,
      state,
      zipcode,
      country,
      timezone,
    };
  }, [addr1, addr2, address, city, location, name, zipcode]);
  return useMemo(
    () => (
      <Container maxWidth="md" className={classes.container}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}>
          {({ submitForm, isSubmitting, values }) => (
            <Form>
              <FormControl fullWidth>
                <Field
                  component={TextField}
                  type="text"
                  name="name"
                  label={t('labels.name')}
                  placeholder={t('placeholders.name')} />
                <br />
                <Field
                  component={StructureTypePicker}
                  name="structure_type"
                  label={t('labels.type')}
                  placeholder={t('placeholders.type')} />
                <br />
                <Field
                  component={TextField}
                  type="text"
                  name="addr1"
                  label={t('labels.address')}
                  placeholder={t('placeholders.addr1')} />
                <br />
                <Field
                  component={TextField}
                  type="text"
                  name="addr2"
                  placeholder={t('placeholders.addr2')} />
                <br />
                <Field
                  component={TextField}
                  type="text"
                  name="city"
                  placeholder={t('placeholders.city')} />
                <br />
                <Field
                  component={StatePicker}
                  country={values.country}
                  name="state"
                  placeholder={t('placeholders.state')} />
                <br />
                <Field
                  component={TextField}
                  type="text"
                  name="zipcode"
                  placeholder={t('placeholders.zipcode')} />
                <br />
                <Field
                  component={CountryPicker}
                  name="country"
                  label={t('placeholders.country')} />
                <br />
                <Field
                  component={TimezonePicker}
                  country={values.country}
                  name="timezone"
                  label={t('placeholders.timezone')} />
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  i18nKey={isSubmitting ? 'Common:common.saving' : 'Common:common.save'}
                  onClick={submitForm}
                  disabled={isSubmitting}>
                  {isSubmitting ? 'saving...' : 'save'}
                </Button>
              </FormControl>
            </Form>
          )}
        </Formik>
      </Container>
    ),
    [classes.container, initialValues, onSubmit, t],
  );
}

export default EditLocation;
