import {useEffect} from 'react';

import * as actions from '../actions/location';
import useActions from './useActions';

export default function useInstallMode(location_id) {
  const {setInstallMode} = useActions(actions);
  useEffect(() => {
    setInstallMode(location_id, true);
    return () => {
      setInstallMode(location_id, false);
    };
  }, [location_id, setInstallMode]);
}
