import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {merge} from 'lodash';
import resources from '@scout-app/assets/translations';

import moment from './moment';

export default function init({config}) {
  const fallbackLng = config?.defaultLanguage || 'en';
  const lng = config?.forceLanguage;
  const overrides = config?.translations || {};
  moment.locale(lng || fallbackLng);
  return i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .on('languageChanged', locale => {
      moment.locale(locale);
    })
    .init({
      fallbackLng,
      lng,
      resources: merge({}, {es: resources['es-ES']}, resources, overrides),
      fallbackNS: 'Common',
      debug: true,
      interpolation: {
        escapeValue: false, // not needed for react
        format: function(value, format, lang) {
          if (format === 'modeName') {
            if (/ mode$/i.test(value)) {
              return value.replace(/ mode$/i, '');
            }
            if (/^es/.test(lang) && / mod(e|o)$/i.test(value)) {
              return value.replace(/ mod(e|o)/i, '');
            }
          }
          if (format === 'farenheit') {
            return `${Math.round(value * 1.8 + 32)}\u00B0F`;
          }
          if (format === 'celcius') {
            return `${Math.round(value)}\u00B0C`;
          }
          return value;
        },
      },
    });
}
