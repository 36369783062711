import React, {useMemo} from 'react';

import Box from '@material-ui/core/Box';

import Images from '../../constants/Images';

const styles = {
  logo: {
    maxWidth: '80%',
  },
};

function AuthHeader(props) {
  const logo = useMemo(() => props.logo || Images.get('VENDOR', 'LOGO'), [
    props.logo,
  ]);
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <img src={logo} style={styles.logo} alt="" />
    </Box>
  );
}

export default AuthHeader;
