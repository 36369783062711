import BaseModel from './BaseModel';
import CameraTypes from '../constants/CameraTypes';

const isOffline = ({status}) => status !== 'active';

const isPrivate = camera => {
  const settings = camera.settings || {};
  const privacy = settings.privacy || {};
  return !!privacy.enabled;
};

const canStream = camera => !isOffline(camera) && !isPrivate(camera);

const formatMAC = mac => {
  if (!mac || mac.indexOf(':') > -1) {
    return mac;
  }
  return mac
    .replace(/\W/g, '')
    .match(/.{1,2}/g)
    .join(':');
};

const hasDealer = ({dealer_id = 'scout'}, ...dealers) =>
  dealers.indexOf(dealer_id) > -1;

const isModel = ({model}, ...models) => {
  for (let i = 0; i < models.length; i++) {
    if (model === models[i]) {
      return true;
    }
  }
  return false;
};

const isType = ({type}, ...types) => {
  for (let i = 0; i < types.length; i++) {
    if (type === types[i]) {
      return true;
    }
  }
  return false;
};

const isDoorbell = camera => isModel(camera, 'dbc831', 'dbc831v2');
const isLogitech = camera => isType(camera, 'logitech');
const isNest = camera => isType(camera, 'nest');
const isScout = camera => !camera.type || isType(camera, 'scout', 'sercomm');

const isWebRTC = camera => {
  const version = camera.version || '';
  return isDoorbell(camera) && version.localeCompare('02D00D01') > -1;
};

const pairingMode = camera => {
  const version = camera.version || '';
  if (isDoorbell(camera)) {
    return version.localeCompare('02D00D01') > -1 ? 'qrcode' : 'wifi';
  }
  if (isScout(camera)) {
    return version.localeCompare('01D02D00') > -1 ? 'qrcode' : 'wifi';
  }
};

// TODO - translate
const modeAlerts = (mode, mic) => {
  if (!mode) {
    return "don't record or alert";
  }
  let alerts = mode === true ? ['motion', 'sound'] : mode || [];
  alerts = alerts.filter(type => {
    if (type === 'sound' && !mic) {
      return false;
    }
    return true;
  });
  if (!alerts.length) {
    return "record but don't alert";
  }
  return alerts.length === 1
    ? `${alerts[0]} alerts only`
    : `${[alerts.slice(0, -1).join(', '), alerts.slice(-1)].join(
        ' & ',
      )} alerts`;
};

const watermark = ({type}) => {
  switch (type) {
    case 'logitech':
    case 'nest':
      return type;
    default:
      return 'scout';
  }
};

class Camera extends BaseModel {
  get currentState() {
    const {status} = this;
    if (status === 'active') {
      if (this.privacy) {
        return 'privacy';
      }
      return 'online';
    }
    return status;
  }

  getType() {
    if (this.model in CameraTypes) {
      return CameraTypes[this.model];
    }
    if (this.type in CameraTypes) {
      return CameraTypes[this.type];
    }
    if (this.isDoorbell()) {
      return CameraTypes.doorbell;
    }
    return CameraTypes.camera;
  }

  canStream() {
    return canStream(this);
  }

  hasDealer() {
    return hasDealer(this);
  }

  isOffline() {
    return isOffline(this);
  }

  isDoorbell() {
    return isDoorbell(this);
  }

  isScout() {
    return isScout(this);
  }

  isModel(...models) {
    return isModel(this, ...models);
  }

  isType(...types) {
    return isType(this, ...types);
  }

  isLogitech() {
    return isLogitech(this);
  }

  isNest() {
    return isNest(this);
  }

  isWebRTC() {
    return isWebRTC(this);
  }

  pairingMode() {
    return pairingMode(this);
  }

  watermark() {
    return watermark(this);
  }

  modeAlerts(mode_id) {
    return modeAlerts(this.modes[mode_id], this.mic);
  }

  hasSettings() {
    return !this.isNest();
  }

  hasTechnicalInformation() {
    return !!(this.serial_number || this.mac || this.ip_address);
  }

  get privacy() {
    return isPrivate(this);
  }

  get mic() {
    const settings = this.settings || {};
    const mic = settings.mic || {enabled: true};
    return !!mic.enabled;
  }

  get led() {
    const settings = this.settings || {};
    const led = settings.led || {enabled: true};
    return !!led.enabled;
  }

  get night_vision() {
    const settings = this.settings || {};
    const night_vision = settings.night_vision || {state: 'auto'};
    return night_vision.state;
  }

  get flip() {
    const settings = this.settings || {};
    const flip = settings.flip || {};
    return !!flip.enabled;
  }

  get sound_sensitivity() {
    const settings = this.settings || {};
    const sound_sensitivity = settings.sound_sensitivity || {volume: 15};
    return sound_sensitivity.volume;
  }

  get motion_sensitivity() {
    const settings = this.settings || {};
    const motion_sensitivity = settings.motion_sensitivity || {threshold: 2};
    return motion_sensitivity.threshold;
  }

  get modes() {
    const settings = this.settings || {};
    return settings.modes || {};
  }

  get chime() {
    const settings = this.settings || {};
    const chime = settings.chime || {};
    return chime.type || 'analog';
  }

  get notifications() {
    const settings = this.settings || {};
    return settings.notifications || [];
  }

  get quality() {
    const settings = this.settings || {};
    const quality = settings.quality || {};
    return quality.state || 'high';
  }

  get ip_address() {
    const connection = this.connection || {};
    return connection.local_ip;
  }
}

Camera.canStream = canStream;
Camera.formatMAC = formatMAC;
Camera.hasDealer = hasDealer;
Camera.isDoorbell = isDoorbell;
Camera.isLogitech = isLogitech;
Camera.isModel = isModel;
Camera.isNest = isNest;
Camera.isOffline = isOffline;
Camera.isPrivate = isPrivate;
Camera.isScout = isScout;
Camera.isType = isType;
Camera.isWebRTC = isWebRTC;
Camera.modeAlerts = modeAlerts;
Camera.pairingMode = pairingMode;
Camera.watermark = watermark;

export default Camera;
