import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import clsx from 'clsx';

import Vector from '../pairing/Vector';
import Colors from '../../constants/Colors';
import {HeaderText, ParagraphText, Text} from '../../elements';
import {useStyleSheet} from '../../hooks';

const styleSheet = () => ({
  container: {
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  text: {
    // flexGrow: 1,
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: '#bebebe',
    marginLeft: 10,
  },
  active: {
    color: Colors.get('pairingIndicator'),
  },
  option: {
    marginTop: 10,
    marginBottom: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  setting: {
    // flex: 1,
  },
});

const activeStates = [
  'open',
  'motion_start',
  'wet',
  'testing',
  'smoke',
  'co',
  'shatter',
];

function DeviceTesting(props) {
  const {device, deviceType, hub} = props;
  const {t} = useTranslation('Device');
  const classes = useStyleSheet(styleSheet);
  const instructions = useMemo(() => {
    if (device.isType('glass_break')) {
      if (device.hasManufacturer('gocontrol')) {
        return (
          <ParagraphText i18nKey="Device:device.glass_break_testing_gocontrol_instructions">
            Press and release the front button to test communication with the
            hub.
          </ParagraphText>
        );
      }
      return (
        <ParagraphText i18nKey="Device:device.glass_break_testing_instructions">
          Tap on the sensor with your hand to test communication with the hub.
        </ParagraphText>
      );
    }
    if (device.isSmokeAlarm()) {
      return (
        <ParagraphText i18nKey="Device:device.smoke_alarm_testing_instructions">
          Press and hold the test/silence button, wait for device to chirp, then
          release the button.
        </ParagraphText>
      );
    }
    if (hub.isType('scout1')) {
      return (
        <ParagraphText i18nKey="Device:device.v1_testing_instructions">
          Please wait 30 seconds after opening this screen for the sensor to
          enter test mode. Then, using the icons below, you can test the
          features of this device in real-time. The system will be disarmed
          during testing.
        </ParagraphText>
      );
    }
    return (
      <ParagraphText i18nKey="Device:device.testing_instructions">
        Using the icons below, you can test the features of this device in
        real-time. The system will be disarmed during testing.
      </ParagraphText>
    );
  }, [device, hub]);

  const content = useMemo(() => {
    const state_vectors = deviceType.state_vectors || {};
    const source = state_vectors[device.state];
    return (
      <div className={classes.container}>
        {source && <Vector source={source()} height={50} width={35} />}
        <Text
          style={clsx(classes.text, {
            [classes.active]: activeStates.includes(device.state),
          })}
          adjustsFontSizeToFit>
          {t(device.state)}
        </Text>
      </div>
    );
  }, [classes.active, classes.container, classes.text, device.state, deviceType.state_vectors, t]);

  return useMemo(
    () => (
      <>
        <HeaderText i18nKey="Device:device.test_this_device">
          Test this Device:
        </HeaderText>
        {instructions}
        <div className={classes.option}>
          <HeaderText
            i18nKey="Device:device.sensor_test"
            className={classes.setting}>
            Sensor Test:
          </HeaderText>
          {content}
        </div>
      </>
    ),
    [classes.option, classes.setting, content, instructions],
  );
}

DeviceTesting.interactive = false;

DeviceTesting.fieldName = 'test_field';

DeviceTesting.getFieldProps = ({device, deviceType, hub, styles}) => ({
  device,
  deviceType,
  hub,
  styles,
});

export default DeviceTesting;
