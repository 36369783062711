import createTextComponent from './createTextComponent';

const H1Text = createTextComponent('H1Text', 'h3');

H1Text.defaultProps = {
  // marginBottom: Spacing.DEFAULT_SPACING,
  component: 'h1',
};

export default H1Text;
