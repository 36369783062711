import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import useModel from './useModel';

import Member from '../models/Member';

const getSelector = params => ({location, member}) => {
  const {member_id} = params || {};
  if(member_id) {
    return { member: location.members.find(({id}) => id === member_id) || {} };
  };
  return { member };
};

function useMember() {
  const params = useParams();
  const selector = useMemo(() => getSelector(params), [params]);
  const {member} = useSelector(selector);
  return useModel(member, Member);
}

export default useMember;
