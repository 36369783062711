import React from 'react';

import H1Text from '../elements/H1Text';

const style = {fontWeight: 'bold'};

function AuthHeader({vendorName}) {
  return (
    <div>
      <H1Text
        i18nKey="AuthHeader:hello"
        color="login"
        component="h2">
        hello!
      </H1Text>
      <H1Text
        i18nKey="AuthHeader:welcome"
        color="login">
        welcome to{' '}
        <span style={style}>
          {{vendorName: vendorName.toLowerCase()}}
        </span>
        .
      </H1Text>
    </div>
  );
}

export default AuthHeader;
