import React, {useMemo} from 'react';

import {useTranslation} from 'react-i18next';

import useConfig from '../hooks/useConfig';
import useStyleSheet from '../hooks/useStyleSheet';

export default function Svg({
  children,
  fill,
  height,
  i18nKey,
  marginBottom,
  marginTop,
  source: Component,
  width,
  ...rest
}) {
  const {t} = useTranslation();
  const {getColor} = useConfig();
  const tintColor = useMemo(() => fill || getColor('accent'), [fill, getColor]);
  const styleSheet = useMemo(
    () => ({
      container: {
        marginBottom,
        marginTop,
        height,
        width,
        justifyContent: 'center',
        alignItems: 'center',
      },
    }),
    [height, marginBottom, marginTop, width],
  );
  const classes = useStyleSheet(styleSheet);
  const content = useMemo(() => {
    if (children) {
      return children;
    }
    if (i18nKey) {
      return t(i18nKey);
    }
    return null;
  }, [children, i18nKey, t]);
  return useMemo(() => {
    return (
      <div className={classes.container}>
        {Component && (
          <Component
            tintColor={tintColor}
            height={height}
            width={width}
            {...rest}>
            {content}
          </Component>
        )}
      </div>
    );
  }, [Component, classes.container, content, height, rest, tintColor, width]);
}
