import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import useModel from './useModel';

import Device from '../models/Device';

const getSelector = params => ({location}) => {
  const {device_id} = params || {};
  return { device: location.devices.find(({id}) => id === device_id) || {} };
};

function useDevice() {
  const params = useParams();
  const selector = useMemo(() => getSelector(params), [params]);
  const {device} = useSelector(selector);
  return useModel(device, Device);
}

export default useDevice;
