import React, {useMemo} from 'react';

import {HeaderText, ParagraphText} from '../../elements';
import Images from '../../constants/Images';
import {useRemoteTest, useStyleSheet} from '../../hooks';

const styleSheet = {
  image: {
    width: 160,
    height: 120,
  },
  option: {
    marginTop: 10,
    marginBottom: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  setting: {
    flex: 1,
  },
};

function RemoteControlTesting(props) {
  const {device, location} = props;
  const classes = useStyleSheet(styleSheet);
  const deviceState = useRemoteTest(location.id, device && device.id);

  const isPanicButton = device.type === 'panic_button';
  const instructions = useMemo(
    () =>
      isPanicButton
        ? 'Press the panic button 3 times and confirm the test icon below lights up.'
        : 'Press the lock button on the remote and confirm the test icon below lights up.',
    [isPanicButton],
  );
  const i18nKey = useMemo(
    () =>
      isPanicButton
        ? 'PanicButton:test_description_below'
        : 'RemoteControl:test_description_below',
    [isPanicButton],
  );
  const source = useMemo(
    Images.defer(device.type.toUpperCase(), deviceState.toUpperCase()),
    [device.type, deviceState],
  );

  return useMemo(
    () => (
      <div className={classes.section}>
        <HeaderText i18nKey="Device:device.test_this_device">
          Test this Device:
        </HeaderText>
        <ParagraphText i18nKey={i18nKey}>{instructions}</ParagraphText>
        <div className={classes.option}>
          <HeaderText
            i18nKey="Device:device.sensor_test"
            className={classes.setting}>
            Sensor Test:
          </HeaderText>
          <img className={classes.image} src={source} alt="" />
        </div>
      </div>
    ),
    [classes.image, classes.option, classes.section, classes.setting, i18nKey, instructions, source],
  );
}

RemoteControlTesting.interactive = false;

RemoteControlTesting.fieldName = 'test_remote_field';

RemoteControlTesting.getFieldProps = ({device, location, styles}) => ({
  device,
  location,
  styles,
});

export default RemoteControlTesting;
